import React, { useState } from "react";
import {
  Box,
  Divider,
  Stack,
  TextField,
  Link,
  Typography,
} from "@mui/material";
import { ADMIN } from "../../../api/ADMIN";
import { useQuery, useMutation, useQueryClient } from "react-query";
import ListReportAdd from "./ListReport_Add";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import {
  DataGridPro,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarContainer,
} from "@mui/x-data-grid-pro";
import FullSizeLoading from "../../ui/fullSizeLoading";
import { ADMIN_CLIENT } from "../../../api/ADMIN_CLIENT";
import { USERS } from "../../../api/USER";
import { USER_CLIENT } from "../../../api/USER_CLIENT";
import ListReportEdit from "./ListReport_Edit";

import AspectRatioIcon from "@mui/icons-material/AspectRatio";
const CustomToolbar: React.FunctionComponent<{
  setFilterButtonEl: React.Dispatch<
    React.SetStateAction<HTMLButtonElement | null>
  >;
  setSearchInputValue: React.Dispatch<React.SetStateAction<string>>;
  searchInputValue: React.Dispatch<
    React.SetStateAction<HTMLButtonElement | null>
  >;
  mutations: any;
  data: any;
  models: any;
  clients: any;
}> = ({
  setFilterButtonEl,
  setSearchInputValue,
  searchInputValue,
  data,
  mutations,
  models,
  clients,
}) => {
  //-------- Grid Header --------
  const SearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(event.target.value);
  };
  return (
    <GridToolbarContainer
      sx={{
        padding: `0px`,
      }}
    >
      <Stack
        flex='1'
        sx={{
          backgroundColor: (theme: any) => theme.palette.customGrey.grey1,
          padding: `0px 10px`,
        }}
      >
        <Stack
          sx={{
            height: `60px`,
            "& .MuiFormLabel-root:not(.Mui-focused)": {
              color: "grey !important",
            },
          }}
          direction='row'
          alignItems='center'
          spacing={2}
        >
          <TextField
            size='small'
            value={searchInputValue}
            onChange={SearchInputChange}
            label='Recherche'
            variant='outlined'
          />
          <ListReportAdd
            mutations={mutations}
            data={data}
            models={models}
            clients={clients}
          />
          <GridToolbarColumnsButton ref={setFilterButtonEl} />
        </Stack>
        <Divider light />
      </Stack>
    </GridToolbarContainer>
  );
};

const ListReports = ({ userType }: any) => {
  const [clients, setClients] = useState([]);
  const [models, setModels] = useState<any>([]);
  const [searchInputValue, setSearchInputValue] = React.useState(``);
  const [filterColumnButton, setFilterColumnButton] =
    React.useState<HTMLButtonElement | null>(null);

  let API: any = ADMIN;
  let API_CLIENT: any = ADMIN_CLIENT;

  if (userType === "USER") {
    API = USERS;
    API_CLIENT = USER_CLIENT;
  }
  const fetchReportsList = async () => {
    const response = await API.GET_REPORTS();
    return response?.data;
  };

  // Get models
  useQuery("getModels", async () => {
    await API.GET_MODEL().then((res: any) => {
      setModels(res.data);
    });
  });
  // Get clients

  useQuery("getClientsReports", async () => {
    await API_CLIENT.GET_CLIENTS().then((res: any) => {
      setClients(res.data);
    });
  });

  const queryClient = useQueryClient();
  const { isLoading, data } = useQuery("ReportsList", fetchReportsList);
  const mutations = {
    createReport: useMutation((newReport) => API.CREATE_REPORT(newReport), {
      onSuccess: (data: any, variables: any) => {
        queryClient.setQueryData("ReportsList", (oldData: any) => [
          ...oldData,
          data.data,
        ]);
      },
    }),
    deleteReport: useMutation(
      ({ id }: { id: string }) => API.DELETE_REPORT(id),
      {
        onSuccess: (data, variable) => {
          queryClient.setQueryData("ReportsList", (oldData: any) => {
            return oldData.filter((report: any) => report._id !== variable);
          });
        },
      }
    ),
    putReport: useMutation(
      ({ reportId, report }: any) => API.PUT_REPORT(reportId, report),
      {
        onSuccess: (data: any, variable) => {
          queryClient.setQueryData("ReportsList", (oldData: any) => {
            return oldData.map((item: any) =>
              item._id === variable.reportId
                ? { _id: variable.reportId, ...data.data }
                : item
            );
          });
        },
      }
    ),
  };

  //-------- Grid Filters --------

  const SearchBarFilter = (rows: any) => {
    return rows
      .map((row: any) => {
        row.id = row._id;
        row.full_name = row.name + " " + row.family_name;
        return row;
      })
      .filter(
        (row: any) =>
          !searchInputValue ||
          row.title?.toLowerCase().includes(searchInputValue.toLowerCase()) ||
          row.status?.toLowerCase().includes(searchInputValue.toLowerCase()) ||
          row.client_id?.toLowerCase().includes(searchInputValue.toLowerCase())
      );
  };

  //-------- Grid Columns --------
  const columns = [
    {
      field: `title`,
      headerName: `Titre du rapport`,
      width: 250,
      minWidth: 100,
      flex: 0.3,
      renderCell: (cellValue: any) => {
        return (
          <Typography
            variant='body2'
            sx={{ fontSize: { xs: "13px", xm: "15px" } }}
          >
            {cellValue.row?.title}
          </Typography>
        );
      },
    },
    {
      field: `client_id`,
      headerName: `Identité du client`,
      width: 250,
      minWidth: 160,
      flex: 0.3,
      renderCell: (cellValue: any) => {
        const clientIdRow = cellValue.row?.client_id;
        const client: any = clients.find((cli: any) => cli._id === clientIdRow);
        return (
          <Typography
            variant='body2'
            sx={{ fontSize: { xs: "13px", xm: "15px" } }}
          >
            {client ? client.email : "Information non renseignée"}
          </Typography>
        );
      },
    },
    {
      field: `site`,
      headerName: `Site`,
      width: 150,
      minWidth: 90,
      flex: 0.2,
    },
    {
      field: `sectionSite`,
      headerName: `Section`,
      width: 150,
      minWidth: 90,
      flex: 0.2,
    },
    {
      field: `status`,
      headerName: `Status`,
      minWidth: 100,
      flex: 0.2,
      renderCell: (cellValue: any) => {
        const status = cellValue.row?.status;
        return (
          <Typography
            variant='body2'
            sx={{ fontSize: { xs: "13px", xm: "15px" } }}
          >
            {status === "uncompleted" ? "Incompleté" : " Complété"}
          </Typography>
        );
      },
    },
    // fixed right
    {
      field: `actions`,
      type: `actions`,
      headerName: `Actions`,
      width: userType === "ADMIN" ? 120 : 85,
      getActions: (e: { row?: any }) => {
        return [
          <GridActionsCellItem
            sx={{
              backgroundColor: "secondary.main",
              display: userType === "ADMIN" ? "block" : "none",
            }}
            icon={
              <Link href={`/export/${e.row._id}`}>
                <UploadFileIcon
                  sx={{
                    color: "white",
                    fontSize: { xs: "14px", xsm: "17px" },
                  }}
                />
              </Link>
            }
            label='More'
            key='grid-actions-cell-item-2'
          />,
          <GridActionsCellItem
            sx={{
              backgroundColor: "secondary.main",
            }}
            icon={
              <Link
                sx={{ padding: "0px !important", margin: "0px" }}
                href={`/report/${e.row._id}`}
              >
                <AspectRatioIcon
                  sx={{
                    color: "white",
                    fontSize: { xs: "14px", xsm: "17px" },
                  }}
                />
              </Link>
            }
            label='More'
            key='grid-actions-cell-item-3'
          />,
          <ListReportEdit
            key='user-grid-edit'
            rowInfos={e}
            mutations={mutations}
            clients={clients}
          />,
        ];
      },
    },
  ];

  return (
    <Box
      className='wrapper_noPadding card'
      sx={{ height: "588px", display: "flex", alignItems: "center", gap: 2 }}
    >
      <Box
        sx={{
          height: "100%",
          width: "100%",
        }}
      >
        {!isLoading && data ? (
          <DataGridPro
            autoPageSize
            pagination
            sx={{
              '.MuiDataGrid-booleanCell[data-value="true"] ': {
                color: `#8D919A`,
              },
              borderRadius: `20px`,
              overflow: "hidden",
            }}
            getRowId={(row) => row._id}
            rows={(data[0] && SearchBarFilter(data)) || []}
            columns={columns}
            disableColumnMenu
            localeText={{
              toolbarColumns: "Colonnes",
              columnsPanelTextFieldLabel: "Chercher une colonne",
              columnsPanelTextFieldPlaceholder: "Nom de la colonne",
              columnsPanelShowAllButton: "Afficher tout",
              columnsPanelHideAllButton: "Masquer tout",
            }}
            components={{
              Toolbar: CustomToolbar,
              NoRowsOverlay: () => (
                <Stack
                  height='100%'
                  alignItems='center'
                  justifyContent='center'
                >
                  Aucune ligne à afficher
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack
                  height='100%'
                  alignItems='center'
                  justifyContent='center'
                >
                  Aucun résultat
                </Stack>
              ),
            }}
            componentsProps={{
              panel: {
                anchorEl: filterColumnButton,
              },
              toolbar: {
                setFilterButtonEl: setFilterColumnButton,
                setSearchInputValue,
                searchInputValue,
                data,
                mutations,
                models,
                clients,
              },
            }}
            initialState={{ pinnedColumns: { right: [`actions`] } }}
            // disableSelectionOnClick
          />
        ) : (
          <FullSizeLoading />
        )}
      </Box>
    </Box>
  );
};

export default ListReports;
