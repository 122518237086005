import axios from 'axios';

export const USERS = {
	GET_PROBYSIRET: async function (SIRET:string) {
		return (axios({
			method: 'get',
			url: `${process.env.REACT_APP_SERVER_URL}/api/user/BYSIRET`,
			params: {
				siret:SIRET,
			},
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			},
		}).then(function (res:any) {
			return res.data;
		})).catch(function (error:any) {
			console.log(error);
			return null;
		});
	},

	GET_USER: async function () {
		return (axios({
			method: 'get',
			url: `${process.env.REACT_APP_SERVER_URL}/api/user/`,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			},
			
		}).then(function (res) {						
			return res.data;
		}))
	},
	GET_CHAT_BY_PROJECT_ID: async function (project_id: string) {
		return (axios({
			method: 'get',
			url: `${process.env.REACT_APP_SERVER_URL}/api/user/chat`,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${localStorage.getItem('token')}` 
			},
			params: {
				project_id: project_id,
			},	
		}).then(function (res) {						
			return res.data;
		}))
	},

	PUT_USER: async function (user: any) {
		return (axios({
			method: 'put',
			url: `${process.env.REACT_APP_SERVER_URL}/api/user/`,
			data: {
				user: JSON.stringify(user),
			},
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			},
		}).then(function (res) {						
			return res.data;
		}))
	},

	PUT_PROJECT: async function (project: any) {
		return (axios({
			method: 'put',
			url: `${process.env.REACT_APP_SERVER_URL}/api/user/myproject`,
			data: {
				project: JSON.stringify(project),
			},
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			},
		}).then(function (res) {						
			return res.data;
		}))
	},

	GET_PROJECT: async function () {
		return (axios({
			method: 'get',
			url: `${process.env.REACT_APP_SERVER_URL}/api/user/project`,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			},
		}).then(function (res) {						
			return res.data;
		}))
	},

	DOC_CHECK: async function (project_id: string, file_name: any) {
		return (axios({
			method: 'get',
			url: `${process.env.REACT_APP_SERVER_URL}/api/user/file/check`,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			},
			params : {
				project_id: project_id,
				file_name: file_name
			}
		}).then(function (res:any) {
			return res.data;
		})).catch(function (error:any) {
			console.log(error);
			return null;
		});
	},

	UPLOAD_DOC: async function handleSendFile(project_id: string, file_name: string, file: any){
		if(file){
			let formData = new FormData();
			formData.append('file', file);
			try {
			  const response = await axios({
				method: 'post',
				url: `${process.env.REACT_APP_SERVER_URL}/api/user/file/upload?project_id=${project_id}&file_name=${file_name}`,
				headers: {
				  'Content-Type': 'multipart/form-data',
				  'Authorization': `Bearer ${localStorage.getItem('token')}`
				},
				data: formData
			  });
			  return 'success'
			} catch (error) {
			  return 'error'
			}
		  }
		},

	UPLOAD_CHAT_DOC: async function handleSendFile(project_id: string, file_name: string, file: any){
		if(file){
			let formData = new FormData();
			formData.append('file', file);
			try {
				const response = await axios({
				method: 'post',
				url: `${process.env.REACT_APP_SERVER_URL}/api/user/chat/upload?project_id=${project_id}&file_name=${file_name}`,
				headers: {
					'Content-Type': 'multipart/form-data',
					'Authorization': `Bearer ${localStorage.getItem('token')}`
				},
				data: formData
				});
				return 'success'
			} catch (error) {
				return 'error'
			}
			}
		},

	DOWNLOAD_CHAT: async function (project_id: string, file_name: string) {
		return (axios({
			method: 'get',
			url: `${process.env.REACT_APP_SERVER_URL}/api/user/chat/download`,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			},
			params : {
				project_id: project_id,
				file_name: file_name
			},
			responseType: 'blob',
		}).then(function (res:any) {
			return res;
		})).catch(function (error:any) {
			console.log(error);
			return null;
		});
	},

	DOC_DOWNLOAD: async function (project_id: string, file_name: string) {
		return (axios({
			method: 'get',
			url: `${process.env.REACT_APP_SERVER_URL}/api/user/file/download`,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			},
			params : {
				project_id: project_id,
				file_name: file_name
			},
			responseType: 'blob',
		}).then(function (res:any) {
			return res;
		})).catch(function (error:any) {
			console.log(error);
			return null;
		});
	},

	DOC_DELETE: async function (project_id: string, file_name: string) {
		return (axios({
			method: 'delete',
			url: `${process.env.REACT_APP_SERVER_URL}/api/user/file/delete`,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			},
			params : {
				project_id: project_id,
				file_name: file_name
			},
		}).then(function (res:any) {						
			return res.data;
		})).catch(function (error:any) {
			console.log(error);
			return null;
		});
	},

	GET_CHATBYID: async function (project_id: string) {
		return (axios({
			method: 'get',
			url: `${process.env.REACT_APP_SERVER_URL}/api/user/chat`,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			},
			params : {
				project_id: project_id,
			},
		}).then(function (res:any) {						
			return res.data;
		})).catch(function (error:any) {
			console.log(error);
			return null;
		});
	},
	POST_MODEL: async function (model: any) {
		return (axios({
			method: 'post',
			url: `${process.env.REACT_APP_SERVER_URL}/api/user/model`,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			},
			data: model
		}).then(function (res:any) {
			return res.data;
		})).catch(function (error:any) {
			console.log(error);
			return null;
		});
	},

	GET_MODEL: async function () {
		return (axios({
			method: 'get',
			url: `${process.env.REACT_APP_SERVER_URL}/api/user/model`,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			},
		}).then(function (res:any) {
			return res.data;
		})).catch(function (error:any) {
			console.log(error);
			return null;
		});
	},
	GET_MODEL_BY_ID: async function(modelId: string)
	{
		return (axios({
			method: 'get',
			params: {model_id:modelId},
			url: `${process.env.REACT_APP_SERVER_URL}/api/user/model/id`,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			},
		}).then((res:any) => {
			return res.data;
		}).catch((error:any) => {
			console.log(error);
			return null;
		}))
	},
	DELETE_MODEL: async function (id: string) {
		return (axios({
			method: 'delete',
			url: `${process.env.REACT_APP_SERVER_URL}/api/user/model`,
			data: {
				model_id: id,
			},
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			},
		}).then(function (res:any) {
			return res.data;
		})).catch(function (error:any) {
			console.log(error);
			return null;
		});
	},

	PUT_MODEL: async function (id: string, model: any) {
		return (axios({
			method: 'put',
			url: `${process.env.REACT_APP_SERVER_URL}/api/user/model`,
			data: {
				model_id: id,
				model: JSON.stringify(model),
			},
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			},
		}).then(function (res:any) {
			return res.data;
		})).catch(function (error:any) {
			console.log(error);
			return null;
		});
	},
	GET_ELEMENT_IN_MODEL: async function(elementId:string) {
		return (axios({
			method: 'get',
			params: {element_id:elementId},
			url: `${process.env.REACT_APP_SERVER_URL}/api/user/model/element`,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			},
		}).then((res:any) => {
			return res.data;
		}).catch((error:any) => {
			console.log(error);
			return null;
		}))
	},
	PUT_ELEMENT_IN_MODEL: async function(elementId:string, element:any){
		return (axios({
			method: 'put',
			data: {element_id: elementId, element: element},
			url: `${process.env.REACT_APP_SERVER_URL}/api/user/model/element`,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			},
		}).then((res:any) => {
			return res.data;
		}).catch((error:any) => {
			console.log(error);
			return null;
		}))
	},
	POST_ELEMENT_IN_MODEL: async function(modelId:string,name:string) {
		return (axios({
			method: 'post',
			data: {element: {name:name }, model_id:modelId},
			url: `${process.env.REACT_APP_SERVER_URL}/api/user/model/element`,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			},
		}).then((res:any) => {
			return res.data;
		}).catch((error:any) => {
			console.log(error);
			return null;
		}))
	},
	DELETE_ELEMENT_IN_MODEL: async function(elementId:string)
	{
		return(axios({
			method: "delete",
			params: {element_id: elementId},
			url: `${process.env.REACT_APP_SERVER_URL}/api/user/model/element`,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			},
		}).then((res:any) => {
			return res.data
		}).catch((error:any) => {
			console.log(error);
			return null;
		}))
	},
	POST_CONTROLE_IN_MODEL: async function(modelId:string,elementId:string,name:string) {
		return (axios({
			method: 'post',
			data: {controle: {description:name }, model_id:modelId, element_id: elementId},
			url: `${process.env.REACT_APP_SERVER_URL}/api/user/model/element/controle`,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			},
		}).then((res:any) => {
			return res.data;
		}).catch((error:any) => {
			console.log(error);
			return null;
		}))
	},
	DELETE_CONTROLE_IN_MODEL: async function(controleId:string)
	{
		return(axios({
			method: "delete",
			params: {controle_id: controleId},
			url: `${process.env.REACT_APP_SERVER_URL}/api/user/model/element/controle`,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			},
		}).then((res:any) => {
			return res.data
		}).catch((error:any) => {
			console.log(error);
			return null;
		}))
	},
	GET_REPORT: async function(report_id:string)
	 {
		return(axios({
			method:"get",
			url:`${process.env.REACT_APP_SERVER_URL}/api/user/report`,
			params: {
				report_id: report_id
			},
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			},

		}).then(function (res:any) {						
			return res.data;
		})).catch(function (error:any) {
			console.log(error);
			return null;
		});
	 },
	 GET_REPORTS: async function()
	{
		return(axios({
			method:"get",
			url:`${process.env.REACT_APP_SERVER_URL}/api/user/reports`,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			},

		}).then(function (res:any) {			
			return res.data;
		})).catch(function (error:any) {
			console.log(error);
			return null;
		});
	},
	CREATE_REPORT: async function(report:any)
	{
		return (axios({
			method: 'post',
			url: `${process.env.REACT_APP_SERVER_URL}/api/user/report`,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			},
			data: {
				report: report,
			}
		}).then(function (res:any) {						
			return res.data;
		})).catch(function (error:any) {
			console.log(error);
			return null;
		});
	},
	PUT_REPORT: async function(report_id:string , report:any)
	{
		return (axios({
			method: 'put',
			url: `${process.env.REACT_APP_SERVER_URL}/api/user/report`,
			data: {
				report_id: report_id,
				report: report
			},
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			}
		}).then(function (res:any) {						
			return res.data;
		})).catch(function (error:any) {
			console.log(error);
			return null;
		});
	},
	DELETE_REPORT: async function(report_id:string)
	{
		return (axios({
			method: 'delete',
			url: `${process.env.REACT_APP_SERVER_URL}/api/user/report`,
			data: {
				report_id: report_id,
			},
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			}
		}).then(function (res:any) {						
			return res.data;
		})).catch(function (error:any) {
			console.log(error);
			return null;
		});
	},
	GET_MY_REPORT_IN_PROGRESS: async function()
	{
		return (axios({
			method: 'get',
			url: `${process.env.REACT_APP_SERVER_URL}/api/user/report/inprogress`,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			}
		}).then(function (res:any) {						
			return res.data;
		})).catch(function (error:any) {
			console.log(error);
			return null;
		});
	},
	POST_INTERVENTION_IN_REPORT: async function(report_id:string, place:string)
	{
		return (axios({
			method: 'post',
			data: {
				report_id: report_id,
				place:place,
				startDate: new Date(Date.now())
			},
			url: `${process.env.REACT_APP_SERVER_URL}/api/user/report/model`,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			}
		}).then(function (res:any) {						
			return res.data;
		})).catch(function (error:any) {
			console.log(error);
			return null;
		});
	},
	IS_CONNECTED: async function (){
		return (axios({
			method: 'get',
			url: `${process.env.REACT_APP_SERVER_URL}/api/user/token/`,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			},
			
		}).then(function (res) {						
			return res.status === 200;
		})).catch((err) => {console.log(err); return false;})
	},
	PUT_CONTROLE_RESULT_IN_REPORT: async function(report_id:string, intervention_id:string,element_id:string,controle_id:string,controle_result:any){
		return (axios({
			method: 'put',
			url: `${process.env.REACT_APP_SERVER_URL}/api/user/report/element/controle/result`,
			data:{
				report_id: report_id,
				intervention_id: intervention_id,
				element_id:element_id,
				controle_id: controle_id,
				result: controle_result
			},
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			},
			
		}).then(function (res) {						
			return res.data;
		})).catch((err) => {console.log(err); return false;})
	},
	UPLOAD_OBSERVATION_IMG: async function(report_id:string, file:any){
		let image = new FormData();
		image.append("file", file);

		return (axios({
			method: 'post',
			params:{
				report_id:report_id
			},
			url: `${process.env.REACT_APP_SERVER_URL}/api/user/report/upload/IMG`,
			data: image,
			headers: {
				'Content-Type': "multipart/form-data",
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			},
			
		}).then(function (res) {			
			return res.data;
		})).catch((err) => {console.log(err); return false;})
	},
	DELETE_OBSERVATION_IMG: async function(report_id:string, file_id:any){
		return (axios({
			method: 'delete',
			url: `${process.env.REACT_APP_SERVER_URL}/api/user/report/delete/IMG`,
			params: {
				report_id:report_id,
				file_id:file_id,
			},
			headers: {
				'Content-Type': "multipart/form-data",
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			},
			
		}).then(function (res) {						
			return res.status === 200;
		})).catch((err) => {console.log(err); return false;})
	},
	GET_APPAREILS_IN_SETTINGS: async function () {
		return (axios({
			method: 'get',
			url: `${process.env.REACT_APP_SERVER_URL}/api/user/settings/appareils`,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			},
		}).then(function (res:any) {
			return res.data;
		})).catch(function (error:any) {
			console.log(error);
			return null;
		});
	},
	GET_USERS: async function (filter?: any) {
		return (axios({
			method: 'get',
			url: `${process.env.REACT_APP_SERVER_URL}/api/user/agents`,
			params: {
				filter: filter,
			},
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			},
		}).then(function (res:any) {
			return res.data;
		})).catch(function (error:any) {
			console.log(error);
			return null;
		});
	},
	DOWNLOAD_OBSERVATION_IMG: async function(report_id:string, file_id:string){
		return (axios({
			method: 'get',
			params:{
				report_id:report_id,
				file_id:file_id
			},
			url: `${process.env.REACT_APP_SERVER_URL}/api/user/report/download/IMG`,
			headers: {
				'Content-Type': "application/x-www-form-urlencoded",
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			},
			responseType: 'blob',
			
		}).then(function (res) {	
			return res;
		})).catch((err) => {console.log(err); return false;})
	},
	GET_PRODUCTS_IN_SETTINGS: async function () {
		return (axios({
			method: 'get',
			url: `${process.env.REACT_APP_SERVER_URL}/api/user/settings/products`,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			},
	
		}).then(function (res:any) {
			return res.data;
		})).catch(function (error:any) {
			console.log(error);
			return null;
		});
	},
}
