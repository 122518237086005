import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Select,
  Typography,
  MenuItem,
  TextField,
  Button,
  InputLabel,
  FormControl,
} from "@mui/material";
import { USER_CLIENT } from "../../../api/USER_CLIENT";
export const InterventionNotCreated = ({
  intervention,
  report,
  selectedReport,
  setSelectedReport,
  setReport,
  reports,
  mutations,
}: any) => {
  const [place, setPlace] = useState("");
  const [availableReports, setAvailableReports] = useState<any>([]);
  const fetchClient = useCallback(async () => {
    const response = (await USER_CLIENT.GET_CLIENT(report?.client_id)).data;
    if (report.length !== 0) {
      setPlace(
        `${response[0]?.address1}, ${response[0]?.city} - ${response[0]?.zipCode}`
      );
    }
    setAvailableReports(
      reports.filter((report: any) =>
        report.interventions?.every(
          (intervention: any) => intervention?.finished !== "false"
        )
      )
    );
  }, [report, reports]);
  useEffect(() => {
    fetchClient();
  }, [fetchClient]);

  const handleChangePlace = (e: any) => {
    setPlace(e.target.value);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    await mutations.createIntervention.mutateAsync({
      reportId: report._id,
      place: place,
    });
  };

  return (
    <>
      <Typography
        variant='h2'
        sx={{
          textAlign: "center",
          width: 1,
          padding: "10px",
          backgroundColor: "#F9F9F9",
        }}
      >
        Sélection du rapport
      </Typography>
      <Box
        sx={{
          margin: "auto 20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <Typography variant='h3' sx={{ textAlign: "center", fontSize: "18px" }}>
          Aucune intervention en cours, veuillez sélectionner un rapport et
          saisir le lieu de l'intervention :
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
          }}
          component='form'
          onSubmit={handleSubmit}
        >
          <FormControl
            sx={{ marginTop: "20px", maxWidth: "100%", width: "300px" }}
          >
            <InputLabel id='report-selection-id'>
              Sélection du rapport
            </InputLabel>
            <Select
              labelId='report-selection-id'
              id='report-selection'
              label='Sélection du rapport'
              onChange={(e: any) => {
                setSelectedReport(e.target.value);
                setReport(
                  reports.find(
                    (reportObj: any) => reportObj._id === e.target.value
                  )
                );
                fetchClient();
              }}
              value={selectedReport || ""}
            >
              {availableReports ? (
                availableReports?.map((report: any) => (
                  <MenuItem key={report._id} value={report._id}>
                    {report.title}
                  </MenuItem>
                ))
              ) : (
                <MenuItem>Aucun rapport disponible</MenuItem>
              )}
            </Select>
          </FormControl>
          <TextField
            margin='dense'
            sx={{ width: "300px", marginTop: "20px 0" }}
            fullWidth
            id='place'
            label="Lieu d'intervention"
            name='place'
            autoComplete='place'
            value={place || ""}
            required
            onChange={handleChangePlace}
          />
          <Button type='submit' variant='contained'>
            Commencer mon intervention
          </Button>
        </Box>
      </Box>
    </>
  );
};
