import { Box, Typography, TextField, IconButton } from "@mui/material";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
const ControlElement = ({
  control,
  editMode,
  popUpMode,
  setControles,
  controles,
  setChanged,
}: any) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        gap: "10px",
        justifyContent: "space-between",
        alignItems: "center",
      }}
      key={control._id}
    >
      {editMode && popUpMode ? (
        <TextField
          multiline
          minRows={1}
          maxRows={5}
          label='Contrôle'
          value={control.description || ""}
          required
          fullWidth
          onChange={(e) => {
            setChanged(true);
            setControles(
              controles.map((item: any) =>
                item._id === control._id
                  ? { ...item, description: e.target.value }
                  : item
              )
            );
          }}
          sx={{ fontSize: "20px" }}
        />
      ) : (
        <Typography sx={{ fontSize: "20px" }}>
          - {control.description}
        </Typography>
      )}
      <Box>
        {editMode && popUpMode && (
          <IconButton
            onClick={() => {
              setChanged(true);
              setControles(
                controles.filter((item: any) => item._id !== control._id)
              );
            }}
          >
            <DeleteIcon
              sx={{
                fontSize: "28px",
              }}
            />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default ControlElement;
