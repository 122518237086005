export const getSurfacesStates = (ReportData: any) => {
  let uniqueSurfacesStates: any = [];
  const interventions = ReportData?.interventions;
  interventions.map((intervention: any) =>
    intervention.surfacesConditions?.map(
      (surfaceCondition: any) =>
        !uniqueSurfacesStates.includes(surfaceCondition) &&
        uniqueSurfacesStates.push(surfaceCondition)
    )
  );
  return uniqueSurfacesStates;
};
