import { Box, Typography, Divider, Button } from "@mui/material";
import GroupsIcon from "@mui/icons-material/Groups";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import EditNoteIcon from "@mui/icons-material/EditNote";
import PersonPinCircleIcon from "@mui/icons-material/PersonPinCircle";
import SettingsIcon from "@mui/icons-material/Settings";
const UserNavigation = ({ userNavigation, setUserNavigation }: any) => {
  const menu_items = [
    {
      name: "Liste des rapports",
      icon: <FactCheckIcon sx={{ margin: "0 5px" }} />,
      link: "list_report",
    },
    {
      name: "Mon intervention",
      icon: <EditNoteIcon sx={{ margin: "0 5px" }} />,
      link: "intervention",
    },
    {
      name: "",
      icon: <SettingsIcon sx={{ margin: "0 5px" }} />,
      link: "settings",
    },
  ];

  return (
    <Box
      className='wrapper card'
      sx={{ position: "fixed", bottom: "20px", zIndex: "999" }}
    >
      <Box
        sx={{
          height: "60px",
          display: "flex",
          alignItems: "center",
          gap: 2,
          padding: "10px 20px",
        }}
      >
        <Divider
          sx={{ display: { xs: "none", sm: "block" } }}
          orientation='vertical'
        />
        <Box
          sx={{ display: "flex", alignItems: "center", gap: 2, width: "100%" }}
        >
          {menu_items.map((item, index) => (
            <Button
              key={index}
              sx={{ width: item.name ? "100%" : "auto" }}
              variant={userNavigation === item.link ? "outlined" : "contained"}
              onClick={() => {
                setUserNavigation(item.link);
              }}
            >
              {item.icon}
              <Typography
                sx={{
                  color: userNavigation === item.link ? "primary" : "#fff",
                  fontSize: { xs: "0px", md: "0", lg: "14px" },
                }}
                variant='body2'
              >
                {item.name}
              </Typography>
            </Button>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default UserNavigation;
