import React from "react";
import {
  Box,
  Divider,
  Stack,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import { ADMIN_CLIENT } from "../../../api/ADMIN_CLIENT";
import { ADMIN_SETTINGS } from "../../../api/SETTINGS";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  DataGridPro,
  GridActionsCellItem,
  GridToolbarContainer,
} from "@mui/x-data-grid-pro";
import DeleteIcon from "@mui/icons-material/Delete";
import FullSizeLoading from "../../ui/fullSizeLoading";
import AppareilsProduct_Add from "./AppareilsList_Add";
import AppareilsProduct_Edit from "./AppareilsList_Edit";

const CustomToolbar: React.FunctionComponent<{
  setSearchInputValue: React.Dispatch<React.SetStateAction<string>>;
  searchInputValue: React.Dispatch<
    React.SetStateAction<HTMLButtonElement | null>
  >;
  mutations: any;
  data: any;
}> = ({ setSearchInputValue, searchInputValue, data, mutations }) => {
  //-------- Grid Header --------
  const SearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(event.target.value);
  };
  return (
    <GridToolbarContainer
      sx={{
        padding: `0px`,
      }}
    >
      <Stack
        flex='1'
        sx={{
          backgroundColor: (theme: any) => theme.palette.customGrey.grey1,
          padding: `0px 10px`,
        }}
      >
        <Stack
          sx={{
            height: `60px`,
            "& .MuiFormLabel-root:not(.Mui-focused)": {
              color: "grey !important",
            },
          }}
          direction='row'
          alignItems='center'
          spacing={2}
        >
          <TextField
            size='small'
            value={searchInputValue}
            onChange={SearchInputChange}
            label='Recherche'
            variant='outlined'
          />
          <AppareilsProduct_Add
            API_USER={ADMIN_CLIENT}
            mutations={mutations}
            data={data}
          />
        </Stack>
        <Divider light />
      </Stack>
    </GridToolbarContainer>
  );
};

const AppareilsList = () => {
  const [searchInputValue, setSearchInputValue] = React.useState(``);

  const fetchAppareilList = async () => {
    const response = await ADMIN_SETTINGS.GET_APPAREILS_IN_SETTINGS();
    return response.data;
  };

  const queryClient = useQueryClient();
  const { isLoading, data } = useQuery("AppareilList", fetchAppareilList);

  const mutations = {
    delete: useMutation(
      (appareil_id: string) =>
        ADMIN_SETTINGS.DELETE_SETTINGS_APPAREIL(appareil_id),
      {
        onSuccess: (resp) => {
          queryClient.setQueryData(
            "AppareilList",
            (oldData: any) => resp.data.appareils
          );
        },
      }
    ),
    create: useMutation(ADMIN_SETTINGS.POST_SETTINGS_APPAREIL, {
      onSuccess: (resp) => {
        queryClient.setQueryData(
          "AppareilList",
          (oldData: any) => resp.data.appareils
        );
      },
    }),
    update: useMutation(
      ({ appareil_id, appareil }: any) =>
        ADMIN_SETTINGS.PUT_SETTINGS_APPAREIL(appareil_id, appareil),
      {
        onSuccess: (resp) => {
          queryClient.setQueryData(
            "AppareilList",
            (oldData: any) => resp.data.appareils
          );
        },
      }
    ),
  };

  const handleDeleteUser = async (id: string) => {
    await mutations.delete.mutateAsync(id);
    setOpenDeletePopup({ status: false });
  };

  //-------- Delete Popup --------
  const [OpenDeletePopup, setOpenDeletePopup] = React.useState<{
    status: boolean;
    row?: any;
  }>({ status: false });

  const SearchBarFilter = (rows: any) => {
    return rows.filter(
      (row: any) =>
        !searchInputValue ||
        row.name?.toLowerCase().includes(searchInputValue.toLowerCase()) ||
        row.type?.toLowerCase().includes(searchInputValue.toLowerCase())
    );
  };

  //-------- Grid Columns --------
  const columns = [
    {
      field: `name`,
      headerName: `Nom`,
      flex: 0.4,
    },
    {
      field: `brand`,
      headerName: `Marque`,
      flex: 0.4,
    },
    {
      field: `type`,
      headerName: `Type`,
      flex: 0.4,
    },
    // fixed right
    {
      field: `actions`,
      type: `actions`,
      headerName: `Actions`,
      width: 90,
      resizable: false,
      getActions: (e: { row?: any }) => [
        <AppareilsProduct_Edit
          key='user-grid-edit'
          rowInfos={e.row}
          mutations={mutations}
        />,
        <GridActionsCellItem
          onClick={() => setOpenDeletePopup({ row: e.row, status: true })}
          icon={<DeleteIcon />}
          label='Delete'
          key='grid-actions-cell-item-1'
        />,
      ],
    },
  ];

  const DeleteClientPopup = () => {
    return (
      <Dialog
        open={OpenDeletePopup?.status}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle
          sx={{
            overflow: "hidden",
            fontSize: { xs: "17px", sm: "24px" },
            textOverflow: "ellipsis",
          }}
        >
          Voulez vous vraiment supprimer {OpenDeletePopup?.row?.name} ?
        </DialogTitle>
        <DialogActions
          sx={{
            padding: `10px 20px`,
            backgroundColor: "#f9f9f9",
          }}
        >
          <Button
            sx={{
              color: `text.disabled`,
            }}
            onClick={() => {
              setOpenDeletePopup({ status: false });
            }}
          >
            Annuler
          </Button>
          <Button
            sx={{
              color: `#fff`,
            }}
            variant='contained'
            disableElevation
            onClick={() => {
              OpenDeletePopup?.row?.id &&
                handleDeleteUser(OpenDeletePopup.row.id);
            }}
            autoFocus
          >
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Box
      className='card'
      sx={{ height: "588px", display: "flex", alignItems: "center", gap: 2 }}
    >
      <Box
        sx={{
          height: "100%",
          width: "100%",
        }}
      >
        {!isLoading && data ? (
          <DataGridPro
            autoPageSize
            pagination
            sx={{
              '.MuiDataGrid-booleanCell[data-value="true"] ': {
                color: `#8D919A`,
              },
              borderRadius: `20px`,
              overflow: "hidden",
            }}
            rows={(data[0] && SearchBarFilter(data)) || []}
            columns={columns}
            disableColumnMenu
            localeText={{
              columnsPanelTextFieldLabel: "Chercher une colonne",
              columnsPanelTextFieldPlaceholder: "Nom de la colonne",
              columnsPanelShowAllButton: "Afficher tout",
              columnsPanelHideAllButton: "Masquer tout",
            }}
            components={{
              Toolbar: CustomToolbar,
              NoRowsOverlay: () => (
                <Stack
                  height='100%'
                  alignItems='center'
                  justifyContent='center'
                >
                  Aucune ligne à afficher
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack
                  height='100%'
                  alignItems='center'
                  justifyContent='center'
                >
                  Aucun résultat
                </Stack>
              ),
            }}
            componentsProps={{
              toolbar: {
                setSearchInputValue,
                searchInputValue,
                data,
                mutations,
                API_USER: ADMIN_CLIENT,
              },
            }}
            initialState={{ pinnedColumns: { right: [`actions`] } }}
          />
        ) : (
          <FullSizeLoading />
        )}
        <DeleteClientPopup />
      </Box>
    </Box>
  );
};

export default AppareilsList;
