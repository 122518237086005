import {
  Dialog,
  DialogTitle,
  Box,
  Typography,
  DialogActions,
  Button,
  DialogContent,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  OutlinedInput,
  Stack,
  Chip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { USERS } from "../../../api/USER";
import { useQuery } from "react-query";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import dayjs from "dayjs";
import { ExamConditionsPopUp } from "./ExamConditionsPopUp";
export const FinishInterventionPopup = ({
  open,
  onClose,
  intervention,
  mutations,
  userId,
  currentIntervention,
  setHasFinished,
  hasFinished,
}: any) => {
  const [errorSelectors, setErrorSelectors] = useState("");
  const [errorExamConditions, setErrorExamConditions] = useState("");
  const [openExamConditions, setOpenExamConditions] = useState(false);
  const [hasFinishedExamCondtions, setHasFinishedExamConditions] =
    useState(false);
  const [examConditions, setExamConditions] = useState<any>({
    examProducts: null,
    lightnings: null,
    surfacesConditions: null,
  });
  const fetchUserDevices = async () => {
    let devices: any = [];
    const response = await USERS.GET_USER();
    let devicesIds = response.data?.Appareils;
    const allDevices = (await USERS.GET_APPAREILS_IN_SETTINGS()).data;
    allDevices?.map(
      (device: any) => devicesIds.includes(device.id) && devices.push(device)
    );
    return devices;
  };
  const { data: devicesUser } = useQuery("getDevicesUser", fetchUserDevices);
  const [pickedDate, setPickedDate] = React.useState<Date | null>(
    new Date(Date.now())
  );
  const [usedDevices, setUsedDevices] = React.useState<any[]>([]);
  const handleFinished = async () => {
    if (usedDevices.length === 0) {
      setErrorSelectors(
        "Vous devez sélectionner au moins un appareil utilisé lors de l'intervention"
      );
      return false;
    }
    setErrorSelectors("");
    if (!hasFinishedExamCondtions) {
      setErrorExamConditions(
        "Vous devez remplir les conditions d'examen avant de finir l'intervention"
      );
      return false;
    }
    setErrorExamConditions("");
    setHasFinished(true);
    // Get last items, we don't need to search the corresponding user_id in the intervention since the user is obligatorily in the last intervention (we checked it in the parent)
    currentIntervention.finished = "true";
    await mutations.putIntervention.mutateAsync({
      reportId: intervention._id,
      report: {
        ...intervention,
        status: "userCompleted",
        interventions: intervention.interventions.map((interv: any) => {
          if (interv._id === currentIntervention._id) {
            return {
              ...currentIntervention,
              usedAppareils: usedDevices,
              endDate: pickedDate,
              examProducts: examConditions.examProducts,
              surfacesConditions: examConditions.surfacesConditions,
              lightnings: examConditions.lightnings,
              hasClean: examConditions.hasClean,
            };
          }
          return interv;
        }),
      },
    });
    setHasFinished(true);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"xs"} fullWidth>
      <DialogTitle
        sx={{
          width: 1,
          backgroundColor: "customGrey.grey1",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography sx={{ fontSize: "20px", fontWeight: "600" }}>
            Finir mon intervention
          </Typography>
        </Box>
        <CloseIcon sx={{ cursor: "pointer" }} onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        <>
          <ExamConditionsPopUp
            open={openExamConditions}
            onClose={() => setOpenExamConditions(false)}
            intervention={currentIntervention}
            hasFinishedExamCondtions={hasFinishedExamCondtions}
            setHasFinishedExamConditions={setHasFinishedExamConditions}
            setExamConditions={setExamConditions}
            examConditions={examConditions}
          />
          <Box
            sx={{
              margin: "20px 0",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
              width: 1,
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ width: 1 }}
                label="Date de fin de l'intervention"
                value={dayjs(pickedDate)}
                onChange={(e: any) => setPickedDate(e.$d)}
                format='DD/MM/YYYY'
              />
            </LocalizationProvider>
            <Button
              variant='contained'
              sx={{
                width: 1,
                fontSize: "18px",
                margin: "10px 0",
                padding: "10px 0",
              }}
              onClick={() => setOpenExamConditions(true)}
            >
              Saisir mes conditions d'examen
            </Button>
            <FormControl sx={{ width: 1 }}>
              <InputLabel id='devices-select-label'>
                Appareil(s) utilisé(s) lors de l'intervention
              </InputLabel>
              <Select
                multiple
                required
                labelId='devices-select-label'
                id='devices-select'
                value={devicesUser}
                sx={{ width: 1 }}
                onChange={(e) => {
                  const lastItem = e.target.value[e.target.value.length - 1];
                  !usedDevices.includes(lastItem) &&
                    setUsedDevices([...usedDevices, lastItem]);
                }}
                input={
                  <OutlinedInput label="Appareils utilisés lors de l'intervention" />
                }
                renderValue={(selected) => (
                  <Stack gap={1} direction='row' flexWrap='wrap'>
                    {selected?.map(
                      (value: any) =>
                        usedDevices.includes(value.id) && (
                          <Chip
                            key={value.id}
                            label={value.name}
                            onDelete={() =>
                              setUsedDevices(
                                usedDevices.filter(
                                  (deviceId: any) => deviceId !== value.id
                                )
                              )
                            }
                            deleteIcon={
                              <CancelIcon
                                onMouseDown={(event: any) =>
                                  event.stopPropagation()
                                }
                              />
                            }
                          />
                        )
                    )}
                  </Stack>
                )}
                label='Produits utilisés'
              >
                {devicesUser?.map((device: any) => (
                  <MenuItem
                    key={device.id}
                    value={device.id}
                    sx={{ justifyContent: "space-between", height: "50px" }}
                  >
                    {device.name}
                    {usedDevices.includes(device.id) ? (
                      <CheckIcon color='info' />
                    ) : null}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {errorSelectors && (
              <Typography
                sx={{ textAlign: "center", color: "red", fontWeight: "500" }}
              >
                {errorSelectors}
              </Typography>
            )}
            {errorExamConditions && (
              <Typography
                sx={{ textAlign: "center", color: "red", fontWeight: "500" }}
              >
                {errorExamConditions}
              </Typography>
            )}
          </Box>
        </>
      </DialogContent>
      <DialogActions sx={{ width: 1, backgroundColor: "customGrey.grey1" }}>
        <Button
          variant='contained'
          onClick={async () => {
            await handleFinished();
          }}
        >
          Confirmer
        </Button>
        <Button onClick={onClose}>Annuler</Button>
      </DialogActions>
    </Dialog>
  );
};
