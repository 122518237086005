import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
export const InterventionElements = ({
  element,
  selectedElementId,
  setSelectedElementId,
  setControls,
  setSelectedElementName,
}: any) => {
  return (
    <Box
      key={element._id}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        width: { xs: "200px", xm: "300px" },
        borderRadius: "20px",
        padding: "20px",
        backgroundColor: "primary.main",
        margin: "20px",
        color: "white",
        cursor: "pointer",
      }}
      onClick={() => {
        setSelectedElementName(element.name);
        setSelectedElementId(element._id);
        setControls(element.controles);
      }}
    >
      <Typography sx={{ color: "white", fontSize: "17px" }}>
        {element?.name}
      </Typography>
      <ArrowForwardIcon />
    </Box>
  );
};
