import React from "react";
import { Button, Toolbar, Box, AppBar } from "@mui/material";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo.png";
import { ADMIN } from "../../api/ADMIN";
import { USERS } from "../../api/USER";
import { useQuery } from "react-query";
import jwtDecode from "jwt-decode";
export default function Navigation(props: any) {
  const [state, setState] = React.useState({ open: false });
  const [logged, setLogged] = React.useState(false);
  const [page, setPage] = React.useState("");
  const storedToken = localStorage.getItem("token");
  const role = storedToken && jwtDecode<any>(storedToken)?.user?.role;
  useQuery<any, Error>("isLogged", async () => {
    if (storedToken) {
      let isLogged: any;
      if (role === "USER") {
        isLogged = await USERS.IS_CONNECTED();
      } else if (role === "ADMIN") {
        isLogged = await ADMIN.IS_CONNECTED();
      }
      setLogged(isLogged);
    }
  });

  const handleLogin = (logged: boolean) => {
    if (logged && !window.location.pathname.toLowerCase().startsWith("/")) {
      window.location.replace("/");
    } else {
      logged && localStorage.removeItem("token");
      window.location.replace("/login");
    }
  };
  return (
    <AppBar
      position='static'
      color='inherit'
      sx={{
        width: "100%",
        paddingLeft: "20px",
        paddingRight: "20px",
        boxShadow: "rgba(0, 0, 0, 0.06) 0px 8px 24px",
      }}
    >
      <Toolbar
        sx={{
          padding: "0 !important",
          maxWidth: "1154px",
          width: "100%",
          minHeight: "80px",
          justifyContent: "space-between",
          gap: "10px",
        }}
      >
        <Box sx={{ lineHeight: 1 }}>
          <Link
            to='/'
            onClick={() => {
              setPage("/");
            }}
          >
            <img alt='logo' src={Logo} width={80} />
          </Link>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button
            variant='contained'
            color='primary'
            sx={{
              height: "40px",
              display: "flex",
              color: "white",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => {
              handleLogin(logged);
            }}
          >
            {logged ? <PowerSettingsNewIcon /> : "Connexion"}
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
