import axios from 'axios';

export const ADMIN_SETTINGS = {
GET_SETTINGS: async function () {
    return (axios({
        method: 'get',
        url: `${process.env.REACT_APP_SERVER_URL}/api/admin/settings`,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
    }).then(function (res:any) {
        return res.data;
    })).catch(function (error:any) {
        console.log(error);
        return null;
    });
},

GET_PRODUCTS_IN_SETTINGS: async function () {
    return (axios({
        method: 'get',
        url: `${process.env.REACT_APP_SERVER_URL}/api/admin/settings/products`,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },

    }).then(function (res:any) {
        return res.data;
    })).catch(function (error:any) {
        console.log(error);
        return null;
    });
},
GET_APPAREILS_IN_SETTINGS: async function () {
    return (axios({
        method: 'get',
        url: `${process.env.REACT_APP_SERVER_URL}/api/admin/settings/appareils`,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
    }).then(function (res:any) {
        return res.data;
    })).catch(function (error:any) {
        console.log(error);
        return null;
    });
},

PUT_SETTINGS: async function (settings: any) {
    return (axios({
        method: 'put',
        url: `${process.env.REACT_APP_SERVER_URL}/api/admin/settings/`,
        data: {
            settings: JSON.stringify(settings),
        },
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
    }).then(function (res:any) {
        return res.data;
    })).catch(function (error:any) {
        console.log(error);
        return null;
    });
},

POST_SETTINGS_PRODUCTS: async function (product: any) {
    return (axios({
        method: 'post',
        url: `${process.env.REACT_APP_SERVER_URL}/api/admin/settings/product`,
        data: {
            product: JSON.stringify(product),
        },
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
    }).then(function (res:any) {
        return res.data;
    })).catch(function (error:any) {
        console.log(error);
        return null;
    });
},

POST_SETTINGS_APPAREIL: async function (appareil: any) {
    return (axios({
        method: 'post',
        url: `${process.env.REACT_APP_SERVER_URL}/api/admin/settings/appareil`,
        data: {
            appareil: JSON.stringify(appareil),
        },
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
    }).then(function (res:any) {
        return res.data;
    })).catch(function (error:any) {
        console.log(error);
        return null;
    });
},

PUT_SETTINGS_PRODUCT: async function (product_id: string, product: any) {
    return (axios({
        method: 'put',
        url: `${process.env.REACT_APP_SERVER_URL}/api/admin/settings/product`,
        data: {
            product_id: product_id,
            product: JSON.stringify(product),
        },
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
    }).then(function (res:any) {
        return res.data;
    })).catch(function (error:any) {
        console.log(error);
        return null;
    });
},

PUT_SETTINGS_APPAREIL: async function (appareil_id: string, appareil: any) {
    return (axios({
        method: 'put',
        url: `${process.env.REACT_APP_SERVER_URL}/api/admin/settings/appareil`,
        data: {
            appareil_id: appareil_id,
            appareil: JSON.stringify(appareil),
        },
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
    }).then(function (res:any) {
        return res.data;
    })).catch(function (error:any) {
        console.log(error);
        return null;
    });
},

DELETE_SETTINGS_PRODUCT: async function (product_id: string) {
    return (axios({
        method: 'delete',
        url: `${process.env.REACT_APP_SERVER_URL}/api/admin/settings/product`,
        data: {
            product_id: product_id,
        },
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
    }).then(function (res:any) {
        return res.data;
    })).catch(function (error:any) {
        console.log(error);
        return null;
    });
},

DELETE_SETTINGS_APPAREIL: async function (appareil_id: string) {
    return (axios({
        method: 'delete',
        url: `${process.env.REACT_APP_SERVER_URL}/api/admin/settings/appareil`,
        data: {
            appareil_id: appareil_id,
        },
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
    }).then(function (res:any) {
        return res.data;
    })).catch(function (error:any) {
        console.log(error);
        return null;
    });
},


}