import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Autocomplete,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import EditIcon from "@mui/icons-material/Edit";

function UserManagement_Edit({ API_USER, API, categoryList, data, mutations, ...props }: any) {
  //-------- Add User Popup ---------
  const [OpenEditUserPopup, setOpenEditUserPopup] =
    React.useState<boolean>(false);

  const phoneReg =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  //formik
  const formikSchema = yup.object({
    title: yup
      .string()
      .min(2, `Titre trop court !`)
      .max(50, `Titre trop long !`)
      .required(`Veuillez renseigner un Titre`),
    machine: yup
      .string()
      .required(`Veuillez renseigner une machine`),
    description: yup
      .string()
      .min(2, `Description trop courte !`)
      .max(5000, `Description trop longue !`),
    category: yup.string(),
  });

  const [inputValue, setInputValue] = React.useState("");
  const ThisUser = data?.find(
    (user: any) => user._id === props?.rowInfos?.row._id
  );
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: ThisUser?.title || ``,
      machine:  ThisUser?.machine || ``,
      description: ThisUser?.description || ``,
      category: ThisUser?.category || ``,
    },
    validationSchema: formikSchema,
    onSubmit: async (formValues, { resetForm }) => {
      let modelValues = formValues;
      modelValues.category = inputValue || "";
      await mutations.update.mutateAsync({
        id: props.rowInfos.row._id, 
        updates: modelValues
      })
      handleAddUserPopupClose();
      resetForm();
      },
  });
  const handleAddUserPopupClose = () => {
    formik.resetForm();
    setOpenEditUserPopup(false);
  };

  return (
    //header de user management
    <Box>
      <GridActionsCellItem
        onClick={() => setOpenEditUserPopup(true)}
        icon={<EditIcon />}
        label="Edit"
      />
      <Dialog open={OpenEditUserPopup}>
        <Box component="form" onSubmit={formik.handleSubmit}>
          <DialogTitle sx={{ backgroundColor: "#f9f9f9" }}>
            Modifier un modèle
          </DialogTitle>
          <DialogContent
            sx={{
              width: `560px`,
              maxWidth: `100%`,
              padding: `20px 20px 20px 20px`,
              mt: 2,
              "& .MuiFormLabel-root:not(.Mui-focused, .Mui-error)": {
                color: "grey !important",
              },
            }}
          >
            <TextField
              margin="dense"
              fullWidth
              id="title"
              label="Titre"
              name="title"
              autoComplete="title"
              value={formik.values.title}
              onChange={formik.handleChange}
              error={formik.touched.title && Boolean(formik.errors.title)}
              // helperText={
              //   formik.touched.title && formik.errors.title
              // }
            />
            <TextField
                margin='dense'
                fullWidth
                id='machine'
                label='Machine'
                name='machine'
                autoComplete='machine'
                value={formik.values.machine}
                onChange={formik.handleChange}
                error={formik.touched.machine && Boolean(formik.errors.machine)}
                helperText={formik.touched.machine && formik.errors.machine?.toString()}
              />
            <TextField
              margin="dense"
              fullWidth
              id="description"
              label="Description"
              name="description"
              autoComplete="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              multiline
              rows={4}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              // helperText={
              //   formik.touched.description && formik.errors.description
              // }
            />
            {categoryList && (
              <Autocomplete
                freeSolo
                value={formik.values.category}
                onChange={formik.handleChange}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                id="controllable-states-demo"
                options={categoryList}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{ ...params.inputProps, maxLength: 100 }}
                    margin="dense"
                    fullWidth
                    id="category"
                    label="Catégorie"
                    name="category"
                    autoComplete="category"
                    value={formik.values.category}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.category && Boolean(formik.errors.category)
                    }
                    // helperText={
                    //   formik.touched.category && formik.errors.category
                    // }
                  />
                )}
              />
            )}
          </DialogContent>
          <DialogActions
            sx={{
              padding: `10px 20px`,
              backgroundColor: "#f9f9f9",
            }}
          >
            <Button
              sx={{
                color: `text.disabled`,
              }}
              onClick={handleAddUserPopupClose}
            >
              Annuler
            </Button>
            <Button
              type="submit"
              sx={{
                color: `#fff`,
              }}
              variant="contained"
              disableElevation
              onClick={() => null}
              autoFocus
            >
              Enregistrer
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
}

export default UserManagement_Edit;
