import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  Grid,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { PopUpDelete } from "../../ui/PopUpDelete";
import { EditElementPopUp } from "./EditElementPopUp";

const ElementModel = ({
  element,
  mutations,
  viewType,
  deleteMutation,
}: any) => {
  const [controles, setControles] = useState(element.controles || []);
  const [editMode, setEditMode] = useState(false);
  const [popUpDelete, setPopUpDelete] = useState(false);

  return (
    <Box
      key={element._id}
      sx={{
        overflow: "hidden",
        marginBottom: "30px",
      }}
      className='card'
    >
      <EditElementPopUp
        open={editMode}
        toggleClose={() => setEditMode((isOpen) => !isOpen)}
        element={element}
        mutations={mutations}
        editMode={editMode}
        setEditMode={setEditMode}
        setControles={setControles}
        controles={controles}
      />
      <Box
        sx={{
          width: "100%",
          backgroundColor: "#F9F9F9",
          height: "60px",
          padding: "0 20px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant='h2'>{element.name}</Typography>
        <Box
          sx={{
            gap: "10px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Button
            sx={{
              color: "white",
              padding: "8px 15px",
            }}
            variant='contained'
            onClick={() => setEditMode((isEditMode: Boolean) => !isEditMode)}
          >
            <EditIcon
              sx={{
                fontSize: "17px",
                mr: "6px",
              }}
            />
            <Typography sx={{ fontSize: "17px", color: "white" }}>
              Editer
            </Typography>
          </Button>
          <IconButton onClick={() => setPopUpDelete(true)}>
            <DeleteIcon sx={{ fontSize: "28px" }} />
          </IconButton>
          <PopUpDelete
            open={popUpDelete}
            mutationsFunction={deleteMutation}
            elementId={element._id}
            headerText={"Voulez-vous supprimer l'élément sélectionné ?"}
            toggleClose={() => setPopUpDelete((isOpen) => !isOpen)}
          />
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: "white",
          padding: "20px",
        }}
      >
        {!element.controles && (
          <Typography
            variant='body1'
            sx={{
              padding: "5px 0",
              textAlign: "center",
            }}
          >
            {viewType === "INTERVENTION"
              ? "L'élément n'a pas de contrôle défini"
              : "Vous n'avez pas saisi de contrôle pour le moment"}
          </Typography>
        )}
        {element?.controles && (
          <Grid container spacing={2}>
            {element?.controles?.map((control: any) => {
              return (
                <Grid item xs={6} key={control._id}>
                  <TextField
                    minRows={3}
                    multiline
                    maxRows={3}
                    disabled
                    InputProps={{
                      sx: {
                        color: "#fff", // Changer la couleur du texte en noir
                      },
                    }}
                    sx={{
                      width: "100%",
                    }}
                    className='azeazezaeaze'
                    value={control.description}
                    autoFocus
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default ElementModel;
