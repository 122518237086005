export const getLightnings = (ReportData: any) => {
  let uniqueLigthings: any = [];
  const interventions = ReportData?.interventions;
  interventions.map((intervention: any) =>
    intervention.lightnings?.map(
      (lightning: any) =>
        !uniqueLigthings.includes(lightning) && uniqueLigthings.push(lightning)
    )
  );
  return uniqueLigthings;
};
