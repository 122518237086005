import gmsLogo from "../../../assets/gms-logo.jpg";
import TableauInterventions from "./ExportRapportComposants/TableauInterventions";
import TableauConclusion from "./ExportRapportComposants/TableauConclusion";
import {
  PreRenderRecapControl,
  RecapControl,
} from "./ExportRapportComposants/RecapControl";
import {
  Footer,
  Header,
  formatInterventionDates,
  ItemComponent,
  UsedAppareil,
  getLatestControls,
} from "./ExportRapportComposants/ExportRapportComposants";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import {
  TableauObservations,
  PreRenderTableauObservations,
} from "./ExportRapportComposants/TableauObservations";
import { ExamsConditions } from "./ExportRapportComposants/ExamsConditions";

const ExportRapport = ({ exportData, formData }: any) => {
  const {
    date: FormDate,
    responsable: FormResponsable,
    nature: FormNature,
    indice: FormIndice,
    affaire_num,
    techniqueOperatoire,
  } = formData;
  const { ReportData, ClientData, UserData, SettingsData, ModeleData } =
    exportData;
  const {
    acceptedCriteria,
    category,
    description,
    machine,
    title,
    model,
    lexicon,
  } = ModeleData;
  const {
    reportNumber,
    interventions,
    controleReason,
    _id: report_id,
    sectionSite,
    site,
  } = ReportData;
  const { name, family_name, address1, address2, zipCode, city } =
    ClientData[0];
  const { refereDocument } = ModeleData;

  const [pageCount, setPageCount] = useState<any>();
  const [formatedData, setFormatedData] = useState<any>();

  const generateSentence = (data: any) => {
    const hasNonConformingControl = data.some(
      (item: any) => item?.result?.conforme === "false"
    );

    if (hasNonConformingControl) {
      return (
        <p>
          Le contrôle a révélé{" "}
          <span style={{ fontWeight: 600 }}>DES DEFAUTS</span>. Des
          sous-ensembles sont jugés{" "}
          <span style={{ fontWeight: 600 }}>NON CONFORMES</span>.
        </p>
      );
    } else {
      return (
        <p>
          Le contrôle n'a révélé{" "}
          <span style={{ fontWeight: 600 }}>AUCUN DEFAUT</span>. Les
          sous-ensembles sont jugés{" "}
          <span style={{ fontWeight: 600 }}>CONFORMES</span>.
        </p>
      );
    }
  };

  useEffect(() => {
    setFormatedData(getLatestControls(ReportData.interventions));
  }, [ReportData.interventions]);

  if (!formatedData) {
    return <div>pas assez d'infos</div>;
  }

  return (
    <div id='pdf'>
      <div className='page'>
        <div className='header_main'>
          <div className='header_main_gms'>
            <div className='header_main_logo_cont'>
              <img src={gmsLogo} />
            </div>
            <div className='header_main_gms_infos'>
              <p>163 Rue de l'Industrie</p>
              <p>ZA Les Remblais</p>
              <p>73220 EPIERRE</p>
              <p>Tél : 04 79 36 95 13</p>
              <p>Site : gmsindustrie.com</p>
              <p>Email : gmsinidustrie@wanadoo.fr</p>
              <p>SIRET : 412 222 085 00026</p>
            </div>
          </div>
          <div className='header_main_infos'>
            <div className='header_main_infos_client'>
              <p>
                {name} {family_name}
              </p>
              <p>{address1}</p>
              <p>{address2}</p>
              <p>
                {zipCode} {city}
              </p>
            </div>
            <div className='header_main_infos_report'>
              <p>Rapport de contrôle n° {reportNumber}</p>
              <p>Appareil : {machine}</p>
              <p>Numéro d'affaire : {affaire_num}</p>
            </div>
          </div>
        </div>
        <div className='page_content'>
          <div className='page_section'>
            <div className='page_section_title'>
              Rapport de contrôle n° {reportNumber}
            </div>
            <div
              className='page_section_content'
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <div
                style={{
                  width: "100%",
                  maxWidth: "80%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <ItemComponent title='Appareil' content={machine} />
                <ItemComponent title={site} content={sectionSite} />
              </div>
              <div>
                <div style={{ display: "flex" }}>
                  <h3
                    style={{
                      fontWeight: 400,
                      minWidth: "270px",
                      textDecoration: "underline",
                    }}
                  >
                    Objet du contrôle :
                  </h3>
                  <p>{controleReason}</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    minWidth: "250px",
                  }}
                >
                  <h3
                    style={{
                      fontWeight: 400,
                      minWidth: "270px",
                      textDecoration: "underline",
                    }}
                  >
                    Sous-ensembles examinés :
                  </h3>{" "}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    {model?.map((thisModel: any) => (
                      <p>{thisModel.name}</p>
                    ))}
                  </div>
                </div>
              </div>
              <div
                style={{
                  marginTop: "40px",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <h3
                    style={{
                      textDecoration: "underline",
                      fontWeight: 400,
                      fontSize: "19px",
                      minWidth: "270px",
                    }}
                  >
                    Critères d'acceptation :
                  </h3>
                  <p>acceptedCriteria</p>
                </div>
              </div>
            </div>
            <div
              style={{
                marginTop: "25px",
              }}
            >
              <p style={{ marginBottom: "20px" }}>
                Interventions du {formatInterventionDates(interventions)}
              </p>
              <TableauInterventions
                interventions={interventions}
                userData={UserData}
              />
            </div>
            <div className='page_section_title'>Conclusions</div>
            <p>{generateSentence(formatedData)}</p>
            <div style={{ width: "90%", margin: "auto" }}>
              <TableauConclusion
                date={FormDate}
                responsable={FormResponsable}
                nature={FormNature}
                indice={FormIndice}
              />
            </div>
          </div>
        </div>
        <Footer totalPages={pageCount} setPageCount={setPageCount} />
      </div>

      <div className='page'>
        <Header
          report_num={reportNumber}
          machine={machine}
          affaire_num={affaire_num}
        />
        <div className='page_content'>
          <div className='page_section'>
            <div className='page_section_title'>Documents de référence</div>
            <div className='page_section_content'>
              <pre>
                <code className='multiline'>{refereDocument}</code>
              </pre>
            </div>
            <div className='page_section_title'>Méthode de magnétisation</div>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <h3 style={{ fontSize: "19px", minWidth: "200px" }}>
                  Aimantation :
                </h3>
                <p>Par passage de flux</p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <h3 style={{ fontSize: "19px", minWidth: "200px" }}>
                  Nature du courant :
                </h3>
                <p>Alternatif</p>
              </div>
              <UsedAppareil
                interventions={ReportData.interventions}
                appareils={SettingsData.appareils}
              />
              {techniqueOperatoire && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <h3 style={{ fontSize: "19px" }}>Technique opératoire :</h3>
                  <p>{techniqueOperatoire}</p>
                </div>
              )}
            </div>

            <ExamsConditions ReportData={ReportData} id={"original"} />
          </div>
        </div>
        <Footer totalPages={pageCount} setPageCount={setPageCount} />
      </div>

      <div className='page' id={"aftertest"}>
        <Header
          report_num={reportNumber}
          machine={machine}
          affaire_num={affaire_num}
        />
        <div className='page_content'>
          <div className='page_section'>
            <ExamsConditions ReportData={ReportData} id={"aftertestchild"} />
          </div>
        </div>
        <Footer totalPages={pageCount} setPageCount={setPageCount} />
      </div>

      <div className='pagePrerender' id='preRenderRecapControl'>
        <div className='page_content'>
          <div className='page_section'>
            <PreRenderRecapControl
              model={ModeleData.model}
              controlChunk={formatedData}
            />
          </div>
        </div>
      </div>

      <RecapControl
        model={ModeleData.model}
        controlChunk={formatedData}
        totalPages={pageCount}
        setPageCount={setPageCount}
        controleReason={controleReason}
        affaire_num={affaire_num}
        machine={machine}
        reportNumber={reportNumber}
        lexicon={lexicon}
      />

      <div className='pagePrerender' id='preRenderObservationControl'>
        <div className='page_content'>
          <div className='page_section'>
            <PreRenderTableauObservations
              controlChunk={formatedData}
              report_id={report_id}
            />
          </div>
        </div>
      </div>

      <TableauObservations
        controlChunk={formatedData}
        totalPages={pageCount}
        setPageCount={setPageCount}
        affaire_num={affaire_num}
        machine={machine}
        report_id={report_id}
        reportNumber={reportNumber}
      />
    </div>
  );
};

export default ExportRapport;
