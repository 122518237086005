import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  Box,
  Typography,
  DialogActions,
  Button,
  DialogContent,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  OutlinedInput,
  Stack,
  Chip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { USERS } from "../../../api/USER";
import { useQuery } from "react-query";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import { ProductsPopUp } from "./ProductsPopUp";

export const ExamConditionsPopUp = ({
  open,
  onClose,
  intervention,
  setIntervention,
  hasFinishedExamCondtions,
  setHasFinishedExamConditions,
  examConditions,
  setExamConditions,
}: any) => {
  const fetchProducts = async () => {
    const response = await USERS.GET_PRODUCTS_IN_SETTINGS();
    return response.data;
  };
  const [lightnings, setLightnings] = useState<any>([]);
  const [usedProducts, setUsedProducts] = useState<any>([]);
  const [openProducts, setOpenProducts] = useState(false);
  const [hasClean, setHasClean] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [encounteredSurfacesConditions, setEncounteredSurfacesConditions] =
    useState<any>([]);
  const { isLoading, data: products } = useQuery(
    "getProductsExam",
    fetchProducts
  );

  const surfacesConditions = [
    {
      name: "Galva",
      type: "galva",
    },
    {
      name: "Brut",
      type: "brut",
    },
    {
      name: "Peint",
      type: "peint",
    },
    {
      name: "Usiné",
      type: "usiné",
    },
  ];
  const lightningOptions = [
    {
      name: "Naturel",
      type: "naturel",
    },
    {
      name: "Artificiel",
      type: "artificiel",
    },
    {
      name: "UV",
      type: "UV",
    },
  ];
  const handleSubmit = () => {
    if (encounteredSurfacesConditions.length === 0) {
      setErrorText("Vous devez sélectionner au moins un état de surface");
      return;
    }
    setExamConditions({
      ...examConditions,
      examProducts: usedProducts,
      lightnings: lightnings,
      surfacesConditions: encounteredSurfacesConditions,
      hasClean: hasClean,
    });
    setHasFinishedExamConditions(true);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"xs"} fullWidth>
      <DialogTitle
        sx={{
          width: 1,
          backgroundColor: "customGrey.grey1",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography sx={{ fontSize: "20px", fontWeight: "600" }}>
            Mes conditions d'examen
          </Typography>
        </Box>
        <CloseIcon sx={{ cursor: "pointer" }} onClick={onClose} />
      </DialogTitle>
      <Box
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
        component='form'
      >
        <DialogContent>
          <Box
            sx={{
              margin: "20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <FormControl sx={{ width: 1 }}>
              <InputLabel id='has-clean-selector-label'>Nettoyage</InputLabel>
              <Select
                labelId='has-clean-selector-label'
                id='has-clean-selector'
                label='Nettoyage'
                value={hasClean}
                required
                sx={{ width: "100%" }}
                onChange={(e: any) => setHasClean(e.target.value)}
              >
                <MenuItem value={true as any}>Oui</MenuItem>
                <MenuItem value={false as any}>Non</MenuItem>
              </Select>
            </FormControl>
            {/* <FormControl sx={{ width: 1 }}>
              <InputLabel id='lightning-selector-label'>
                Type d'éclairage
              </InputLabel>
              <Select
                labelId='lightning-selector-label'
                id='lightning-selector'
                label="Type d'éclairage"
                required
                value={lightning}
                sx={{ width: 1 }}
                onChange={(e: any) => setLightning(e.target.value)}
              >
                {lightningOptions.map((lightningOption: any, index: number) => (
                  <MenuItem key={index} value={lightningOption.type}>
                    {lightningOption.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
            <FormControl sx={{ width: 1 }}>
              <InputLabel id='lightnings-select-label'>
                Types d'éclairages
              </InputLabel>
              <Select
                multiple
                required
                labelId='lightnings-select-label'
                id='lightnings'
                value={lightnings || ""}
                sx={{ width: 1 }}
                onChange={(e: any) => {
                  const lastItem = e.target.value[e.target.value.length - 1];
                  !lightnings.includes(lastItem) &&
                    setLightnings([...lightnings, lastItem]);
                }}
                input={<OutlinedInput label='Etat(s) de surface' />}
                renderValue={(selected) => (
                  <Stack gap={1} direction='row' flexWrap='wrap'>
                    {lightningOptions?.map(
                      (lightninOption: any, index) =>
                        lightnings.includes(lightninOption.type) && (
                          <Chip
                            key={index}
                            label={lightninOption.name}
                            onDelete={() =>
                              setLightnings(
                                lightnings.filter(
                                  (lightning: any) =>
                                    lightning !== lightninOption.type
                                )
                              )
                            }
                            deleteIcon={
                              <CancelIcon
                                onMouseDown={(event: any) =>
                                  event.stopPropagation()
                                }
                              />
                            }
                          />
                        )
                    )}
                  </Stack>
                )}
                label='Produits utilisés'
              >
                {lightningOptions?.map(
                  (lightningOption: any, index: number) => (
                    <MenuItem
                      key={index}
                      value={lightningOption.type}
                      sx={{ justifyContent: "space-between", height: "50px" }}
                    >
                      {lightningOption.name}
                      {lightnings.includes(lightningOption.type) ? (
                        <CheckIcon color='info' />
                      ) : null}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
            <FormControl sx={{ width: 1 }}>
              <InputLabel id='surface-condition-select-label'>
                Etat(s) de surface
              </InputLabel>
              <Select
                multiple
                required
                labelId='surface-condition-select-label'
                id='surface-condition'
                value={surfacesConditions || ""}
                sx={{ width: 1 }}
                onChange={(e: any) => {
                  const lastItem = e.target.value[e.target.value.length - 1];
                  !encounteredSurfacesConditions.includes(lastItem) &&
                    setEncounteredSurfacesConditions([
                      ...encounteredSurfacesConditions,
                      lastItem,
                    ]);
                }}
                input={<OutlinedInput label='Etat(s) de surface' />}
                renderValue={(selected) => (
                  <Stack gap={1} direction='row' flexWrap='wrap'>
                    {surfacesConditions?.map(
                      (surfaceCondition: any, index) =>
                        encounteredSurfacesConditions.includes(
                          surfaceCondition.type
                        ) && (
                          <Chip
                            key={index}
                            label={surfaceCondition.name}
                            onDelete={() =>
                              setEncounteredSurfacesConditions(
                                encounteredSurfacesConditions.filter(
                                  (productType: any) =>
                                    productType !== surfaceCondition.type
                                )
                              )
                            }
                            deleteIcon={
                              <CancelIcon
                                onMouseDown={(event: any) =>
                                  event.stopPropagation()
                                }
                              />
                            }
                          />
                        )
                    )}
                  </Stack>
                )}
                label='Produits utilisés'
              >
                {surfacesConditions?.map(
                  (surfaceCondition: any, index: number) => (
                    <MenuItem
                      key={index}
                      value={surfaceCondition.type}
                      sx={{ justifyContent: "space-between", height: "50px" }}
                    >
                      {surfaceCondition.name}
                      {encounteredSurfacesConditions.includes(
                        surfaceCondition.type
                      ) ? (
                        <CheckIcon color='info' />
                      ) : null}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
            <Button
              variant='contained'
              sx={{
                width: 1,
              }}
              onClick={() => setOpenProducts(true)}
            >
              Saisir les produits utilisés
            </Button>
            {!isLoading && (
              <ProductsPopUp
                open={openProducts}
                products={products}
                usedProducts={usedProducts}
                setUsedProducts={setUsedProducts}
                onClose={() => setOpenProducts(false)}
              />
            )}

            {/* {errorSelectors && (
            <Typography
              sx={{ textAlign: "center", color: "red", fontWeight: "500" }}
            >
              {errorSelectors}
            </Typography>
          )} */}
          </Box>
          {errorText !== "" && (
            <Box>
              <Typography
                sx={{ color: "red", fontWeight: "500", textAlign: "center" }}
              >
                {errorText}
              </Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ width: 1, backgroundColor: "customGrey.grey1" }}>
          <Box>
            <Button type='submit' variant='contained'>
              Confirmer
            </Button>
            <Button onClick={onClose}>Annuler</Button>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
