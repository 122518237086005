import dayjs from "dayjs";
import gmsLogo from "../../../../assets/gms-logo.jpg";
import certif from "../../../../assets/certif.png";
import { useEffect, useState } from "react";
export const ItemComponent = ({
  title,
  content,
}: {
  title: string;
  content: string[] | string;
}) => {
  const normalizedContent = Array.isArray(content) ? content : [content];

  const renderContent = () =>
    normalizedContent.map((item, index) => (
      <div key={index}>
        <p>{item}</p>
      </div>
    ));
  if (!content) return null;
  return (
    <div className='content_item'>
      <p className='content_item_title'>{title} :</p>
      <div className='content_item_content'>{renderContent()}</div>
    </div>
  );
};

export const formatInterventionDates = (interventions: any[]) => {
  const startDates = interventions.map((i: any) =>
    dayjs(i.startDate).valueOf()
  );
  const endDates = interventions.map((i: any) => dayjs(i.endDate).valueOf());

  const formattedStartDate = dayjs(Math.min(...startDates)).format(
    "DD/MM/YYYY"
  );
  const formattedEndDate = dayjs(Math.max(...endDates)).format("DD/MM/YYYY");
  if (startDates.length === 1) {
    return `${formattedStartDate}`;
  }
  return `${formattedStartDate} au ${formattedEndDate}`;
};

export const UsedAppareil = ({ interventions, appareils }: any) => {
  const usedAppareilIds = interventions.reduce(
    (ids: string[], intervention: any) => {
      if (intervention.usedAppareils) {
        return [...ids, ...intervention.usedAppareils];
      }
      return ids;
    },
    []
  );
  const uniqueAppareils = appareils.filter((appareil: any) =>
    usedAppareilIds.includes(appareil.id)
  );
  return (
    <div className='content_item'>
      <p
        className='content_item_title'
        style={{ fontWeight: 600, textDecoration: "none", minWidth: "200px" }}
      >
        Appareils utilisés :
      </p>
      <div className='content_item_content'>
        {uniqueAppareils.map((appareil: any, i1: number) => (
          <div key={i1}>
            <p>
              {appareil.name} <span>Marque : </span>
              {appareil.brand}
              <span>Type : </span>
              {appareil.type}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export const Header = ({ report_num, machine, affaire_num }: any) => {
  return (
    <div className='header'>
      <div className='header_logo_cont'>
        <img src={gmsLogo} />
      </div>
      <div className='header_infos_cont'>
        <p>Rapport de contrôle n° {report_num}</p>
        <p>Appareil : {machine}</p>
        <p>Numéro d'affaire : {affaire_num}</p>
      </div>
    </div>
  );
};
export const Footer = ({
  totalPages,
  setPageCount,
}: {
  totalPages: number;
  setPageCount: any;
}) => {
  const addNumberToPagination = () => {
    const paginationDivs = document.querySelectorAll(".pagination");

    paginationDivs.forEach((div, index) => {
      div.textContent = (index + 1).toString();
    });
  };
  useEffect(() => {
    const pages = document.querySelectorAll(".page");
    setPageCount(pages?.length);
    addNumberToPagination();
  }, []);

  return (
    <div className='footer'>
      <div className='footer_infos'>
        <p className='litte_text'>
          GMS INDUSTRIE
          <br />
          Tous travaux de soudure, contrôle, maintenance, chaudronnerie,
          serrurerie, fabrication et montage de pièces
          <br />
          Contrôleurs certifiés Cofrend II selon NF EN ISO 9712 / Soudeurs
          agréés suivant NF EN ISO 9606-1
        </p>
      </div>
      <div className='footer_pagination'>
        <img className='footer_certif' src={certif} />
        <p>
          Page <span className='pagination'></span> sur {totalPages}
        </p>
      </div>
    </div>
  );
};

interface Control {
  _id: string;
  description: string;
  result?: {
    conforme: string;
    observations: string[];
  };
}

interface InterventionElement {
  controles: Control[];
  name: string;
  _id: string;
}

interface Intervention {
  user_id: string;
  place: string;
  startDate: string;

  intervention: InterventionElement[];
  finished: string;
  _id: string;
}

export const getLatestControls = (
  interventions: Intervention[]
): Control[] | null => {
  const controlsMap: {
    [key: string]: Control & { startDate: string } & { name: string };
  } = {};

  if (!interventions || interventions.length === 0) {
    return null;
  }
  for (const intervention of interventions) {
    if (
      !intervention?.intervention ||
      intervention?.intervention?.length === 0 ||
      !intervention
    ) {
      return null;
    }
    for (const element of intervention?.intervention) {
      if (element?.controles) {
        for (const control of element?.controles) {
          if (
            !controlsMap[control?._id] ||
            dayjs(controlsMap[control?._id]?.startDate).isBefore(
              dayjs(intervention?.startDate)
            )
          ) {
            controlsMap[control?._id] = {
              ...control,
              startDate: intervention?.startDate,
              name: element?.name,
            };
          }
        }
      }
    }
  }

  return Object.values(controlsMap);
};
