import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import EditIcon from "@mui/icons-material/Edit";
function UserManagement_Edit({
  API_USER,
  API,
  mutations,
  data,
  ...props
}: any) {
  //-------- Add User Popup ---------
  const [OpenEditUserPopup, setOpenEditUserPopup] =
    React.useState<boolean>(false);
  const [emailAlreadyExist, setEmailAlreadyExist] = React.useState<string>(``);

  //formik
  const formikSchema = yup.object({
    name: yup
      .string()
      .matches(/^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ\s\-/.]+$/, `Prenom invalide`)
      .min(2, `Prenom trop court !`)
      .max(50, `Prenom trop long !`)
      .required(`Veuillez renseigner votre prenom`),
    family_name: yup
      .string()
      .matches(/^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ\s\-/.]+$/, `Nom invalide`)
      .min(2, `Nom trop court !`)
      .max(50, `Nom trop long !`)
      .required(`Veuillez renseigner votre nom`),
    email: yup
      .string()
      .email()
      .required("Veuillez renseigner l'email de l'agent."),
    CardNumber: yup
      .string()
      .required("Veuillez saisir le numéro de carte de l'agent."),
  });
  const ThisUser: {
    name: string;
    family_name: string;
    email: string;
    CardNumber: string;
  } = data?.find((user: any) => user.user_id === props?.rowInfos?.row.user_id);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: ThisUser?.name || ``,
      family_name: ThisUser?.family_name || ``,
      email: ThisUser?.email || ``,
      CardNumber: ThisUser?.CardNumber || ``,
    },
    validationSchema: formikSchema,
    onSubmit: async (formValues, { resetForm }) => {
      try {
        await mutations.update.mutateAsync({
          id: props.rowInfos.row.user_id,
          updates: formValues,
        });
        handleAddUserPopupClose();
        resetForm();
      } catch {
        setEmailAlreadyExist(`Cet email est déjà utilisé`);
      }
    },
  });

  const handleAddUserPopupClose = () => {
    formik.resetForm();
    setOpenEditUserPopup(false);
  };

  return (
    //header de user management
    <Box>
      <GridActionsCellItem
        onClick={() => setOpenEditUserPopup(true)}
        icon={<EditIcon />}
        label='Edit'
      />
      <Dialog open={OpenEditUserPopup}>
        <Box component='form' onSubmit={formik.handleSubmit}>
          <DialogTitle sx={{ backgroundColor: "#f9f9f9" }}>
            Modifier un agent
          </DialogTitle>
          <DialogContent
            sx={{
              width: `560px`,
              maxWidth: `100%`,
              padding: `20px 20px 20px 20px`,
              mt: 2,
              "& .MuiFormLabel-root:not(.Mui-focused, .Mui-error)": {
                color: "grey !important",
              },
            }}
          >
            <TextField
              margin='dense'
              fullWidth
              id='name'
              label='Prenom'
              name='name'
              autoComplete='name'
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <TextField
              margin='dense'
              fullWidth
              id='family_name'
              label='Nom'
              name='family_name'
              autoComplete='family_name'
              value={formik.values.family_name}
              onChange={formik.handleChange}
              error={
                formik.touched.family_name && Boolean(formik.errors.family_name)
              }
              helperText={
                formik.touched.family_name && formik.errors.family_name
              }
            />
            <TextField
              margin='dense'
              fullWidth
              id='email'
              label='Email'
              name='email'
              autoComplete='email'
              value={formik.values.email}
              onChange={formik.handleChange}
              error={
                (emailAlreadyExist && true) ||
                (formik.touched.email && Boolean(formik.errors.email))
              }
              helperText={
                (formik.touched.email && formik.errors.email) ||
                (emailAlreadyExist && emailAlreadyExist)
              }
            />
            <TextField
              margin='dense'
              fullWidth
              id='CardNumber'
              label='Numéro de carte'
              name='CardNumber'
              autoComplete='CardNumber'
              value={formik.values.CardNumber}
              onChange={formik.handleChange}
              error={
                formik.touched.CardNumber && Boolean(formik.errors.CardNumber)
              }
              helperText={formik.touched.CardNumber && formik.errors.CardNumber}
            />
          </DialogContent>
          <DialogActions
            sx={{
              padding: `10px 20px`,
              backgroundColor: "#f9f9f9",
            }}
          >
            <Button
              sx={{
                color: `text.disabled`,
              }}
              onClick={handleAddUserPopupClose}
            >
              Annuler
            </Button>
            <Button
              type='submit'
              sx={{
                color: `#fff`,
              }}
              variant='contained'
              disableElevation
              onClick={() => null}
              autoFocus
            >
              Enregistrer
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
}

export default UserManagement_Edit;
