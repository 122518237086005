import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import EditIcon from "@mui/icons-material/Edit";

function ListProduct_Edit({rowInfos, mutations }: any) {
  //-------- Add User Popup ---------
  const [OpenEditUserPopup, setOpenEditUserPopup] =
    React.useState<boolean>(false);

   
    const [type, setType] = React.useState(rowInfos?.type || 'CONTRASTE');

    const handleChangeType = (event: any) => {
        setType(event.target.value as string);
    };


  //formik
  const formikSchema = yup.object({
    name: yup
      .string()
      .min(2, `Titre trop court !`)
      .max(50, `Titre trop long !`)
      .required(`Veuillez renseigner un nom`),
  });


  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
        name: rowInfos?.name || ``,
      },
    validationSchema: formikSchema,
    onSubmit: async (formValues, { resetForm }) => {
      await mutations.update.mutateAsync({
        product_id: rowInfos?.id,
        product: {
          name: formValues.name, 
          type: type
        },
      })
      handleAddUserPopupClose();
      resetForm();
      },
  });
  const handleAddUserPopupClose = () => {
    formik.resetForm();
    setOpenEditUserPopup(false);
  };

  return (
    //header de user management
    <Box>
      <GridActionsCellItem
        onClick={() => setOpenEditUserPopup(true)}
        icon={<EditIcon />}
        label="Edit"
      />
      <Dialog open={OpenEditUserPopup}>
        <Box component="form" onSubmit={formik.handleSubmit}>
          <DialogTitle sx={{ backgroundColor: "#f9f9f9" }}>
            Modifier un produit
          </DialogTitle>
          <DialogContent
            sx={{
              width: `560px`,
              maxWidth: `100%`,
              padding: `20px 20px 20px 20px`,
              mt: 2,
              "& .MuiFormLabel-root:not(.Mui-focused, .Mui-error)": {
                color: "grey !important",
              },
            }}
          >
           <TextField
              margin="dense"
              sx={{mb:'15px'}}
              fullWidth
              id="name"
              label="Nom"
              name="name"
              autoComplete="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={
                formik.touched.name && Boolean(formik.errors.name)
              }
              helperText={
                formik.touched.name && formik.errors.name
              }
            />   
             <Select
             margin="dense"
            id="demo-simple-select"
            fullWidth
            value={type}
            onChange={handleChangeType}
            >
          <MenuItem value={"CONTRASTE"}>Contraste</MenuItem>
          <MenuItem value={"REVELATEUR"}>Revelateur</MenuItem>
            </Select>
          </DialogContent>
          <DialogActions
            sx={{
              padding: `10px 20px`,
              backgroundColor: "#f9f9f9",
            }}
          >
            <Button
              sx={{
                color: `text.disabled`,
              }}
              onClick={handleAddUserPopupClose}
            >
              Annuler
            </Button>
            <Button
              type="submit"
              sx={{
                color: `#fff`,
              }}
              variant="contained"
              disableElevation
              onClick={() => null}
              autoFocus
            >
              Enregistrer
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
}

export default ListProduct_Edit;
