import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import EditIcon from "@mui/icons-material/Edit";

function ListClient_Edit({
  API_USER,
  API,
  categoryList,
  data,
  mutations,
  ...props
}: any) {
  //-------- Add User Popup ---------
  const [OpenEditUserPopup, setOpenEditUserPopup] =
    React.useState<boolean>(false);

  const phoneReg =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  //formik
  const formikSchema = yup.object({
    socialReason: yup
      .string()
      .min(2, `Titre trop court !`)
      .max(50, `Titre trop long !`)
      .required(`Veuillez renseigner un nom`),
    email: yup.string().email(`Email invalide`),
    phone: yup.string().matches(phoneReg, `Numéro invalide`),
    address1: yup.string().required(`Veuillez renseigner une adresse`),
    address2: yup.string(),
    zipCode: yup.string().required(`Veuillez renseigner un code postal`),
    city: yup.string().required(`Veuillez renseigner une ville`),
  });

  const ThisUser = data?.find(
    (user: any) => user._id === props?.rowInfos?.row._id
  );

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      socialReason: ThisUser?.socialReason || ``,
      email: ThisUser?.email || ``,
      phone: ThisUser?.phone || ``,
      address1: ThisUser?.address1 || ``,
      address2: ThisUser?.address2 || ``,
      zipCode: ThisUser?.zipCode || ``,
      city: ThisUser?.city || ``,
    },
    validationSchema: formikSchema,
    onSubmit: async (formValues, { resetForm }) => {
      await mutations.update.mutateAsync({
        id: props.rowInfos.row._id,
        updates: formValues,
      });
      handleAddUserPopupClose();
      resetForm();
    },
  });
  const handleAddUserPopupClose = () => {
    formik.resetForm();
    setOpenEditUserPopup(false);
  };

  return (
    //header de user management
    <Box>
      <GridActionsCellItem
        onClick={() => setOpenEditUserPopup(true)}
        icon={<EditIcon />}
        label='Edit'
      />
      <Dialog open={OpenEditUserPopup}>
        <Box component='form' onSubmit={formik.handleSubmit}>
          <DialogTitle sx={{ backgroundColor: "#f9f9f9" }}>
            Modifier un client
          </DialogTitle>
          <DialogContent
            sx={{
              width: `560px`,
              maxWidth: `100%`,
              padding: `20px 20px 20px 20px`,
              mt: 2,
              "& .MuiFormLabel-root:not(.Mui-focused, .Mui-error)": {
                color: "grey !important",
              },
            }}
          >
            <TextField
              margin='dense'
              fullWidth
              id='socialReason'
              label='Raison sociale'
              name='socialReason'
              autoComplete='socialReason'
              value={formik.values.socialReason}
              onChange={formik.handleChange}
              error={
                formik.touched.socialReason &&
                Boolean(formik.errors.socialReason)
              }
              helperText={formik.touched.socialReason && formik.errors.name}
            />
            <TextField
              margin='dense'
              fullWidth
              id='phone'
              label='Téléphone'
              name='phone'
              autoComplete='phone'
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
            />
            <TextField
              margin='dense'
              fullWidth
              id='email'
              label='Email'
              name='email'
              autoComplete='email'
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <TextField
              margin='dense'
              fullWidth
              id='address1'
              label='Adresse 1'
              name='address1'
              autoComplete='address1'
              value={formik.values.address1}
              onChange={formik.handleChange}
              error={formik.touched.address1 && Boolean(formik.errors.address1)}
              helperText={
                formik.touched.address1 && formik.errors.address1?.toString()
              }
            />
            <TextField
              margin='dense'
              fullWidth
              id='address2'
              label='Adresse 1'
              name='address2'
              autoComplete='address2'
              value={formik.values.address2}
              onChange={formik.handleChange}
              error={formik.touched.address2 && Boolean(formik.errors.address2)}
              helperText={
                formik.touched.address2 && formik.errors.address2?.toString()
              }
            />
            <TextField
              margin='dense'
              fullWidth
              id='zipCode'
              label='Code postal'
              name='zipCode'
              autoComplete='zipCode'
              value={formik.values.zipCode}
              onChange={formik.handleChange}
              error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
              helperText={
                formik.touched.zipCode && formik.errors.zipCode?.toString()
              }
            />
            <TextField
              margin='dense'
              fullWidth
              id='city'
              label='Ville'
              name='city'
              autoComplete='city'
              value={formik.values.city}
              onChange={formik.handleChange}
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={formik.touched.city && formik.errors.city?.toString()}
            />
          </DialogContent>
          <DialogActions
            sx={{
              padding: `10px 20px`,
              backgroundColor: "#f9f9f9",
            }}
          >
            <Button
              sx={{
                color: `text.disabled`,
              }}
              onClick={handleAddUserPopupClose}
            >
              Annuler
            </Button>
            <Button
              type='submit'
              sx={{
                color: `#fff`,
              }}
              variant='contained'
              disableElevation
              onClick={() => null}
              autoFocus
            >
              Enregistrer
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
}

export default ListClient_Edit;
