import dayjs from "dayjs";

interface Props {
  responsable: string;
  date: dayjs.Dayjs;
  indice: number;
  nature: string;
}

const TableauConclusion: React.FC<Props> = ({
  responsable,
  date,
  indice,
  nature,
}) => {
  return (
    <table className='interventions-table'>
      <thead>
        <tr>
          <th className='column-date'>Date</th>
          <th className='column-intervenant'>Responsable</th>
          <th className='column-num-carte'>Nature de la modification</th>
          <th className='column-lieu'>Indice</th>
          <th className='column-sign'>Signature</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className='cell-date'>{dayjs(date).format("DD/MM/YYYY")}</td>
          <td className='cell-intervenant'>{responsable}</td>
          <td className='cell-num-carte'>{nature}</td>
          <td className='cell-lieu'>{indice}</td>
          <td className='cell-sign'>{responsable?.split(" ")}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default TableauConclusion;
