import React from "react";

import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";

function AppareilsProduct_Add({
  API_USER,
  categoryList,
  data,
  mutations,
}: any) {
  //-------- Add User Popup ---------
  const [OpenAddUserPopup, setOpenAddUserPopup] =
    React.useState<boolean>(false);

  //formik
  const formikSchema = yup.object({
    name: yup
      .string()
      .min(2, `Titre trop court !`)
      .max(50, `Titre trop long !`)
      .required(`Veuillez renseigner un nom`),
    brand: yup
      .string()
      .max(50, `Nom de marque trop long !`)
      .required(`Veuillez renseigner une marque`),
    type: yup
      .string()
      .max(50, `Nom de type trop long !`)
      .required(`Veuillez renseigner un type`),
  });

  const formik = useFormik({
    initialValues: {
      name: ``,
      brand: ``,
      type: ``,
    },
    validationSchema: formikSchema,
    onSubmit: async (formValues, { resetForm }) => {
      await mutations.create.mutateAsync({
        name: formValues.name,
        brand: formValues.brand,
        type: formValues.type,
      });
      handleAddUserPopupClose();
      resetForm();
    },
  });

  const handleAddUserPopupClose = () => {
    formik.resetForm();
    setOpenAddUserPopup(false);
  };

  return (
    //header de user management
    <Stack direction='row' alignItems='center' spacing={5}>
      <Button
        onClick={() => {
          setOpenAddUserPopup(true);
        }}
        sx={{
          color: "#fff",
          height: `38px`,
        }}
        variant='contained'
      >
        <AddIcon />
        <Typography
          sx={{
            color: "#fff",
            fontSize: { xs: `0`, md: `14px` },
          }}
          variant='body2'
        >
          Ajouter un appareil
        </Typography>
      </Button>
      <Dialog open={OpenAddUserPopup}>
        <Box component='form' onSubmit={formik.handleSubmit}>
          <DialogTitle sx={{ backgroundColor: "#f9f9f9" }}>
            Ajouter un appareil
          </DialogTitle>
          <DialogContent
            sx={{
              width: `560px`,
              maxWidth: `100%`,
              padding: `20px 20px 20px 20px`,
              mt: 2,
              "& .MuiFormLabel-root:not(.Mui-focused, .Mui-error)": {
                color: "grey !important",
              },
            }}
          >

              <TextField
                margin='dense'
                fullWidth
                id='name'
                label='Nom'
                name='name'
                autoComplete='name'
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              <TextField
                margin='dense'
                fullWidth
                id='name'
                label='Marque'
                name='brand'
                autoComplete='Marque'
                value={formik.values.brand}
                onChange={formik.handleChange}
                error={formik.touched.brand && Boolean(formik.errors.brand)}
                helperText={formik.touched.brand && formik.errors.brand}
              />
              <TextField
                margin='dense'
                fullWidth
                id='type'
                label='Type'
                name='type'
                autoComplete="type d'appareil"
                value={formik.values.type}
                onChange={formik.handleChange}
                error={formik.touched.type && Boolean(formik.errors.type)}
                helperText={formik.touched.type && formik.errors.type}
              />
          </DialogContent>
          <DialogActions
            sx={{
              padding: `10px 20px`,
              backgroundColor: "#f9f9f9",
            }}
          >
            <Button
              sx={{
                color: `text.disabled`,
              }}
              onClick={handleAddUserPopupClose}
            >
              Annuler
            </Button>
            <Button
              type='submit'
              sx={{
                color: `#fff`,
              }}
              variant='contained'
              disableElevation
              onClick={() => null}
              autoFocus
            >
              Créer
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Stack>
  );
}

export default AppareilsProduct_Add;
