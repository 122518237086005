import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { ADMIN_SETTINGS } from "../api/SETTINGS";
import { ADMIN } from "../api/ADMIN";
import jwtDecode from "jwt-decode";
import { USERS } from "../api/USER";
import { ElementIntervention } from "../components/admin/Intervention/ElementIntervention";
export const Intervention = () => {
  const [usedDevices, setUsedDevices] = useState([]);
  const [isLoadingDevices, setIsLoadingDevices] = useState(true);

  const token = localStorage.getItem("token");
  const role = token && jwtDecode<any>(token).user?.role;
  let API: any = ADMIN;
  let API_SETTINGS: any = ADMIN_SETTINGS;
  if (role === "USER") {
    API = USERS;
    API_SETTINGS = USERS;
  }
  const { report, concernedCustomer, currentAgent } = useLocation().state;
  const interventionState = useLocation().state.intervention;
  const getIntervention: any = async () => {
    const response = await API.GET_REPORT(report?._id);
    const reportData = response.data;
    const intervention = reportData.interventions.find(
      (inter: any) => inter._id === interventionState._id
    );

    return intervention;
  };
  const { isLoading, data: intervention }: any = useQuery(
    "getIntervention",
    async () => await getIntervention()
  );
  const elements = intervention?.intervention;
  const usedDevicesIds = intervention?.usedAppareils;
  useEffect(() => {
    const fetchUserDevices = async () => {
      let usedDevicesArray: any = [];
      const allDevices = (await API_SETTINGS.GET_APPAREILS_IN_SETTINGS()).data;
      allDevices?.map(
        (device: any) =>
          usedDevicesIds?.includes(device.id) && usedDevicesArray.push(device)
      );
      setUsedDevices(usedDevicesArray);
      setIsLoadingDevices(false);
    };
    fetchUserDevices();
  }, [usedDevicesIds]);
  const queryClient = useQueryClient();
  const mutations = {
    createIntervention: useMutation(
      ({ reportId, place }: any) =>
        API.POST_INTERVENTION_IN_REPORT(reportId, place),
      {
        onSuccess(data: any, variables) {
          if (data) {
            queryClient.setQueryData("getIntervention", (oldData: any) => {
              return data.data;
            });
          }
        },
      }
    ),
    putControl: useMutation(
      ({
        reportId,
        interventionId,
        elementId,
        controlId,
        controlResult,
      }: any) =>
        API.PUT_CONTROLE_RESULT_IN_REPORT(
          reportId,
          interventionId,
          elementId,
          controlId,
          controlResult
        ),
      {
        onSuccess: (data: any, variables: any) => {
          if (data) {
            queryClient.setQueryData("getIntervention", (oldData: any) => {
              return data.data.interventions.find(
                (inter: any) => inter._id === intervention._id
              );
            });
          }
        },
      }
    ),
    uploadImage: useMutation(
      ({ reportId, imageFile }: any) =>
        API.UPLOAD_OBSERVATION_IMG(reportId, imageFile),
      {
        onSuccess: (data: any, variables: any) => {
          if (data.data) {
            return data.data;
          }
        },
      }
    ),
    deleteImage: useMutation(
      ({ reportId, imageId }: any) =>
        API.DELETE_OBSERVATION_IMG(reportId, imageId),
      {
        onSuccess: (data: any, variables: any) => {},
      }
    ),
    putReport: useMutation(
      ({ reportId, report }: any) => API.PUT_REPORT(reportId, report),
      {
        onSuccess: (data: any, variables: any) => {
          queryClient.setQueryData("getIntervention", (oldData: any) => {
            return data.data.interventions.find(
              (inter: any) => inter._id === intervention._id
            );
          });
        },
      }
    ),
  };

  if (isLoading || isLoadingDevices)
    return <Typography>Chargement...</Typography>;
  return (
    <Box className='wrapper card' sx={{ margin: "20px auto", padding: "20px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant='h2'>
          Intervenant : {currentAgent.family_name + " " + currentAgent.name}
        </Typography>
        <Typography variant='h2'>
          Etat : {intervention?.finished === "true" ? "Fini" : "En cours"}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          margin: "20px 0",
        }}
      >
        {usedDevices.length !== 0 && (
          <>
            <Typography variant='h3'>
              Appareils utilisés lors de l'intervention :
            </Typography>
            <Box>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Nom</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Marque</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {usedDevices?.map((item: any) => (
                      <TableRow key={item.id}>
                        <TableCell sx={{ width: "200px" }}>
                          {item.name}
                        </TableCell>
                        <TableCell sx={{ width: "200px" }}>
                          {item.type}
                        </TableCell>
                        <TableCell sx={{ width: "200px" }}>
                          {item.brand}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </>
        )}
      </Box>
      {elements?.map((element: any) => (
        <ElementIntervention
          key={element._id}
          element={element}
          mutations={mutations}
          intervention={intervention}
          report={report}
          userType={role === "ADMIN" ? "ADMIN" : "USER"}
        />
      ))}
    </Box>
  );
};
