import { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  FormControl,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  DialogActions,
} from "@mui/material";
import { useFormik, FormikHelpers } from "formik";
import * as yup from "yup";
import { set } from "mongoose";

export interface FormDataType {
  [key: string]: string | number | boolean;
}

export interface FormFieldType {
  label: string;
  name: string;
  type: string;
  formikSchema: yup.Schema<any, any, any, any>;
  initialValue?: string | number | boolean;
  options?: string[];
  rows?: number;
}

export const yuped = yup;

interface FormGeneratorProps {
  SubmitFunction: (values: FormDataType) => Promise<void>;
  formFields: FormFieldType[];
  textButton: string;
  dialog?: {
    title: string;
    button: React.ReactElement<typeof Button>;
  };
}

export const FormGenerator = ({
  SubmitFunction,
  formFields,
  textButton,
  dialog,
}: FormGeneratorProps) => {
  const [loading, setLoading] = useState(false);
  const [OpenDialog, setOpenDialog] = useState(false);

  const handleAddUserPopupClose = () => {
    formik.resetForm();
    setOpenDialog(false);
  };

  const formikSchema = yup
    .object()
    .shape<Record<keyof FormDataType, yup.Schema<any>>>(
      formFields.reduce((schema, field) => {
        schema[field.name] = field.formikSchema;
        return schema;
      }, {} as Record<keyof FormDataType, yup.Schema<any>>)
    );

  const initialValues: FormDataType = formFields.reduce((values, field) => {
    values[field.name] = field.initialValue || "";
    return values;
  }, {} as FormDataType);

  const onSubmit = async (
    values: FormDataType,
    formikHelpers: FormikHelpers<FormDataType>
  ) => {
    setLoading(true);
    await SubmitFunction(values);
    formikHelpers.resetForm();
    setLoading((prevLoading) => !prevLoading);
  };

  const formik = useFormik<FormDataType>({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: formikSchema,
    onSubmit: onSubmit,
  });

  const renderCheckbox = (field: FormFieldType) => (
    <FormControl
      error={Boolean(formik.touched[field.name] && formik.errors[field.name])}
    >
      <FormControlLabel
        control={
          <Checkbox
            checked={Boolean(formik.values[field.name])}
            onChange={(event) => {
              formik.setFieldValue(field.name, event.target.checked);
            }}
            name={field.name}
            sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
          />
        }
        label={field.label}
      />
      {formik.touched[field.name] && Boolean(formik.errors[field.name]) && (
        <FormHelperText sx={{ m: "-8px 0 0 0" }}>
          {formik.errors[field.name]}
        </FormHelperText>
      )}
    </FormControl>
  );

  const renderSelect = (field: FormFieldType) => (
    <FormControl fullWidth>
      <TextField
        id={field.name}
        value={formik.values[field.name]}
        margin='dense'
        label={field.label}
        select
        onChange={(event) => {
          formik.setFieldValue(field.name, event.target.value);
        }}
        error={Boolean(formik.touched[field.name] && formik.errors[field.name])}
        helperText={formik.touched[field.name] && formik.errors[field.name]}
      >
        {field.options?.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  );

  const renderText = (field: FormFieldType) => (
    <TextField
      key={field.name}
      margin='dense'
      type={field.type}
      fullWidth
      id={field.name}
      label={field.label}
      name={field.name}
      multiline={field.rows ? true : false}
      rows={field.rows}
      autoComplete={field.name}
      value={formik.values[field.name]}
      onChange={formik.handleChange}
      error={Boolean(formik.touched[field.name] && formik.errors[field.name])}
      helperText={formik.touched[field.name] && formik.errors[field.name]}
    />
  );

  const renderFormFields = () => {
    return formFields?.map((field) => {
      switch (field.type) {
        case "checkbox":
          return renderCheckbox(field);
        case "select":
          return renderSelect(field);
        default:
          return renderText(field);
      }
    });
  };

  if (dialog) {
    return (
      <Box>
        <Box onClick={() => setOpenDialog(true)}>{dialog.button}</Box>
        <Dialog open={OpenDialog} onClose={() => setOpenDialog(false)}>
          <Box component='form' onSubmit={formik.handleSubmit}>
            <DialogTitle sx={{ backgroundColor: "#f9f9f9" }}>
              {dialog.title}
            </DialogTitle>
            <DialogContent
              sx={{
                width: `560px`,
                maxWidth: `100%`,
                padding: `20px`,
                mt: 2,
                "& .MuiFormLabel-root:not(.Mui-focused, .Mui-error)": {
                  color: "grey !important",
                },
              }}
            >
              <Stack>{renderFormFields()}</Stack>
            </DialogContent>
            <DialogActions
              sx={{
                padding: `10px 20px`,
                backgroundColor: "#f9f9f9",
              }}
            >
              <Button
                sx={{
                  color: `text.disabled`,
                }}
                onClick={handleAddUserPopupClose}
              >
                Annuler
              </Button>
              <Button
                type='submit'
                sx={{
                  color: `#fff`,
                }}
                variant='contained'
                disableElevation
                onClick={() => null}
                autoFocus
              >
                {loading ? (
                  <CircularProgress sx={{ color: "white" }} size='34px' />
                ) : (
                  textButton || "Envoyer"
                )}
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      </Box>
    );
  } else {
    return (
      <Box
        component='form'
        onSubmit={formik.handleSubmit}
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant='h3' sx={{ mb: "20px" }}>
          Informations du rapport :
        </Typography>
        {renderFormFields()}
        <Button
          type='submit'
          fullWidth
          sx={{
            color: `#fff`,
            mt: 1,
          }}
          variant='contained'
          disableElevation
          autoFocus
        >
          {loading ? (
            <CircularProgress sx={{ color: "white" }} size='34px' />
          ) : (
            textButton || "Envoyer"
          )}
        </Button>
      </Box>
    );
  }
};
