import { Skeleton } from "@mui/material";

export const DashBoardSkeleton = (props: any) => {
  return (
    <Skeleton
      className='wrapper'
      variant='rectangular'
      animation='wave'
      height={400}
      sx={{
        width: "calc(100% - 40px)",
        margin: "0 20px",
        borderRadius: "20px",
      }}
    />
  );
};
