import {
    Box,
    Grid,
    Typography,
    TextField,
    Button,
    Skeleton,
  } from "@mui/material";
  import { useFormik } from "formik";
  import * as yup from "yup";
  import { useQuery, useMutation, useQueryClient } from "react-query";
  import { USERS } from "../../../api/USER";
  import {DashBoardSkeleton} from "../../ui/Skeleton";

  const Settings = ({...props}: any) => {
    const fetchUser = async () => {
      const response = await USERS.GET_USER();
      return response.data;
    };

    const IsFormDataSameAsUserData = (formData: any, userData: any) => {
      return (
        formData.name === userData?.name &&
        formData.family_name === userData?.family_name &&
        formData.phone === userData?.phone
      );
    }

    const queryClient = useQueryClient();
    const { isLoading, data } = useQuery("User", fetchUser);

    const mutations = {
      update: useMutation(
        ({ updates }: any) => USERS.PUT_USER(updates),
        {
          onSuccess: (data, variables) => {
            queryClient.setQueryData("User", (oldData: any) =>
            variables.updates
            );
          },
        }
      ),
    };

    const phoneReg =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

    const formikSchema = yup.object({
      name: yup
        .string()
        .matches(/^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ\s\-\/.]+$/, `Prenom invalide`)
        .min(2, `Prenom trop court !`)
        .max(50, `Prenom trop long !`)
        .required(`Veuillez renseigner votre prenom`),
      family_name: yup
        .string()
        .matches(/^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ\s\-\/.]+$/, `Nom invalide`)
        .min(2, `Nom trop court !`)
        .max(50, `Nom trop long !`)
        .required(`Veuillez renseigner votre nom`),
      phone: yup
        .string()
        .matches(phoneReg, `Numéro invalide`)
        .required(`Veuillez renseigner votre numéro de téléphone`),
    });

    const formik = useFormik({
      enableReinitialize : true,
      initialValues: {
        name: data?.name || ``,
        family_name: data?.family_name || ``,
        phone: data?.phone || ``,
      },
      validationSchema: formikSchema,
      onSubmit: async (formValues, { resetForm }) => {
        await mutations.update.mutateAsync({
          updates: formValues
        })
        resetForm();  
      },
    });

    if (isLoading){ return <DashBoardSkeleton/>};

    return (
      <Box className='wrapper card' sx={{p: '20px'}}>
        <Box component="form" onSubmit={formik.handleSubmit}>
          <Typography variant='h3' sx={{mb: '20px'}}>
            Informations personnelles :
          </Typography>
        <TextField
              margin="dense"
              fullWidth
              id="name"
              label="Prenom"
              name="name"
              autoComplete="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={
                  formik.touched.name && Boolean(formik.errors.name)
              }
              helperText={
                  (formik.touched.name && formik.errors.name?.toString()) 
              }
              />
              <TextField
              margin="dense"
              fullWidth
              id="family_name"
              label="Nom"
              name="family_name"
              autoComplete="family_name"
              value={formik.values.family_name}
              onChange={formik.handleChange}
              error={
                  formik.touched.family_name && Boolean(formik.errors.family_name)
              }
              helperText={
                  formik.touched.family_name && formik.errors.family_name?.toString()
              }
              />          
              <TextField
                margin="dense"
                fullWidth
                id="phone"
                label="Téléphone"
                name="phone"
                autoComplete="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone?.toString()}
              />
            <Button
             disabled={IsFormDataSameAsUserData(formik.values, data)}
              type="submit"
              fullWidth
              sx={{
                color: `#fff`,
                mt: 1,
              }}
              variant="contained"
              disableElevation
              onClick={() => null}
              autoFocus
            >
              Enregistrer
            </Button>
      </Box>
      </Box>
    );
  };
  
  export default Settings;