import axios from 'axios';

export const ADMIN_CLIENT = {
GET_CLIENTS: async function (filter?: any) {
    return (axios({
        method: 'get',
        url: `${process.env.REACT_APP_SERVER_URL}/api/admin/clients`,
        data: {
            filter: filter,
        },
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
    }).then(function (res:any) {
        return res.data;
    })).catch(function (error:any) {
        console.log(error);
        return null;
    });
},

GET_CLIENT: async function (client_id: string) {
    return (axios({
        method: 'get',
        url: `${process.env.REACT_APP_SERVER_URL}/api/admin/client`,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        params : {
            client_id: client_id
        }
    }).then(function (res:any) {
        return res.data;
    })).catch(function (error:any) {
        console.log(error);
        return null;
    });
},

POST_CLIENT: async function (client: any) {
    return (axios({
        method: 'post',
        url: `${process.env.REACT_APP_SERVER_URL}/api/admin/client`,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        data: {client: client}
    }).then(function (res:any) {
        return res.data;
    })).catch(function (error:any) {
        console.log(error);
        return null;
    });
},

PUT_CLIENT: async function (id: string, client: any) {
    return (axios({
        method: 'put',
        url: `${process.env.REACT_APP_SERVER_URL}/api/admin/client/`,
        data: {
            client_id: id,
            client: JSON.stringify(client),
        },
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
    }).then(function (res:any) {
        return res.data;
    })).catch(function (error:any) {
        console.log(error);
        return null;
    });
},

DELETE_CLIENT: async function (id: string) {
    return (axios({
        method: 'delete',
        url: `${process.env.REACT_APP_SERVER_URL}/api/admin/client/`,
        data: {
            client_id: id,
        },
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
    }).then(function (res:any) {
        return res.data;
    })).catch(function (error:any) {
        console.log(error);
        return null;
    });
},
}