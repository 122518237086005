import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, TextField, Button } from "@mui/material";
import ProductsList from "./ProductsList";
import AppareilsList from "./AppareilsList";
import { ADMIN_SETTINGS } from "../../../api/SETTINGS";
import { useMutation, useQuery, useQueryClient } from "react-query";

const Settings = ({ ...props }: any) => {
  const fetchSettings = async () => {
    const res = await ADMIN_SETTINGS.GET_SETTINGS();
    return res.data;
  };

  const queryClient = useQueryClient();
  const { isLoading, data: settings } = useQuery("getSettings", fetchSettings);

  const mutations = {
    updateDocRef: useMutation(
      ({ settings }: any) => ADMIN_SETTINGS.PUT_SETTINGS(settings),
      {
        onSuccess: (data, variables) => {
          queryClient.setQueryData("getSettings", (oldData: any) => {
            return data.data;
          });
        },
      }
    ),
  };

  return (
    <Box className='wrapper' sx={{ pb: "30px" }}>
      <Grid container spacing={"20px"}>
        <Grid item xs={12} lg={6}>
          <ProductsList />
        </Grid>
        <Grid item xs={12} lg={6}>
          <AppareilsList />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Settings;
