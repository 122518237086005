import React from "react";

import AddIcon from "@mui/icons-material/Add";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";

function UserManagement_Add({ API_USER, categoryList, data, mutations }: any) {
  //-------- Add User Popup ---------
  const [OpenAddUserPopup, setOpenAddUserPopup] =
    React.useState<boolean>(false);

  const [inputValue, setInputValue] = React.useState("");

  //formik
  const formikSchema = yup.object({
    title: yup
      .string()
      .min(2, `Titre trop court !`)
      .max(50, `Titre trop long !`)
      .required(`Veuillez renseigner un Titre`),
    machine: yup.string().required(`Veuillez renseigner une machine`),
    description: yup
      .string()
      .min(2, `Description trop courte !`)
      .max(5000, `Description trop longue !`),
    category: yup.string(),
    refereDocument: yup.string().min(2, "Document de référence trop court !"),
  });

  const formik = useFormik({
    initialValues: {
      title: ``,
      machine: ``,
      description: ``,
      category: ``,
      refereDocument: ``,
    },
    validationSchema: formikSchema,
    onSubmit: async (formValues, { resetForm }) => {
      let modelValues = formValues;
      if (!modelValues.category) {
        modelValues.category = inputValue;
      }
      await mutations.create.mutateAsync(modelValues);
      handleAddUserPopupClose();
      resetForm();
    },
  });

  const handleAddUserPopupClose = () => {
    formik.resetForm();
    setOpenAddUserPopup(false);
  };

  return (
    //header de user management
    <Stack direction='row' alignItems='center' spacing={5}>
      <Button
        onClick={() => {
          setOpenAddUserPopup(true);
        }}
        sx={{
          color: "#fff",
          height: `38px`,
        }}
        variant='contained'
      >
        <AddIcon />
        <Typography
          sx={{
            color: "#fff",
            fontSize: { xs: `0`, md: `14px` },
          }}
          variant='body2'
        >
          Ajouter un modèle
        </Typography>
      </Button>
      <Dialog
        open={OpenAddUserPopup}
        onClose={() => setOpenAddUserPopup(false)}
      >
        <Box component='form' onSubmit={formik.handleSubmit}>
          <DialogTitle sx={{ backgroundColor: "#f9f9f9" }}>
            Ajouter un modèle
          </DialogTitle>
          <DialogContent
            sx={{
              width: `560px`,
              maxWidth: `100%`,
              padding: `20px 20px 20px 20px`,
              mt: 2,
              "& .MuiFormLabel-root:not(.Mui-focused, .Mui-error)": {
                color: "grey !important",
              },
            }}
          >
            <Stack>
              <TextField
                margin='dense'
                fullWidth
                id='title'
                label='Titre'
                name='title'
                autoComplete='title'
                value={formik.values.title}
                onChange={formik.handleChange}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
              />
              <TextField
                margin='dense'
                fullWidth
                id='machine'
                label='Machine'
                name='machine'
                autoComplete='machine'
                value={formik.values.machine}
                onChange={formik.handleChange}
                error={formik.touched.machine && Boolean(formik.errors.machine)}
                helperText={formik.touched.machine && formik.errors.machine}
              />
              <TextField
                margin='dense'
                fullWidth
                id='description'
                label='Description'
                name='description'
                autoComplete='description'
                value={formik.values.description}
                onChange={formik.handleChange}
                multiline
                rows={4}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
              />
              <TextField
                margin='dense'
                fullWidth
                id='refereDocument'
                label='Document de référence'
                name='refereDocument'
                autoComplete='refereDocument'
                value={formik.values.refereDocument}
                onChange={formik.handleChange}
                error={
                  formik.touched.refereDocument &&
                  Boolean(formik.errors.refereDocument)
                }
                helperText={
                  formik.touched.refereDocument && formik.errors.refereDocument
                }
              />
              {categoryList && (
                <Autocomplete
                  freeSolo
                  value={formik.values.category}
                  onChange={formik.handleChange}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  id='controllable-states-demo'
                  options={categoryList}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        maxLength: process.env.REACT_APP_ENTRY_MAX_LENGTH,
                      }}
                      margin='dense'
                      fullWidth
                      id='category'
                      label='Catégorie'
                      name='category'
                      autoComplete='category'
                      value={formik.values.category}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.category &&
                        Boolean(formik.errors.category)
                      }
                      helperText={
                        formik.touched.category && formik.errors.category
                      }
                    />
                  )}
                />
              )}
            </Stack>
          </DialogContent>
          <DialogActions
            sx={{
              padding: `10px 20px`,
              backgroundColor: "#f9f9f9",
            }}
          >
            <Button
              sx={{
                color: `text.disabled`,
              }}
              onClick={handleAddUserPopupClose}
            >
              Annuler
            </Button>
            <Button
              type='submit'
              sx={{
                color: `#fff`,
              }}
              variant='contained'
              disableElevation
              onClick={() => null}
              autoFocus
            >
              Créer
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Stack>
  );
}

export default UserManagement_Add;
