import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useParams, Link } from "react-router-dom";
import { ADMIN } from "../api/ADMIN";
import { useQuery } from "react-query";
import { ADMIN_USER } from "../api/ADMIN_USER";
import { ADMIN_CLIENT } from "../api/ADMIN_CLIENT";
import { Stack } from "@mui/system";
import PreviewIcon from "@mui/icons-material/Preview";
import { DataGridPro, GridActionsCellItem } from "@mui/x-data-grid-pro";
import jwtDecode from "jwt-decode";
import { USERS } from "../api/USER";
import { USER_CLIENT } from "../api/USER_CLIENT";
export const Report = () => {
  const [interventions, setInterventions] = useState<any>([]);
  const [elementsIntervention, setElementsIntervention] = React.useState({});
  const [isLoadingElements, setIsLoadingElements] = useState(true);
  const [agents, setAgents] = useState([]);
  const [clients, setClients] = useState([]);
  const reportId = useParams()?.report_id || " ";
  const token = localStorage.getItem("token");
  const role = token && jwtDecode<any>(token).user?.role;

  let API: any = ADMIN;
  let API_CLIENT: any = ADMIN_CLIENT;
  let API_USER: any = ADMIN_USER;
  if (role === "USER") {
    API = USERS;
    API_CLIENT = USER_CLIENT;
    API_USER = USERS;
  }

  const fetchAgents = async () => {
    const response = await API_USER.GET_USERS({});
    return response.data;
  };

  const fetchClients = async () => {
    const response = await API_CLIENT.GET_CLIENTS({});
    return response.data;
  };

  const fetchReportById = async () => {
    const response = await API.GET_REPORT(reportId);
    setInterventions(response.data?.interventions);
    const interventions = response.data?.interventions;
    // Get last interventions
    setElementsIntervention(interventions[interventions.length - 1]);
    setAgents(await fetchAgents());
    setClients(await fetchClients());
    setIsLoadingElements(false);
    return response.data;
  };
  const { isLoading, data: report } = useQuery(
    "getReportById",
    fetchReportById
  );
  const concernedCustomer: any = clients.find(
    (client: any) => client?._id === report?.client_id
  );

  const getCurrentAgent = (agentId: string) => {
    const currentAgent: any = agents.find(
      (agent: any) => agent.user_id === agentId
    );

    return currentAgent;
  };

  const columns = [
    {
      field: `agent`,
      headerName: `Agent`,
      width: 205,
      minWidth: 130,
      flex: 0.3,
      renderCell: (cellValues: any) => {
        const agentId = cellValues.row.user_id;
        const currentAgent: any = agents.find(
          (agent: any) => agent.user_id === agentId
        );
        return (
          <Typography
            sx={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {currentAgent?.family_name + " " + currentAgent?.name}
          </Typography>
        );
      },
    },
    {
      field: `place`,
      headerName: `Localisation`,
      width: 275,
      minWidth: 140,
      flex: 0.5,
      renderCell: (cellValues: any) => {
        return (
          <Typography
            sx={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {cellValues.formattedValue}
          </Typography>
        );
      },
    },
    {
      field: `finished`,
      headerName: `Traitement`,
      width: 150,
      minWidth: 80,
      flex: 0.2,
      renderCell: (cellValues: any) => {
        const getFinished = cellValues.row?.finished;
        return (
          <Typography>
            {getFinished === "true" ? "Fini" : "En cours"}
          </Typography>
        );
      },
    },
    {
      field: `actions`,
      type: `actions`,
      headerName: `Actions`,
      width: 80,
      resizable: false,
      getActions: (e: { row?: any }) => {
        const agentId = e.row.user_id;
        const currentAgent: any = agents.find(
          (agent: any) => agent.user_id === agentId
        );
        return [
          <GridActionsCellItem
            icon={
              <Link
                to={`/intervention/${report._id}/${e.row._id}`}
                state={{
                  report: report,
                  intervention: e.row,
                  concernedCustomer: concernedCustomer,
                  currentAgent: currentAgent,
                }}
              >
                <PreviewIcon
                  sx={{
                    color: "white",
                    backgroundColor: "orange",
                    borderRadius: "50%",
                    padding: "2px",
                    width: "30px",
                    height: "auto",
                  }}
                />
              </Link>
            }
            label='Delete'
            key='grid-actions-cell-item-1'
          />,
        ];
      },
    },
  ];
  if (isLoading || isLoadingElements) return <div>loading...</div>;
  return (
    <>
      <Box
        sx={{
          margin: "0 20px",
        }}
      >
        <Box
          className='card'
          sx={{
            maxWidth: "1150px",
            width: 1,
            margin: "30px auto",
            paddingBottom: "20px",
          }}
        >
          <Box
            sx={{
              padding: "40px 0",
              textAlign: "center",
            }}
          >
            <Typography variant='h2'>Rapport : {report?.title}</Typography>
          </Box>
          {concernedCustomer && (
            <Typography
              variant='h3'
              sx={{ textAlign: "center", margin: "20px" }}
            >
              Client concerné : {concernedCustomer.name}{" "}
            </Typography>
          )}
          <DataGridPro
            sx={{
              margin: "20px",
              '.MuiDataGrid-booleanCell[data-value="true"] ': {
                color: `#8D919A`,
              },
              borderRadius: `20px`,
              overflow: "hidden",
            }}
            columns={columns || []}
            getRowId={(row) => row._id}
            components={{
              NoRowsOverlay: () => (
                <Stack
                  height='100%'
                  alignItems='center'
                  justifyContent='center'
                >
                  Aucune ligne à afficher
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack
                  height='100%'
                  alignItems='center'
                  justifyContent='center'
                >
                  Aucun résultat
                </Stack>
              ),
            }}
            rows={
              interventions.map((intervention: any) => ({
                ...intervention,
                currentAgent: getCurrentAgent(intervention.user_id),
              })) || []
            }
            initialState={{ pinnedColumns: { right: [`actions`] } }}
          />
        </Box>
      </Box>
    </>
  );
};
