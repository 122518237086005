import React, { useState } from "react";
import { getSurfacesStates } from "../utils/getSurfacesStates";
import { getUsedProducts } from "../utils/getUsedProducts";
import { getUsedDevices } from "../utils/getUsedDevices";
import { ADMIN_SETTINGS } from "../../../../api/SETTINGS";
import { useQuery } from "react-query";
import { ItemComponent } from "./ExportRapportComposants";
import dayjs from "dayjs";
import { getLightnings } from "../utils/getLightnings";

export const ExamsConditions = ({ ReportData, id }: any) => {
  const condition_div = document.getElementById("original");
  const condition_div_after = document.getElementById("aftertest");
  if (
    condition_div?.clientHeight &&
    condition_div_after &&
    condition_div?.clientHeight > 610
  ) {
    condition_div.style.display = "none";
  } else if (
    condition_div_after &&
    condition_div &&
    condition_div.style.display !== "none"
  ) {
    condition_div_after.style.display = "none";
    condition_div.style.display = "block";
  }

  const surfacesConditions = getSurfacesStates(ReportData);
  const usedProducts = getUsedProducts(ReportData);
  const hasBeenCleaned = ReportData.interventions?.some(
    (intervention: any) => intervention.hasClean === "true"
  );
  const uniqueLigthings = getLightnings(ReportData);
  const fetchProducts = async () => {
    let filteredProducts: any[] = [];
    const response = await ADMIN_SETTINGS.GET_PRODUCTS_IN_SETTINGS();
    const products = response.data;
    products.forEach((product: any) => {
      const isProductUsed = usedProducts.some(
        (usedProduct: any) =>
          usedProduct.contraste?._id === product.id ||
          usedProduct.revelateur?._id === product.id
      );
      if (isProductUsed) {
        const isProductAlreadyFiltered = filteredProducts.some(
          (filteredProduct: any) => filteredProduct.id === product.id
        );
        if (!isProductAlreadyFiltered) {
          filteredProducts.push(product);
        }
      }
    });
    return filteredProducts;
  };

  const { data: filteredProducts } = useQuery(
    "getFilteredProduct",
    fetchProducts
  );
  return (
    <div id={id}>
      <div className='page_section_title'>Conditions d'examens</div>
      <div className='content_item'>
        <p
          className='content_item_title'
          style={{ fontWeight: 600, fontSize: "19px", textDecoration: "none" }}
        >
          Etat de surface :
        </p>
        <div className='content_item_inline'>
          {surfacesConditions?.map((surface: any, index: number) => {
            return index === surfacesConditions.length - 1 ? (
              <p key={index}>
                {surface?.slice(0, 1).toUpperCase() +
                  surface?.slice(1, surface.length)}
              </p>
            ) : (
              <p key={index}>
                {surface?.slice(0, 1).toUpperCase() +
                  surface?.slice(1, surface.length)}
                /{" "}
              </p>
            );
          })}
        </div>
      </div>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <h3 style={{ fontSize: "19px" }}>Nettoyage :</h3>
          <p>{hasBeenCleaned ? "Oui" : "Non"}</p>
        </div>
      </div>
      <div>
        {usedProducts?.map((product: any, index: number) => {
          const contrastProduct = product.contraste;
          const developerProduct = product.revelateur;
          const detailsDeveloperProduct = filteredProducts?.find(
            (product: any) => product?.id === developerProduct._id
          );
          const detailsContrastProduct = filteredProducts?.find(
            (product: any) => product?.id === contrastProduct?._id
          );

          return (
            <div className='content_product' key={index}>
              {index !== 0 && index < usedProducts.length && (
                <div className='content_item_title'>Et</div>
              )}
              {detailsContrastProduct && (
                <div>
                  <ItemComponent
                    title="Application d'un produit de contraste"
                    content='Oui'
                  />

                  <div className='content_item_inline'>
                    <p className='content_item_title'>Produit :</p>
                    <div className='content_item_inline'>
                      <p className='content_item_padding content_item_padding_left_small'>
                        {detailsContrastProduct?.name}
                      </p>
                      <p className='content_item_padding'>
                        Lot {contrastProduct?.productModel?.batchNumber}
                      </p>
                      <p className='content_item_padding'>
                        Exp.{" "}
                        {dayjs(
                          contrastProduct?.productModel?.expirationDate
                        ).format("DD/MM/YYYY")}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              <div className={detailsContrastProduct ? "content_product" : ""}>
                <ItemComponent
                  title='Révélateur'
                  content='Liqueur machin truc bidule'
                />
                <div className='content_item_inline'>
                  <p className='content_item_title'>Produit :</p>
                  <div className='content_item_inline'>
                    <p className='content_item_padding content_item_padding_left_small'>
                      {detailsDeveloperProduct?.name}
                    </p>
                    <p className='content_item_padding'>
                      Lot {developerProduct?.productModel?.batchNumber}
                    </p>
                    <p className='content_item_padding'>
                      Exp.{" "}
                      {dayjs(
                        developerProduct?.productModel?.expirationDate
                      ).format("DD/MM/YYYY")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        <div className='content_product'>
          <div>
            <div className='content_item_inline '>
              <p
                className='content_item_title content_item_min_width'
                style={{
                  fontWeight: 600,
                  textDecoration: "none",
                  fontSize: "19px",
                }}
              >
                Temps de magnétisation :
              </p>
              <div>
                <p>5 secondes</p>
              </div>
            </div>
            <div className='content_item_inline '>
              <p
                className='content_item_title content_item_min_width'
                style={{
                  fontWeight: 600,
                  textDecoration: "none",
                  fontSize: "19px",
                }}
              >
                Contrôle de la sensibilité :
              </p>
              <div>
                <p>Croix de Berthold</p>
              </div>
            </div>{" "}
            <div className='content_item_inline '>
              <p
                className='content_item_title content_item_min_width'
                style={{
                  fontWeight: 600,
                  textDecoration: "none",
                  fontSize: "19px",
                }}
              >
                Mesure du champ tangentiel :
              </p>
              <div>
                <p>Supérieure à 2400 A/M</p>
              </div>
            </div>
          </div>
        </div>
        <div className='content_product'>
          <div>
            <div className='content_item_inline'>
              <p
                className='content_item_title content_item_padding content_item_min_width_small'
                style={{
                  fontWeight: 600,
                  textDecoration: "none",
                  fontSize: "19px",
                }}
              >
                Eclairage :
              </p>
              <div className='content_item_inline '>
                {uniqueLigthings?.map((uniqueLightning: any, index: number) => {
                  const uniqueLigthningsLength = uniqueLigthings.length - 1;
                  return (
                    <div key={index} style={{ display: "flex" }}>
                      <p className='content_item_padding' key={index}>
                        {uniqueLightning.slice(0, 1).toUpperCase() +
                          uniqueLightning.slice(1, uniqueLightning.length)}{" "}
                      </p>
                      {index < uniqueLigthningsLength && (
                        <p className='content_item_padding content_item_bold'>
                          Et
                        </p>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div>
            <div className='content_item_inline'>
              <p
                className='content_item_title content_item_padding content_item_min_width_small'
                style={{
                  fontWeight: 600,
                  textDecoration: "none",
                  fontSize: "19px",
                }}
              >
                Intensité lumineuse :
              </p>
              <div className='content_item_inline '>
                {(uniqueLigthings.includes("naturel") ||
                  uniqueLigthings.includes("artificiel")) && (
                  <>
                    <p>≥ 500 Lux</p>
                    {uniqueLigthings.includes("UV") && (
                      <>
                        <p className='content_item_padding content_item_bold content_item_padding_left'>
                          Et
                        </p>
                        <p>≥ 1000 μW/cm² & lumière parasite ≤ 20 LUX</p>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
