import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import EditIcon from "@mui/icons-material/Edit";

function AppareilsProduct_Edit({ rowInfos, mutations }: any) {
  //-------- Add User Popup ---------
  const [OpenEditUserPopup, setOpenEditUserPopup] =
    React.useState<boolean>(false);

  //formik
  const formikSchema = yup.object({
    name: yup
      .string()
      .min(2, `Titre trop court !`)
      .max(50, `Titre trop long !`)
      .required(`Veuillez renseigner un nom`),
  });

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: rowInfos?.name || ``,
      brand: rowInfos?.brand || ``,
      type: rowInfos?.type || ``,
    },
    validationSchema: formikSchema,
    onSubmit: async (formValues, { resetForm }) => {
      await mutations.update.mutateAsync({
        appareil_id: rowInfos?.id,
        appareil: {
          name: formValues.name,
          brand: formValues.brand,
          type: formValues.type,
        },
      });
      handleAddUserPopupClose();
      resetForm();
    },
  });
  const handleAddUserPopupClose = () => {
    formik.resetForm();
    setOpenEditUserPopup(false);
  };

  return (
    //header de user management
    <Box>
      <GridActionsCellItem
        onClick={() => setOpenEditUserPopup(true)}
        icon={<EditIcon />}
        label='Edit'
      />
      <Dialog open={OpenEditUserPopup}>
        <Box component='form' onSubmit={formik.handleSubmit}>
          <DialogTitle sx={{ backgroundColor: "#f9f9f9" }}>
            Modifier un produit
          </DialogTitle>
          <DialogContent
            sx={{
              width: `560px`,
              maxWidth: `100%`,
              padding: `20px 20px 20px 20px`,
              mt: 2,
              "& .MuiFormLabel-root:not(.Mui-focused, .Mui-error)": {
                color: "grey !important",
              },
            }}
          >
            <TextField
              margin='dense'
              fullWidth
              id='name'
              label='Nom'
              name='name'
              autoComplete='name'
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <TextField
              margin='dense'
              fullWidth
              id='brand'
              label='Marque'
              name='brand'
              autoComplete='Marque'
              value={formik.values.brand}
              onChange={formik.handleChange}
              error={formik.touched.brand && Boolean(formik.errors.brand)}
              helperText={formik.touched.brand && formik.errors.brand}
            />
            <TextField
              margin='dense'
              fullWidth
              id='type'
              label='Type'
              name='type'
              autoComplete='type'
              value={formik.values.type}
              onChange={formik.handleChange}
              error={formik.touched.type && Boolean(formik.errors.type)}
              helperText={formik.touched.type && formik.errors.type}
            />
          </DialogContent>
          <DialogActions
            sx={{
              padding: `10px 20px`,
              backgroundColor: "#f9f9f9",
            }}
          >
            <Button
              sx={{
                color: `text.disabled`,
              }}
              onClick={handleAddUserPopupClose}
            >
              Annuler
            </Button>
            <Button
              type='submit'
              sx={{
                color: `#fff`,
              }}
              variant='contained'
              disableElevation
              onClick={() => null}
              autoFocus
            >
              Enregistrer
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
}

export default AppareilsProduct_Edit;
