import React, { useState } from "react";
import { Box } from "@mui/material";

import jwt_decode from "jwt-decode";
import { DashBoardSkeleton } from "../components/ui/Skeleton";
import AdminNavigation from "../components/admin/AdminNavigation";
import UserNavigation from "../components/user/UserNavigation";
import UserGrid from "../components/admin/UserGrid/UserGrid";
import ListReport from "../components/admin/ListReport/ListReport";
import ListModel from "../components/admin/ListModel/ListModel";
import ListClient from "../components/admin/ListClient/ListClient";
import AdminSettings from "../components/admin/Settings/Settings";
import UserSettings from "../components/user/Settings/Settings";
import { useParams } from "react-router-dom";
import { Intervention } from "../components/user/Intervention/Intervention";

function DashBoard() {
  const storedToken = localStorage.getItem("token");
  const userType = storedToken && jwt_decode<any>(storedToken)?.user?.role;

  return (
    <Box
      sx={{
        height: "100%",
        padding: "50px 0 0 0",
        flexDirection: "column",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {userType === "USER" ? (
        <UserDashBoard />
      ) : userType === "ADMIN" ? (
        <AdminDashBoard />
      ) : (
        <DashBoardSkeleton />
      )}
    </Box>
  );
}

function UserDashBoard() {
  const { location } = useParams();
  const [userNavigation, setUserNavigation] = useState<String>(
    location || "list_report"
  );
  const ActualStep = () => {
    switch (userNavigation) {
      case "list_report":
        return <ListReport userType={"USER"} />;
      case "settings":
        return <UserSettings />;
      case "intervention":
        return <Intervention />;
      default:
        return <DashBoardSkeleton />;
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        pb: "100px",
        flexDirection: "column",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ActualStep />

      <UserNavigation
        userNavigation={userNavigation}
        setUserNavigation={setUserNavigation}
      />
    </Box>
  );
}

function AdminDashBoard() {
  const { location } = useParams();

  const [adminNavigation, setAdminNavigation] = useState<String>(
    location || "list_report"
  );

  const ActualStep = () => {
    switch (adminNavigation) {
      case "list_report":
        return <ListReport userType={"ADMIN"} />;
      case "user":
        return <UserGrid />;
      case "list_model":
        return <ListModel setAdminNavigation={setAdminNavigation} />;
      case "list_client":
        return <ListClient />;
      case "settings":
        return <AdminSettings />;
      default:
        return <DashBoardSkeleton />;
    }
  };
  return (
    <Box
      sx={{
        width: "100%",
        flexDirection: "column",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <AdminNavigation
        adminNavigation={adminNavigation}
        setAdminNavigation={setAdminNavigation}
      />
      <ActualStep />
    </Box>
  );
}

export default DashBoard;
