import React from "react";
import {
  Box,
  Divider,
  Stack,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  Select,
  MenuItem,
  Link,
} from "@mui/material";
import { ADMIN_USER } from "../../../api/ADMIN_USER";
import { ADMIN } from "../../../api/ADMIN";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  DataGridPro,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarContainer,
} from "@mui/x-data-grid-pro";
import DeleteIcon from "@mui/icons-material/Delete";
import ModelAdd from "./ListModel_Add";
import ModelEdit from "./ListModel_Edit";
import FullSizeLoading from "../../ui/fullSizeLoading";
import ArticleIcon from "@mui/icons-material/Article";
const CustomToolbar: React.FunctionComponent<{
  setFilterButtonEl: React.Dispatch<
    React.SetStateAction<HTMLButtonElement | null>
  >;
  setSearchInputValue: React.Dispatch<React.SetStateAction<string>>;
  searchInputValue: React.Dispatch<
    React.SetStateAction<HTMLButtonElement | null>
  >;
  mutations: any;
  data: any;
  categoryFilter: string;
  categoryList: any;
  setCategoryFilter: React.Dispatch<React.SetStateAction<string>>;
}> = ({
  setFilterButtonEl,
  setSearchInputValue,
  searchInputValue,
  data,
  mutations,
  categoryFilter,
  setCategoryFilter,
  categoryList,
}) => {
  //-------- Grid Header --------
  const SearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(event.target.value);
  };
  const handleChangeCategory = (event: any) => {
    setCategoryFilter(event.target.value as string);
  };
  return (
    <GridToolbarContainer
      sx={{
        padding: `0px`,
      }}
    >
      <Stack
        flex='1'
        sx={{
          backgroundColor: (theme: any) => theme.palette.customGrey.grey1,
          padding: `0px 10px`,
        }}
      >
        <Stack
          sx={{
            height: `60px`,
            "& .MuiFormLabel-root:not(.Mui-focused)": {
              color: "grey !important",
            },
          }}
          direction='row'
          alignItems='center'
          spacing={2}
        >
          <TextField
            size='small'
            value={searchInputValue}
            onChange={SearchInputChange}
            label='Recherche'
            variant='outlined'
          />
          <Select
            sx={{ minWidth: `120px` }}
            size='small'
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            value={categoryFilter}
            onChange={handleChangeCategory}
          >
            <MenuItem value={"all"}>Voir tout</MenuItem>
            {categoryList?.map((category: any, i1: number) => (
              <MenuItem key={i1} value={category}>
                {category}
              </MenuItem>
            ))}
          </Select>
          <ModelAdd
            categoryList={categoryList}
            API_USER={ADMIN_USER}
            mutations={mutations}
            data={data}
          />
          <GridToolbarColumnsButton
            sx={{ display: { xs: "none", sm: "flex" } }}
            ref={setFilterButtonEl}
          />
        </Stack>
        <Divider light />
      </Stack>
    </GridToolbarContainer>
  );
};

const ListModel = ({ userType, setAdminNavigation }: any) => {
  const [categoryList, setCategoryList] = React.useState<any>([]);
  const [searchInputValue, setSearchInputValue] = React.useState(``);
  const [categoryFilter, setCategoryFilter] = React.useState("all");
  const [filterColumnButton, setFilterColumnButton] =
    React.useState<HTMLButtonElement | null>(null);

  const fetchModelList = async () => {
    const response = await ADMIN.GET_MODEL();
    setCategoryList(
      response.data
        .map((row: any) => row.category.trim())
        .filter(
          (category: any, index: any, array: any) =>
            category !== "" && array.indexOf(category) === index
        )
    );
    return response.data;
  };

  const queryClient = useQueryClient();
  const { isLoading, data } = useQuery("ModelList", fetchModelList);

  const mutations = {
    delete: useMutation(ADMIN.DELETE_MODEL, {
      onSuccess: (resp) => {
        queryClient.setQueryData("ModelList", (oldData: any) =>
          oldData.filter((model: any) => model._id !== resp.data._id)
        );
      },
    }),
    create: useMutation(ADMIN.POST_MODEL, {
      onSuccess: (resp) => {
        queryClient.setQueryData("ModelList", (oldData: any) => {
          !categoryList.includes(resp.data.category) &&
            setCategoryList([...categoryList, resp.data.category]);
          return [...oldData, resp.data];
        });
      },
    }),
    update: useMutation(
      ({ id, updates }: any) => ADMIN.PUT_MODEL(id, updates),
      {
        onSuccess: (data, variables) => {
          queryClient.setQueryData("ModelList", (oldData: any) => {
            return oldData.map((model: any) =>
              model._id === variables.id ? data.data : model
            );
          });
        },
      }
    ),
  };

  const handleDeleteUser = async (id: string) => {
    await mutations.delete.mutateAsync(id);
    setOpenDeletePopup({ status: false });
  };

  //-------- Delete Popup --------
  const [OpenDeletePopup, setOpenDeletePopup] = React.useState<{
    status: boolean;
    row?: any;
  }>({ status: false });

  const SearchBarFilter = (rows: any) => {
    return rows
      .map((row: any) => {
        row.id = row._id;
        return row;
      })
      .filter(
        (row: any) =>
          (!searchInputValue ||
            row.title?.toLowerCase().includes(searchInputValue.toLowerCase()) ||
            row.description
              ?.toLowerCase()
              .includes(searchInputValue.toLowerCase())) &&
          (categoryFilter === "all" || row.category === categoryFilter)
      );
  };

  //-------- Grid Columns --------
  const columns = [
    //show by default
    {
      field: `title`,
      headerName: `Titre`,
      width: 250,
    },
    {
      field: `category`,
      headerName: `Catégorie`,
      width: 150,
    },
    {
      field: `machine`,
      headerName: `Machine`,
      width: 150,
    },
    {
      field: `description`,
      headerName: `Description`,
      width: 150,
    },
    // fixed right
    {
      field: `actions`,
      type: `actions`,
      headerName: `Actions`,
      width: 120,
      resizable: false,
      getActions: (e: { row?: any }) => [
        <GridActionsCellItem
          sx={{
            backgroundColor: "secondary.main",
          }}
          icon={
            <Link href={`/model/${e.row.id}`}>
              <ArticleIcon
                sx={{
                  color: "white",
                }}
              />
            </Link>
          }
          label='More'
          key='grid-actions-cell-item-2'
        />,
        <ModelEdit
          API={ADMIN}
          API_USER={ADMIN_USER}
          categoryList={categoryList}
          key='user-grid-edit'
          rowInfos={e}
          mutations={mutations}
          data={data}
        />,
        <GridActionsCellItem
          onClick={() => setOpenDeletePopup({ row: e.row, status: true })}
          icon={<DeleteIcon />}
          label='Delete'
          key='grid-actions-cell-item-1'
        />,
      ],
    },
  ];

  const DeleteModelPopup = () => {
    return (
      <Dialog
        open={OpenDeletePopup?.status}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle
          sx={{
            overflow: "hidden",
            fontSize: { xs: "17px", sm: "24px" },
            textOverflow: "ellipsis",
          }}
        >
          Voulez vous vraiment supprimer {OpenDeletePopup?.row?.title} ?
        </DialogTitle>
        <DialogActions
          sx={{
            padding: `10px 20px`,
            backgroundColor: "#f9f9f9",
          }}
        >
          <Button
            sx={{
              color: `text.disabled`,
            }}
            onClick={() => {
              setOpenDeletePopup({ status: false });
            }}
          >
            Annuler
          </Button>
          <Button
            sx={{
              color: `#fff`,
            }}
            variant='contained'
            disableElevation
            onClick={() => {
              OpenDeletePopup?.row?.id &&
                handleDeleteUser(OpenDeletePopup.row.id);
            }}
            autoFocus
          >
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Box
      className='wrapper_noPadding card'
      sx={{ height: "588px", display: "flex", alignItems: "center", gap: 2 }}
    >
      <Box
        sx={{
          height: "100%",
          width: "100%",
        }}
      >
        {!isLoading ? (
          <DataGridPro
            autoPageSize
            pagination
            sx={{
              '.MuiDataGrid-booleanCell[data-value="true"] ': {
                color: `#8D919A`,
              },
              borderRadius: `20px`,
              overflow: "hidden",
            }}
            rows={(data[0] && SearchBarFilter(data)) || []}
            columns={columns}
            disableColumnMenu
            localeText={{
              toolbarColumns: "Colonnes",
              columnsPanelTextFieldLabel: "Chercher une colonne",
              columnsPanelTextFieldPlaceholder: "Nom de la colonne",
              columnsPanelShowAllButton: "Afficher tout",
              columnsPanelHideAllButton: "Masquer tout",
            }}
            components={{
              Toolbar: CustomToolbar,
              NoRowsOverlay: () => (
                <Stack
                  height='100%'
                  alignItems='center'
                  justifyContent='center'
                >
                  Aucune ligne à afficher
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack
                  height='100%'
                  alignItems='center'
                  justifyContent='center'
                >
                  Aucun résultat
                </Stack>
              ),
            }}
            componentsProps={{
              panel: {
                anchorEl: filterColumnButton,
              },
              toolbar: {
                setFilterButtonEl: setFilterColumnButton,
                setSearchInputValue,
                searchInputValue,
                data,
                mutations,
                API_USER: ADMIN_USER,
                categoryFilter,
                setCategoryFilter,
                categoryList,
              },
            }}
            initialState={{ pinnedColumns: { right: [`actions`] } }}
          />
        ) : (
          <FullSizeLoading />
        )}
        <DeleteModelPopup />
      </Box>
    </Box>
  );
};

export default ListModel;
