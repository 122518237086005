import React from "react";
import { Box, Button, Typography, Link } from "@mui/material";
import { useParams } from "react-router-dom";
import { ADMIN } from "../api/ADMIN";
import { useQuery } from "react-query";
import { DashBoardSkeleton } from "../components/ui/Skeleton";
import { ADMIN_CLIENT } from "../api/ADMIN_CLIENT";
import { ADMIN_USER } from "../api/ADMIN_USER";
import { ADMIN_SETTINGS } from "../api/SETTINGS";
import ExportForm from "../components/admin/Export/ExportForm";
import ExportRapport from "../components/admin/Export/ExportRapport";
import ReplyIcon from "@mui/icons-material/Reply";
import { getLatestControls } from "../components/admin/Export/ExportRapportComposants/ExportRapportComposants";

import "../components/admin/Export/ExportRapport.css";

export const Export = () => {
  const reportId = useParams()?.report_id || "";
  const [formData, setFormData] = React.useState<any>({});

  const fetchExportDataById = async () => {
    const SettingsData = await ADMIN_SETTINGS.GET_SETTINGS();
    const ReportData = await ADMIN.GET_REPORT(reportId);
    const ModeleData = await ADMIN.GET_MODEL_BY_ID(ReportData.data.model_id);
    let UserDataPromise;
    if (ReportData?.data?.interventions?.length > 0) {
      UserDataPromise = ADMIN_USER.GET_USERS({
        _id: {
          $in: ReportData.data.interventions?.map((item: any) => item.user_id),
        },
      });
    }
    const [ClientData, UserData] = await Promise.all([
      ADMIN_CLIENT.GET_CLIENT(ReportData.data.client_id),
      UserDataPromise || { data: [] },
    ]);
    return {
      ReportData: ReportData.data,
      ClientData: ClientData.data,
      UserData: UserData?.data,
      SettingsData: SettingsData.data,
      ModeleData: ModeleData?.data,
    };
  };
  const { isLoading, data: exportData } = useQuery(
    "ExportData",
    fetchExportDataById
  );

  if (isLoading) {
    return <DashBoardSkeleton />;
  }

  return (
    <Box
      sx={{
        width: "100%",
        flexDirection: "column",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        className='wrapper'
        sx={{
          m: "40px 20px 20px 20px",
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: { xs: "center", sm: "space-between" },
          gap: { xs: "20px", sm: 0 },
          width: "calc(100% - 40px)",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <Link href={`/list_report`} sx={{ textDecoration: "none" }}>
            <Button
              variant='contained'
              sx={{
                color: "white",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <ReplyIcon />
              Retour
            </Button>
          </Link>
          <Typography variant='h3' sx={{ m: "0" }}>
            Exporter le rapport :
          </Typography>
        </Box>
      </Box>
      {getLatestControls(exportData?.ReportData?.interventions) ? (
        <Box className='wrapper'>
          <ExportForm
            exportData={exportData}
            formData={formData}
            setFormData={setFormData}
          />
          <div className='etude_root_div'>
            <ExportRapport exportData={exportData} formData={formData} />
          </div>
        </Box>
      ) : (
        <Box className='wrapper'>
          <Typography>
            Il n'y a pas assez de données pour générer un Export
          </Typography>
        </Box>
      )}
    </Box>
  );
};
