import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  TextField,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import CancelIcon from "@mui/icons-material/Cancel";
export const ProductsPopUp = ({
  open,
  onClose,
  products,
  usedProducts,
  setUsedProducts,
}: any) => {
  const [hasUsedContrastProduct, setHasUsedContrastProduct] = useState(false);
  const [contrastProducts, setContrastProducts] = useState<any>(
    products.filter((product: any) => product.type === "CONTRASTE")
  );
  const [developerProducts, setDeveloperProducts] = useState<any>(
    products.filter((product: any) => product.type === "REVELATEUR")
  );
  const [selectedDeveloper, setSelectedDeveloper] = useState<any>(null);
  const [selectedContrast, setSelectedContrast] = useState<any>(null);
  const [selectedProducts, setSelectedProducts] = useState<any>({
    contraste: null,
    revelateur: {
      _id: null,
      productModel: null,
    },
  });
  const resetState = () => {
    setSelectedProducts({
      contraste: null,
      revelateur: {
        _id: null,
        productModel: null,
      },
    });
    setHasUsedContrastProduct(false);
  };

  const addProduct = () => {
    setUsedProducts([...usedProducts, selectedProducts]);
    setSelectedContrast(null);
    setSelectedDeveloper(null);
  };

  const checkProductNotNull = (product: any) => {
    return product?._id !== null && product?.productModel;
  };

  const deleteProduct = (index: number, type: string, productId: string) => {
    setUsedProducts(
      type === "contraste"
        ? usedProducts.map((product: any, indexProduct: number) => {
            if (indexProduct === index && product[type]._id === productId) {
              return { ...product, [type]: null };
            } else {
              return product;
            }
          })
        : usedProducts.filter(
            (usedProduct: any, indexProduct: number) =>
              indexProduct !== index || usedProduct[type]._id !== productId
          )
    );
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth={"xs"} fullWidth>
      <DialogTitle
        sx={{
          width: 1,
          backgroundColor: "customGrey.grey1",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        Saisie des produits utilisés
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            margin: "10px 0",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: "20px",
            alignItems: "center",
            width: 1,
          }}
        >
          <FormControl sx={{ width: 1 }}>
            <InputLabel id='has-used-contrast-product-selector-label'>
              Utilisation d'un produit de contraste
            </InputLabel>
            <Select
              labelId='has-used-contrast-product-selector-label'
              id='has-used-contrast-product-selector'
              label="Utilisation d'un produit de contraste"
              value={hasUsedContrastProduct}
              sx={{ width: "100%" }}
              onChange={(e: any) => setHasUsedContrastProduct(e.target.value)}
            >
              <MenuItem value={true as any}>Oui</MenuItem>
              <MenuItem value={false as any}>Non</MenuItem>
            </Select>
          </FormControl>

          {hasUsedContrastProduct && (
            <Box
              sx={{
                border: "1px solid grey",
                width: 1,
                padding: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "20px",
                borderRadius: "10px",
              }}
            >
              <FormControl sx={{ width: 1 }}>
                <InputLabel id='contrast-product-selector-label'>
                  Saisir un produit de contraste
                </InputLabel>
                <Select
                  labelId='contrast-product-selector-label'
                  id='contrast-product-selector'
                  label='Saisir un produit de contraste'
                  value={selectedProducts.contraste?._id || ""}
                  sx={{ width: "100%" }}
                  onChange={
                    (e: any) => {
                      setSelectedContrast(
                        products.find(
                          (product: any) => product.id === e.target.value
                        )
                      );
                      setSelectedProducts({
                        ...selectedProducts,
                        contraste: {
                          ...selectedProducts.contraste,
                          _id: e.target.value,
                        },
                      });
                    }
                    // setSelectedProducts({
                    //   ...selectedProducts,
                    //   contraste: {
                    //     ...selectedProducts.contraste,
                    //     _id: e.target.value,
                    //   },
                    // })
                  }
                >
                  {contrastProducts?.map((contrastProduct: any) => (
                    <MenuItem
                      key={contrastProduct.id}
                      value={contrastProduct.id}
                    >
                      {contrastProduct.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {selectedContrast !== null && (
                <FormControl sx={{ width: 1 }}>
                  <InputLabel id='contrast-product-selector-label'>
                    Saisir un numéro de lot
                  </InputLabel>
                  <Select
                    labelId='batch-number-selector-label'
                    id='batch-number-selector'
                    label='Saisir un numéro de lot'
                    value={selectedProducts.contraste?.productModel || ""}
                    sx={{ width: "100%" }}
                    onChange={(e: any) => {
                      setSelectedProducts({
                        ...selectedProducts,
                        contraste: {
                          ...selectedProducts.contraste,
                          productModel: e.target.value,
                        },
                      });
                    }}
                  >
                    {selectedContrast?.productModels?.map(
                      (productModel: any, index: number) => (
                        <MenuItem key={index} value={productModel}>
                          {productModel.batchNumber} -{" "}
                          {dayjs(productModel.expirationDate).format(
                            "DD/MM/YYYY"
                          )}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              )}
            </Box>
          )}
          <Box
            sx={{
              border: "1px solid grey",
              width: 1,
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
              borderRadius: "10px",
            }}
          >
            <FormControl sx={{ width: 1 }}>
              <InputLabel id='developer-product-selector-label'>
                Saisir un produit révélateur
              </InputLabel>
              <Select
                labelId='developer-product-selector-label'
                id='developer-product-selector'
                label='Saisir un produit révélateur'
                sx={{ width: 1 }}
                value={selectedProducts.revelateur._id || ""}
                onChange={(e: any) => {
                  setSelectedDeveloper(
                    products.find(
                      (product: any) => product.id === e.target.value
                    )
                  );
                  setSelectedProducts({
                    ...selectedProducts,
                    revelateur: {
                      ...selectedProducts.revelateur,
                      _id: e.target.value,
                    },
                  });
                }}
              >
                {developerProducts?.map((developerProduct: any) => (
                  <MenuItem
                    key={developerProduct.id}
                    value={developerProduct.id}
                  >
                    {developerProduct.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {selectedDeveloper !== null && (
              <FormControl sx={{ width: 1 }}>
                <InputLabel id='contrast-product-selector-label'>
                  Saisir un numéro de lot
                </InputLabel>
                <Select
                  labelId='batch-number-selector-label'
                  id='batch-number-selector'
                  label='Saisir un numéro de lot'
                  value={selectedProducts.revelateur?.productModel || ""}
                  sx={{ width: "100%" }}
                  onChange={(e: any) => {
                    setSelectedProducts({
                      ...selectedProducts,
                      revelateur: {
                        ...selectedProducts.revelateur,
                        productModel: e.target.value,
                      },
                    });
                  }}
                >
                  {selectedDeveloper?.productModels?.map(
                    (productModel: any, index: number) => (
                      <MenuItem key={index} value={productModel}>
                        {productModel.batchNumber} -{" "}
                        {dayjs(productModel.expirationDate).format(
                          "DD/MM/YYYY"
                        )}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            )}

            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ width: 1 }}
                label="Date d'expiration du produit révélateur"
                value={dayjs(selectedProducts.revelateur.expirationDate) || ""}
                onChange={(e: any) =>
                  setSelectedProducts({
                    ...selectedProducts,
                    revelateur: {
                      ...selectedProducts.revelateur,
                      expirationDate: e.$d,
                    },
                  })
                }
                format='DD/MM/YYYY'
              />
            </LocalizationProvider>
          </Box> */}

            {((hasUsedContrastProduct &&
              checkProductNotNull(selectedProducts.contraste) &&
              checkProductNotNull(selectedProducts.revelateur)) ||
              (!hasUsedContrastProduct &&
                checkProductNotNull(selectedProducts.revelateur))) && (
              <Box sx={{ display: "flex", gap: "20px" }}>
                <Button
                  variant='contained'
                  onClick={() => {
                    addProduct();
                    resetState();
                  }}
                >
                  Saisir
                </Button>
                <Button onClick={resetState}>Annuler</Button>
              </Box>
            )}
          </Box>
        </Box>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Contraste</TableCell>
              <TableCell>Révélateur</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {usedProducts.length === 0 && (
              <TableRow>
                <TableCell
                  sx={{
                    width: "200px",
                  }}
                >
                  Aucun produit
                </TableCell>
                <TableCell
                  sx={{
                    width: "200px",
                  }}
                >
                  Aucun produit
                </TableCell>
              </TableRow>
            )}

            {usedProducts?.map((usedProduct: any, index: number) => {
              const productDeveloper = products.find(
                (product: any) => product.id === usedProduct.revelateur._id
              );
              let productContrast: any = null;
              if (usedProduct.contraste !== null) {
                productContrast = products.find(
                  (product: any) => product.id === usedProduct.contraste._id
                );
              }
              return (
                <TableRow key={index}>
                  <TableCell
                    sx={{
                      width: "200px",
                    }}
                  >
                    {productContrast && (
                      <Box
                        sx={{
                          position: "relative",
                          padding: "5px",
                          margin: "10px",
                          borderRadius: "20px",
                        }}
                      >
                        <Typography variant='body2' sx={{ margin: "5px" }}>
                          {productContrast?.name}
                        </Typography>
                        <IconButton
                          sx={{
                            position: "absolute",
                            right: 0,
                            top: "50%",
                            transform: "translateY(-50%)",
                          }}
                          onClick={() =>
                            deleteProduct(
                              index,
                              "contraste",
                              productContrast?.id
                            )
                          }
                        >
                          <CancelIcon />
                        </IconButton>
                      </Box>
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "200px",
                    }}
                  >
                    {productDeveloper && (
                      <Box
                        sx={{
                          position: "relative",
                          padding: "5px",
                          margin: "10px",
                          borderRadius: "20px",
                        }}
                      >
                        <Typography variant='body2' sx={{ margin: "5px" }}>
                          {productDeveloper?.name}
                        </Typography>

                        <IconButton
                          sx={{
                            position: "absolute",
                            right: 0,
                            top: "50%",
                            transform: "translateY(-50%)",
                          }}
                          onClick={() =>
                            deleteProduct(
                              index,
                              "revelateur",
                              productDeveloper?.id
                            )
                          }
                        >
                          <CancelIcon />
                        </IconButton>
                      </Box>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions sx={{ width: 1, backgroundColor: "customGrey.grey1" }}>
        <Box>
          <Button
            variant='contained'
            onClick={() => {
              onClose();
            }}
          >
            Confirmer
          </Button>
          <Button
            onClick={() => {
              onClose();
              setUsedProducts([]);
            }}
          >
            Annuler
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
