import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Button, Typography, Link, TextField } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ADMIN } from "../api/ADMIN";
import AddIcon from "@mui/icons-material/Add";
import ElementPopup from "../components/admin/ListModel/ElementPopup";
import ElementModel from "../components/admin/ListModel/ElementModel";

import ReplyIcon from "@mui/icons-material/Reply";
import { CriteriaPopUp } from "../components/admin/ListModel/CriteriaPopUp";
import { LexiconPopUp } from "../components/admin/ListModel/LexiconPopUp";
import { RefDocPopUp } from "../components/admin/ListModel/RefDocPopUp";
export const Model = () => {
  const [openElementPopup, setOpenElementPopup] = useState(false);
  const [openCriteriaPopUp, setOpenCriteriaPopUp] = useState(false);
  const [openLexiconPopUp, setOpenLexiconPopUp] = useState(false);
  const [openRefDocPopUp, setOpenRefDocPopUp] = useState(false);
  const modelId = useParams()?.model_id || "";
  const toggleElementPopup = () => {
    setOpenElementPopup((isOpen) => !isOpen);
  };
  const fetchModels = async () => {
    const response = await ADMIN.GET_MODEL_BY_ID(modelId);
    return response.data;
  };
  const queryClient = useQueryClient();
  const { isLoading, data: model } = useQuery("getModels", fetchModels);
  const mutations = {
    addElement: useMutation(
      ({ modelId, name }: any) => ADMIN.POST_ELEMENT_IN_MODEL(modelId, name),
      {
        onSuccess: (data, variables) => {
          queryClient.setQueryData("getModels", (oldData: any) => {
            return data.data;
          });
        },
      }
    ),
    addControl: useMutation(
      ({ modelId, elementId, description }: any) =>
        ADMIN.POST_CONTROLE_IN_MODEL(modelId, elementId, description),
      {
        onSuccess: (data, variables) => {
          queryClient.setQueryData("getModels", (oldData: any) => {
            return data.data;
          });
        },
      }
    ),
    deleteElement: useMutation(
      ({ elementId }: any) => {
        return ADMIN.DELETE_ELEMENT_IN_MODEL(elementId);
      },
      {
        onSuccess: (data, variables) => {
          queryClient.setQueryData("getModels", (oldData: any) => {
            return data.data;
          });
        },
      }
    ),
    deleteControl: useMutation(
      ({ controleId }: any) => {
        return ADMIN.DELETE_CONTROLE_IN_MODEL(controleId);
      },
      {
        onSuccess: (data, variables) => {},
      }
    ),
    putElement: useMutation(
      ({ elementId, element }: any) =>
        ADMIN.PUT_ELEMENT_IN_MODEL(elementId, element),
      {
        onSuccess: (data, variables) => {
          queryClient.setQueryData("getModels", (oldData: any) => {
            return data.data;
          });
        },
      }
    ),
    putModel: useMutation(
      ({ modelId, model }: any) => ADMIN.PUT_MODEL(modelId, model),
      {
        onSuccess: (data, variables) => {
          queryClient.setQueryData("getModels", (oldData: any) => {
            return data.data;
          });
        },
      }
    ),
  };

  if (isLoading) return <div>loading...</div>;
  return (
    <Box
      className='wrapper'
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <ElementPopup
        mutations={mutations}
        open={openElementPopup}
        toggle={toggleElementPopup}
        modelId={modelId}
      />

      <Box
        sx={{
          paddingTop: "40px",
          maxWidth: "1154px",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: { xs: "center", sm: "space-between" },
            gap: { xs: "20px", sm: 0 },
            width: 1,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <Link href={`/list_model`} sx={{ textDecoration: "none" }}>
              <Button
                variant='contained'
                sx={{
                  color: "white",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <ReplyIcon />
                Retour
              </Button>
            </Link>
            <Typography variant='h3' sx={{ m: "0" }}>
              Editer le modèle : {model?.title}
            </Typography>
          </Box>
          <Button variant='contained' onClick={toggleElementPopup}>
            <AddIcon sx={{ marginRight: "10px" }} />
            Ajouter un élément
          </Button>
        </Box>{" "}
        <Box
          sx={{
            marginTop: "30px",
            padding: "15px",
            width: 1,
          }}
          className='card'
        >
          {openCriteriaPopUp && (
            <CriteriaPopUp
              criteriaText={model?.acceptedCriteria || ""}
              open={openCriteriaPopUp}
              toggle={() => setOpenCriteriaPopUp(false)}
              model={model}
              mutations={mutations}
            />
          )}

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          >
            <Typography variant='h3' sx={{ margin: "0px" }}>
              Critère(s) d'acceptation :
            </Typography>
            <Button
              variant='contained'
              onClick={() => setOpenCriteriaPopUp(true)}
              sx={{
                backgroundColor: "#F6A21A",
                marginLeft: "15px",
              }}
            >
              Editer
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
              mt: "10px",
            }}
          >
            <TextField
              multiline
              minRows={2}
              sx={{ width: 1 }}
              value={
                model?.acceptedCriteria === ""
                  ? "Vous n'avez pas saisi de critère d'acceptation"
                  : model?.acceptedCriteria
              }
              disabled
            />
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: "30px",
            padding: "15px",
            width: 1,
          }}
          className='card'
        >
          {openRefDocPopUp && (
            <RefDocPopUp
              refDocText={model?.refereDocument || ""}
              open={openRefDocPopUp}
              toggle={() => setOpenRefDocPopUp(false)}
              model={model}
              mutations={mutations}
            />
          )}

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          >
            <Typography variant='h3' sx={{ margin: "0px" }}>
              Document de référence :
            </Typography>
            <Button
              variant='contained'
              onClick={() => setOpenRefDocPopUp(true)}
              sx={{
                backgroundColor: "#F6A21A",
                marginLeft: "15px",
              }}
            >
              Editer
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
              mt: "10px",
            }}
          >
            <TextField
              multiline
              minRows={2}
              sx={{ width: 1 }}
              value={
                model?.refereDocument === ""
                  ? "Vous n'avez pas saisi de document de référence"
                  : model?.refereDocument
              }
              disabled
            />
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: "30px",
            padding: "15px",
            width: 1,
          }}
          className='card'
        >
          {openLexiconPopUp && (
            <LexiconPopUp
              lexiconText={model?.lexicon || ""}
              open={openLexiconPopUp}
              toggle={() => setOpenLexiconPopUp(false)}
              model={model}
              mutations={mutations}
            />
          )}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          >
            <Typography variant='h3' sx={{ margin: "0px" }}>
              Lexique :
            </Typography>
            <Button
              variant='contained'
              onClick={() => setOpenLexiconPopUp(true)}
              sx={{
                backgroundColor: "#F6A21A",
                marginLeft: "15px",
              }}
            >
              Editer
            </Button>
          </Box>
          <TextField
            multiline
            minRows={2}
            sx={{ width: 1 }}
            value={
              model?.lexicon === "" || !model?.lexicon
                ? "Vous n'avez pas saisi de lexique"
                : model?.lexicon
            }
            disabled
          />
        </Box>
        <Box sx={{ margin: "30px 0", width: 1 }}>
          {model?.model?.length !== 0 ? (
            model?.model.map((element: any) => {
              return (
                <ElementModel
                  key={element?._id}
                  element={element}
                  modelId={model?._id}
                  mutations={mutations}
                  model={model?.model}
                  deleteMutation={() =>
                    mutations.deleteElement.mutateAsync({
                      elementId: element._id,
                    })
                  }
                />
              );
            })
          ) : (
            <Typography
              variant='h2'
              sx={{ margin: "20px 0", textAlign: "center" }}
            >
              Le modèle ne contient aucun élément.
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};
