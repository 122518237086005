export const getUsedProducts = (ReportData: any) => {
  let uniqueProducts: any[] = [];
  const interventions = ReportData?.interventions;
  interventions?.forEach((intervention: any) => {
    const examProducts = intervention?.examProducts;
    examProducts?.forEach((examProduct: any) => {
      const contrastProduct = examProduct.contraste;
      const developerProduct = examProduct.revelateur;
      const isContrastNull = contrastProduct === null;

      let productIndex = uniqueProducts.findIndex((product: any) => {
        if (isContrastNull && product.contraste === null) {
          return product.revelateur?._id === developerProduct?._id;
        }
        return (
          product.contraste?._id === contrastProduct?._id &&
          product.revelateur?._id === developerProduct?._id
        );
      });

      if (productIndex === -1 && isContrastNull) {
        uniqueProducts.push({
          contraste: null,
          revelateur: developerProduct,
        });
      } else if (productIndex === -1) {
        uniqueProducts.push({
          contraste: contrastProduct,
          revelateur: developerProduct,
        });
      } else {
        uniqueProducts[productIndex] = {
          contraste: contrastProduct,
          revelateur: developerProduct,
        };
      }
    });
  });
  return uniqueProducts;
};
