import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Switch,
  FormGroup,
  Box,
  FormControlLabel,
  Button,
  DialogActions,
  IconButton,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { ObservationPopup } from "./ObservationPopup";
import EditIcon from "@mui/icons-material/Edit";
import { USERS } from "../../../api/USER";
export const InterventionPopup = ({
  open,
  onClose,
  control,
  mutations,
  intervention,
  interventionUserId,
  selectedElementId,
  userType,
  setStartedControles,
  startedControles,
  mode,
  lastInterventionsObservations,
}: any) => {
  const [hasDeletedImage, setHasDeletedImage] = useState<any>({
    status: false,
    deletedImageIds: [],
  });
  const [imageIds, setImageIds] = useState([]);
  const [openObservationPopUp, setOpenObservationPopUp] = useState<any>({
    status: false,
    key: null,
  });
  const [controlled, setControlled] = useState(
    control?.result?.conforme !== undefined || false
  );

  const [compliant, setCompliant] = useState(
    control?.result?.conforme === "true" ? "true" : "false"
  );
  const [errorControlled, setErrorControlled] = useState("");

  const [observations, setObservations] = useState([]);

  useEffect(() => {
    if (control?.result?.observations) {
      setObservations(
        control?.result?.observations?.filter((observation: any) => {
          return !lastInterventionsObservations?.some((item: any) => {
            return (
              item.description === observation?.description &&
              item.action === observation?.action
            );
          });
        })
      );
    }
  }, [control?.result?.observations, lastInterventionsObservations]);

  const handleSubmit = async () => {
    console.log(
      "sent observations : ",
      lastInterventionsObservations
        ? [...lastInterventionsObservations, ...observations]
        : [...observations]
    );

    await mutations.putControl.mutateAsync({
      reportId: intervention._id,
      interventionId: interventionUserId,
      elementId: selectedElementId,
      controlId: control._id,
      controlResult: {
        result: {
          conforme: controlled ? compliant : undefined,
          observations: lastInterventionsObservations
            ? [...lastInterventionsObservations, ...observations]
            : [...observations],
        },
      },
    });

    hasDeletedImage?.deletedImageIds?.map(async (imageId: any) => {
      await mutations.deleteImage.mutateAsync({
        reportId: intervention?._id,
        imageId: imageId,
      });
    });
    setHasDeletedImage({ status: false, deletedImageIds: [] });
    onClose();
  };
  const handleCancel = () => {
    imageIds.map(async (imageId) => {
      await mutations.deleteImage.mutateAsync({
        reportId: intervention?._id,
        imageId: imageId,
      });
    });
    setErrorControlled("");
  };

  console.log("last : ", lastInterventionsObservations);
  console.log(
    "observations from us : ",
    control?.result?.observations,
    control?.result?.observations?.filter((observation: any) => {
      return !lastInterventionsObservations?.some((item: any) => {
        return (
          item.description === observation?.description &&
          item.action === observation?.action
        );
      });
    })
  );
  console.log("observations : ", observations);
  console.log("--------------------");
  return (
    <Dialog
      open={open}
      onClose={async () => {
        if (observations?.length !== 0 || controlled) {
          await mutations.putControl.mutateAsync({
            reportId: intervention._id,
            interventionId: interventionUserId,
            elementId: selectedElementId,
            controlId: control._id,
            controlResult: {
              result: {
                conforme: controlled ? compliant : undefined,
                observations: lastInterventionsObservations
                  ? [...lastInterventionsObservations, ...observations]
                  : [...observations],
              },
            },
          });
        }

        onClose();
      }}
      maxWidth={"sm"}
      fullWidth
    >
      <DialogTitle
        sx={{
          width: 1,
          backgroundColor: "customGrey.grey1",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography sx={{ fontSize: "20px", fontWeight: "600" }}>
            Intervention du contrôle
          </Typography>
        </Box>
        <CloseIcon sx={{ cursor: "pointer" }} onClick={onClose} />
      </DialogTitle>

      <DialogContent sx={{ height: "500px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
            Description du contrôle
          </Typography>
          <TextField
            multiline
            disabled
            value={control?.description}
            sx={{ maxWidth: "100%", width: "400px" }}
            minRows={3}
            maxRows={10}
          />
          {(userType === "ADMIN" ||
            (userType === "USER" && mode === "INTERVENTION")) && (
            <Box sx={{ margin: "20px", width: "270px", maxWidth: "100%" }}>
              <Button
                variant='contained'
                onClick={() =>
                  setOpenObservationPopUp({ status: true, key: null })
                }
                fullWidth
              >
                <AddIcon sx={{ marginRight: "10px", fontSize: "20px" }} />
                <Typography
                  sx={{ color: "white", fontSize: "16px", fontWeight: "500" }}
                >
                  Ajouter une observation
                </Typography>
              </Button>
            </Box>
          )}
          <Box
            sx={{
              marginTop: "10px",
              maxWidth: "100%",
              width: "400px",
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            {lastInterventionsObservations?.map(
              (lastInterventionsObservation: any, index: number) => (
                <Typography key={index}>
                  {lastInterventionsObservation.description}
                </Typography>
              )
            )}
            {observations?.map((observation: any, key: number) => {
              return (
                <Box
                  key={key}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ fontSize: "16px" }}>
                    {observation?.description}
                  </Typography>

                  {(userType === "ADMIN" ||
                    (userType === "USER" && mode === "INTERVENTION")) && (
                    <Box>
                      <IconButton
                        onClick={() =>
                          setOpenObservationPopUp({ status: true, key: key })
                        }
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setHasDeletedImage({
                            ...hasDeletedImage,
                            status: true,
                            deletedImageIds: [
                              ...hasDeletedImage.deletedImageIds,
                              observation.image_id,
                            ],
                          });

                          setObservations(
                            observations?.filter(
                              (_: any, index: number) => index !== key
                            )
                          );
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              );
            })}
            {openObservationPopUp.status && (
              <ObservationPopup
                openObservationPopUp={openObservationPopUp}
                setOpenObservationPopUp={setOpenObservationPopUp}
                setObservations={setObservations}
                observations={observations}
                mutations={mutations}
                control={control}
                intervention={intervention}
                imageIds={imageIds}
                setImageIds={setImageIds}
                selectedElementId={selectedElementId}
                interventionUserId={interventionUserId}
                setHasDeletedImage={setHasDeletedImage}
                hasDeletedImage={hasDeletedImage}
                userType={userType}
                // startedControles={startedControles}
                // setStaredControles={setStartedControles}
                lastInterventionsObservations={lastInterventionsObservations}
              />
            )}
          </Box>
          <Box
            sx={{
              borderTop: "1px black solid",
              marginTop: "30px",
              width: 1,
            }}
          >
            <FormGroup
              sx={{
                display: "flex",
                flexDirection: "column",
                paddingTop: "10px",
              }}
            >
              <Typography
                sx={{
                  color: "red",
                  fontWeight: "500",
                  textAlign: "center",
                  margin: "5px 0",
                }}
              >
                {errorControlled}
              </Typography>
              <Box sx={{ display: "flex" }}>
                {controlled ? (
                  <Typography sx={{ minWidth: "130px" }}>Contrôlé</Typography>
                ) : (
                  <Typography sx={{ minWidth: "130px" }}>En cours</Typography>
                )}
                <FormControlLabel
                  control={
                    <Switch
                      checked={controlled || false}
                      onChange={(e) => {
                        if (
                          userType === "ADMIN" ||
                          (userType === "USER" && mode === "INTERVENTION")
                        ) {
                          setControlled(e.target.checked);
                          if (e.target.checked) {
                            setErrorControlled("");
                          }
                        } else {
                          setErrorControlled(
                            "Vous ne pouvez pas modifier une intervention déjà contrôlée"
                          );
                        }
                      }}
                    />
                  }
                  label=''
                />
              </Box>
            </FormGroup>
          </Box>
          <Box sx={{ width: 1 }}>
            <Box
              sx={{
                marginTop: "10px",
                width: 1,
              }}
            >
              {controlled ? (
                <FormGroup
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {compliant === "true" ? (
                    <Typography sx={{ minWidth: "130px" }}>Conforme</Typography>
                  ) : (
                    <Typography sx={{ minWidth: "130px" }}>
                      Non conforme
                    </Typography>
                  )}
                  <FormControlLabel
                    control={
                      <Switch
                        checked={compliant === "true"}
                        onChange={(e) => {
                          if (
                            userType === "ADMIN" ||
                            (userType === "USER" && mode === "INTERVENTION")
                          ) {
                            setCompliant(e.target.checked ? "true" : "false");
                          } else {
                            setErrorControlled(
                              "Vous ne pouvez pas modifier une intervention déjà contrôlée"
                            );
                          }
                        }}
                      />
                    }
                    label=''
                  />
                </FormGroup>
              ) : (
                <></>
              )}
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ width: 1, backgroundColor: "customGrey.grey1" }}>
        {(userType === "ADMIN" ||
          (userType === "USER" && mode === "INTERVENTION")) && (
          <Button variant='contained' onClick={handleSubmit}>
            Enregistrer
          </Button>
        )}
        <Button
          onClick={() => {
            handleCancel();
            onClose();
          }}
        >
          {userType === "ADMIN" ||
          (userType === "USER" && mode === "INTERVENTION")
            ? "Annuler"
            : "Fermer"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
