import React, { useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
  Select,
  MenuItem,
  Chip,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

function ListProduct_Add({ mutations }: any) {
  const [expirationDate, setExpirationDate] = useState<any>(null);
  const [productModels, setProductModels] = useState<any>([]);
  const [batchNumber, setBatchNumber] = useState("");
  const [error, setError] = useState("");
  //-------- Add User Popup ---------
  const [OpenAddUserPopup, setOpenAddUserPopup] =
    React.useState<boolean>(false);

  const [type, setType] = React.useState("CONTRASTE");

  const handleChangeType = (event: any) => {
    setType(event.target.value as string);
  };

  //formik
  const formikSchema = yup.object({
    name: yup
      .string()
      .min(2, `Titre trop court !`)
      .max(50, `Titre trop long !`)
      .required(`Veuillez renseigner un nom`),
  });

  const formik = useFormik({
    initialValues: {
      name: ``,
    },
    validationSchema: formikSchema,
    onSubmit: async (formValues, { resetForm }) => {
      await mutations.create.mutateAsync({
        name: formValues.name,
        type: type,
        productModels: productModels,
      });
      setError("");
      setProductModels([]);
      setExpirationDate(null);
      handleAddUserPopupClose();
      resetForm();
    },
  });

  const handleAddUserPopupClose = () => {
    formik.resetForm();
    setOpenAddUserPopup(false);
  };

  return (
    //header de user management
    <Stack direction='row' alignItems='center' spacing={5}>
      <Button
        onClick={() => {
          setOpenAddUserPopup(true);
        }}
        sx={{
          color: "#fff",
          height: `38px`,
        }}
        variant='contained'
      >
        <AddIcon />
        <Typography
          sx={{
            color: "#fff",
            fontSize: { xs: `0`, md: `14px` },
          }}
          variant='body2'
        >
          Ajouter un produit
        </Typography>
      </Button>
      <Dialog open={OpenAddUserPopup}>
        <Box component='form' onSubmit={formik.handleSubmit}>
          <DialogTitle sx={{ backgroundColor: "#f9f9f9" }}>
            Ajouter un produit
          </DialogTitle>
          <DialogContent
            sx={{
              width: `560px`,
              maxWidth: `100%`,
              padding: `20px 20px 20px 20px`,
              mt: 2,
              "& .MuiFormLabel-root:not(.Mui-focused, .Mui-error)": {
                color: "grey !important",
              },
            }}
          >
            <Stack>
              <TextField
                margin='dense'
                sx={{ mb: "15px" }}
                fullWidth
                id='name'
                label='Nom'
                name='name'
                autoComplete='name'
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              <Select
                margin='dense'
                id='demo-simple-select'
                fullWidth
                value={type}
                onChange={handleChangeType}
              >
                <MenuItem value={"CONTRASTE"}>Contraste</MenuItem>
                <MenuItem value={"REVELATEUR"}>Revelateur</MenuItem>
              </Select>
            </Stack>
            <Box
              sx={{
                border: "1px solid grey",
                borderRadius: "20px",
                padding: "10px",
                margin: "10px 0",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  sx={{ width: 1, marginTop: "20px" }}
                  label="Date d'expiration du produit de contraste"
                  value={dayjs(expirationDate)}
                  onChange={(e: any) => {
                    setExpirationDate(e.$d);
                    setError("");
                  }}
                  format='DD/MM/YYYY'
                />
              </LocalizationProvider>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: 1,
                  margin: "20px 0",
                }}
              >
                <TextField
                  sx={{ width: 1 }}
                  label='Ajouter un numéro de lot'
                  value={batchNumber || ""}
                  onChange={(e) => setBatchNumber(e.target.value)}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                  margin: "20px 0",
                }}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
                >
                  <Box>
                    <Button
                      disabled={batchNumber === ""}
                      variant='contained'
                      onClick={() => {
                        if (expirationDate === null) {
                          setError(
                            "Vous devez renseigner une date d'expiration"
                          );
                          return;
                        }
                        setProductModels([
                          ...productModels,
                          {
                            batchNumber: batchNumber,
                            expirationDate: expirationDate,
                          },
                        ]);
                        setBatchNumber("");
                        setExpirationDate(null);
                      }}
                    >
                      Ajouter
                    </Button>
                    <Button onClick={() => setBatchNumber("")}>Annuler</Button>
                  </Box>
                  {error && (
                    <Typography sx={{ color: "red" }}>{error}</Typography>
                  )}
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
              {productModels?.map((currentProductModel: any, index: number) => {
                return (
                  <Chip
                    key={index}
                    label={`${currentProductModel.batchNumber} - ${dayjs(
                      currentProductModel.expirationDate
                    ).format("DD/MM/YYYY")}`}
                    onDelete={() => {
                      setProductModels(
                        productModels.filter(
                          (enteredProductModel: any) =>
                            enteredProductModel.batchNumber !==
                            currentProductModel.batchNumber
                        )
                      );
                    }}
                  />
                );
              })}
            </Box>
          </DialogContent>
          <DialogActions
            sx={{
              padding: `10px 20px`,
              backgroundColor: "#f9f9f9",
            }}
          >
            <Button
              type='submit'
              sx={{
                color: `#fff`,
              }}
              variant='contained'
              disableElevation
              onClick={() => null}
              autoFocus
            >
              Créer
            </Button>
            <Button
              sx={{
                color: `text.disabled`,
              }}
              onClick={handleAddUserPopupClose}
            >
              Annuler
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Stack>
  );
}

export default ListProduct_Add;
