import { useState, useEffect } from "react";
import { Footer, Header } from "./ExportRapportComposants";
import { upload } from "@testing-library/user-event/dist/upload";
import { ADMIN } from "../../../../api/ADMIN";
const UploadImage = ({
  image_id,
  report_id,
}: {
  image_id: string;
  report_id: string;
}) => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  useEffect(() => {
    async function fetchImage() {
      const result = await ADMIN.DOWNLOAD_OBSERVATION_IMG(report_id, image_id);
      if (result.data) {
        const blobUrl = URL.createObjectURL(result.data);
        setImageSrc(blobUrl);
      }
    }
    if (image_id) {
      fetchImage();
    }
    return () => {
      if (imageSrc) {
        URL.revokeObjectURL(imageSrc);
      }
    };
  }, [image_id, report_id]);

  if (!imageSrc) return null;

  return <img src={imageSrc} alt='Uploaded content' />;
};
export const PreRenderTableauObservations = ({
  controlChunk,
  report_id,
}: any) => {
  return (
    <div>
      {controlChunk?.map(
        (control: any, i2: number) =>
          control.result?.observations?.length > 0 && (
            <table
              key={i2}
              className='observations-table'
              id={`${control._id}`}
            >
              <thead>
                <tr>
                  <td>{control.description}</td>
                  <td>Action à prévoir :</td>
                </tr>
              </thead>
              <tbody>
                {control.result?.observations?.map(
                  (observation: any, i1: number) => (
                    <tr key={i1}>
                      <td className='column-description'>
                        {observation.description}
                        {observation.image_id && (
                          <div className='image_container'>
                            <UploadImage
                              image_id={observation.image_id}
                              report_id={report_id}
                            />
                          </div>
                        )}
                      </td>
                      <td className='column-action'>{observation.action}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          )
      )}
    </div>
  );
};

export const TableauObservations = ({
  controlChunk,
  totalPages,
  setPageCount,
  affaire_num,
  machine,
  report_id,
  reportNumber,
}: any) => {
  type ControlData = { height: number; content: any };
  type PageContent = ControlData[];

  const [controlDataList, setControlDataList] = useState<ControlData[]>([]);
  const [pagesContent, setPagesContent] = useState<PageContent[]>([]);

  const toggleElementVisibility = (
    elementId: string,
    displayValue: "block" | "none"
  ) => {
    const element = document.getElementById(elementId);
    element?.style.setProperty("display", displayValue);
  };
  const computeControlHeight = (controlId: string): number => {
    const controlElement = document.getElementById(controlId);
    return controlElement ? controlElement.clientHeight + 80 : 0;
  };

  useEffect(() => {
    toggleElementVisibility("preRenderObservationControl", "block");

    const controlData = controlChunk.map((control: any) => ({
      content: control,
      height: computeControlHeight(control._id),
    }));

    setControlDataList(controlData);
  }, [controlChunk]);

  useEffect(() => {
    const maxPageHeight = 1270;
    const newPagesContent: PageContent[] = [];
    let currentPageContent: ControlData[] = [];
    let currentPageHeight = 0;

    controlDataList.forEach((controlData) => {
      if (currentPageHeight === 0 && controlData.height >= maxPageHeight) {
        currentPageContent.push(controlData);
        currentPageHeight += controlData.height;
      } else if (currentPageHeight + controlData.height <= maxPageHeight) {
        currentPageContent.push(controlData);
        currentPageHeight += controlData.height;
      } else {
        newPagesContent.push(currentPageContent);
        currentPageContent = [controlData];
        currentPageHeight = controlData.height;
      }
    });

    if (currentPageContent.length > 0) {
      newPagesContent.push(currentPageContent);
    }
    setPagesContent(newPagesContent);

    toggleElementVisibility("preRenderObservationControl", "none");
  }, [controlDataList]);

  return (
    <>
      {pagesContent.map((pageDivs, pageIndex) => (
        <div key={pageIndex} className='page'>
          <Header
            affaire_num={affaire_num}
            machine={machine}
            report_num={reportNumber}
          />
          <div className='page_content'>
            <div className='page_section'>
              {pageIndex === 0 && (
                <div className='page_section_title'>
                  Détails des observations
                </div>
              )}
              {pageDivs?.map(
                (control, i2: number) =>
                  control.content?.result?.observations?.length > 0 && (
                    <table key={i2} className='observations-table'>
                      <thead>
                        <tr>
                          <td>{control.content.description} :</td>
                          <td>Action à prévoir :</td>
                        </tr>
                      </thead>
                      <tbody>
                        {control.content?.result?.observations?.map(
                          (observation: any, i1: number) => (
                            <tr key={i1}>
                              <td className='column-description'>
                                {observation.description}
                                {observation.image_id && (
                                  <div className='image_container'>
                                    <UploadImage
                                      image_id={observation.image_id}
                                      report_id={report_id}
                                    />
                                  </div>
                                )}
                              </td>
                              <td className='column-action'>
                                {observation.action}
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  )
              )}
            </div>
          </div>
          <Footer totalPages={totalPages} setPageCount={setPageCount} />
        </div>
      ))}
    </>
  );
};

export default TableauObservations;
