import React, { useState, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import jwtDecode from "jwt-decode";
import { InterventionElements } from "./InterventionElement";
import { InterventionControl } from "./InterventionControl";
import { FinishInterventionPopup } from "./FinishInterventionPopup";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { USERS } from "../../../api/USER";

export const InterventionDisplay = ({
  intervention,
  mutations,
  interventionUserId,
  userId,
  hasFinished,
  setHasFinished,
}: any) => {
  const [selectedElementId, setSelectedElementId] = useState("");
  const [selectedControlId, setSelectedControlId] = useState("");
  const [selectedElementName, setSelectedElementName] = useState("");
  const [openFinishPopUp, setOpenFinishPopUp] = useState(false);
  const [controls, setControls] = useState([]);
  useEffect(() => {
    setControls(
      intervention?.interventions
        ?.find((inter: any) => inter._id === interventionUserId)
        ?.intervention?.find((inter: any) => inter._id === selectedElementId)
        ?.controles || []
    );
  }, [intervention, interventionUserId, selectedElementId]);
  const lengthArray = intervention.interventions.length - 1;
  const elements = intervention?.interventions[lengthArray]?.intervention;
  const lastIntervention = intervention?.interventions[lengthArray - 1];
  return (
    <>
      <FinishInterventionPopup
        open={openFinishPopUp}
        currentIntervention={
          intervention?.interventions[intervention.interventions.length - 1]
        }
        onClose={() => setOpenFinishPopUp(false)}
        intervention={intervention}
        mutations={mutations}
        userId={userId}
        hasFinished={hasFinished}
        setHasFinished={setHasFinished}
      />
      {selectedElementId === "" ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: 1,
            justifyContent: "space-evenly",
            backgroundColor: "#F9F9F9",
          }}
        >
          <Button
            variant='outlined'
            sx={{
              cursor: "pointer",
              marginLeft: { xs: "10px", sm: "20px" },
              margin: "8px 0",
              width: { xs: "100px", sm: "150px" },
            }}
            onClick={() => setOpenFinishPopUp(true)}
          >
            <DoneAllIcon
              sx={{
                fontSize: { xs: "12px", sm: "20px" },
                marginRight: { xsm: "5px" },
              }}
            />
            <Typography
              sx={{
                fontSize: { xs: "10px", xm: "12px", xsm: "14px", sm: "20px" },
                fontWeight: "500",
                padding: { xs: "3px 0", xsm: "4px 0" },
              }}
            >
              Terminer
            </Typography>
          </Button>
          <Typography
            variant='h2'
            sx={{
              padding: "0px",
              width: "50%",
              fontSize: { xs: "14px", xsm: "16", xm: "18px", sm: "30px" },
              marginLeft: "20px",
            }}
          >
            Sélection de l'élément
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            width: 1,
            justifyContent: "space-evenly",
            padding: "8px",
            backgroundColor: "#F9F9F9",
            alignItems: "center",
          }}
        >
          <Button
            variant='contained'
            sx={{ fontSize: { xs: "14px", sm: "18px" } }}
            onClick={() => setSelectedElementId("")}
          >
            Retour
          </Button>
          <Button
            variant='outlined'
            sx={{
              cursor: "pointer",
              marginLeft: { xs: "10px", sm: "20px" },
              margin: "8px 0",
            }}
            onClick={() => setOpenFinishPopUp(true)}
          >
            <DoneAllIcon
              sx={{
                fontSize: { xs: "14px", xm: "16px", sm: "20px" },
                marginRight: { xm: "3px", sm: "3px" },
                // marginRight: { xsm: "5px" },
              }}
            />
            <Typography
              sx={{
                fontWeight: "500",
                fontSize: { xs: "10px", xm: "12px", xsm: "14px", sm: "20px" },
                padding: { xs: "3px 0", xsm: "4px 0" },
              }}
            >
              Terminer
            </Typography>
          </Button>
          <Typography
            variant='h2'
            sx={{
              width: "50%",
              textAlign: "center",
              fontSize: { xs: "14px", xsm: "16", xm: "18px", sm: "30px" },
            }}
          >
            Sélection du contrôle
          </Typography>
          {selectedElementName && (
            <Typography
              sx={{
                fontSize: { xs: "14px", xsm: "16", xm: "18px", sm: "30px" },
              }}
            >
              {selectedElementName}
            </Typography>
          )}
        </Box>
      )}

      <Box sx={{ minHeight: "450px", width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {selectedElementId === "" &&
            elements?.map((element: any) => (
              <InterventionElements
                key={element._id}
                mutations={mutations}
                element={element}
                selectedElementId={selectedElementId}
                setSelectedElementName={setSelectedElementName}
                setSelectedElementId={setSelectedElementId}
                setControls={setControls}
              />
            ))}
          {(elements?.length === 0 || !elements) && (
            <Typography
              variant='h3'
              sx={{ textAlign: "center", margin: "20px" }}
            >
              Aucun élément disponible pour ce rapport
            </Typography>
          )}
        </Box>
        {selectedElementId !== "" && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              {controls?.map((control: any) => (
                <InterventionControl
                  intervention={intervention}
                  key={control._id}
                  selectedControlId={selectedControlId}
                  mutations={mutations}
                  control={control}
                  selectedElementId={selectedElementId}
                  setSelectedControlId={setSelectedControlId}
                  setSelectedElementId={setSelectedElementId}
                  interventionUserId={interventionUserId}
                  selectedElementName={selectedElementName}
                  lastIntervention={lastIntervention}
                />
              ))}
              {(controls?.length === 0 || !controls) && (
                <Typography variant='h3' sx={{ textAlign: "center" }}>
                  Aucun élément disponible pour ce rapport
                </Typography>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};
