import dayjs from "dayjs";

interface InterventionData {
  startDate: string;
  endDate?: string;
  finished: string;
  place: string;
  user_id: string;
  _id: string;
}

interface UserData {
  user_id: string;
  name: string;
  family_name: string;
}

interface Props {
  interventions: InterventionData[];
  userData: UserData[];
}

const TableauInterventions: React.FC<Props> = ({ interventions, userData }) => {
  const formatInterventions = () => {
    const formattedInterventions = [];
    let currentIntervention = interventions[0];
    if (interventions.length === 1) {
      return [currentIntervention];
    }
    const slicedInterventions = interventions.slice(1, interventions.length);
    for (const intervention of slicedInterventions) {
      if (currentIntervention.user_id === intervention.user_id) {
        if (
          dayjs(currentIntervention.endDate)?.format("DD/MM/YYYY") ===
            dayjs(intervention.startDate)
              .subtract(1, "day")
              .format("DD/MM/YYYY") ||
          dayjs(currentIntervention.endDate)?.format("DD/MM/YYYY") ===
            dayjs(intervention.startDate).format("DD/MM/YYYY") ||
          dayjs(currentIntervention?.startDate).valueOf() <=
            dayjs(intervention.startDate).valueOf()
        ) {
          if (
            intervention?.endDate &&
            dayjs(currentIntervention?.endDate).valueOf() <=
              dayjs(intervention?.endDate).valueOf()
          ) {
            currentIntervention.endDate = intervention?.endDate;
          }
        } else if (
          !intervention?.endDate &&
          dayjs(currentIntervention?.endDate).valueOf() <=
            dayjs(intervention?.startDate).valueOf()
        ) {
          currentIntervention.endDate = intervention?.startDate;
        }
      } else {
        formattedInterventions.push(currentIntervention);
        currentIntervention = intervention;
      }
    }
    formattedInterventions.push(currentIntervention);
    return formattedInterventions;
  };
  const getFullName = (userId: string): string => {
    const user = userData.find((data) => data.user_id === userId);
    if (user) {
      return `${user.name} ${user.family_name}`;
    }
    return "";
  };

  const getName = (userId: string): string => {
    const user = userData.find((data) => data.user_id === userId);
    if (user) {
      return `${user.name}`;
    }
    return "";
  };

  const formatDate = (startDate: string, endDate?: string): string => {
    if (endDate) {
      if (
        dayjs(startDate).format("MM/YYYY") !== dayjs(endDate).format("MM/YYYY")
      ) {
        return `${dayjs(startDate).format("DD/MM/YYYY")} au ${dayjs(
          endDate
        ).format("DD/MM/YYYY")}`;
      }
      if (
        dayjs(startDate).format("DD/MM/YYYY") ===
        dayjs(endDate).format("DD/MM/YYYY")
      ) {
        return dayjs(startDate).format("DD/MM/YYYY");
      }
      return `${dayjs(startDate).format("DD")} au ${dayjs(endDate).format(
        "DD/MM/YYYY"
      )}`;
    }
    return dayjs(startDate).format("DD/MM/YYYY");
  };

  return (
    <table className='interventions-table'>
      <thead>
        <tr>
          <th className='column-date'>Date</th>
          <th className='column-intervenant'>Intervenant</th>
          <th className='column-num-carte'>N° de carte</th>
          <th className='column-lieu'>Lieu</th>
          <th className='column-sign'>Signature</th>
        </tr>
      </thead>
      <tbody>
        {formatInterventions().map((intervention, index) => (
          <tr key={index}>
            <td className='cell-date'>
              {formatDate(intervention.startDate, intervention.endDate)}
            </td>
            <td className='cell-intervenant'>
              {getFullName(intervention.user_id)}
            </td>
            <td className='cell-num-carte'>{intervention._id.slice(0, 5)}</td>
            <td className='cell-lieu'>{intervention.place}</td>
            <td className='cell-sign'>
              {intervention.finished === "true" &&
                getName(intervention.user_id)}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TableauInterventions;
