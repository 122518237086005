import React, { useState } from "react";
import {
  Box,
  Divider,
  Stack,
  Button,
  TextField,
  Link,
  Dialog,
  DialogTitle,
  DialogActions,
  Typography,
} from "@mui/material";
import { ADMIN_USER } from "../../../api/ADMIN_USER";
import { ADMIN } from "../../../api/ADMIN";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  DataGridPro,
  GridActionsCellItem,
  GridRenderCellParams,
  GridToolbarColumnsButton,
  GridToolbarContainer,
} from "@mui/x-data-grid-pro";
import DeleteIcon from "@mui/icons-material/Delete";
import UserManagementAdd from "./UserGrid_Add";
import UserManagementEdit from "./UserGrid_Edit";
import FullSizeLoading from "../../ui/fullSizeLoading";
import EngineeringIcon from "@mui/icons-material/Engineering";
import DevicesSelectorPopup from "./DevicesSelectorPopUp";
import { ADMIN_SETTINGS } from "../../../api/SETTINGS";
const CustomToolbar: React.FunctionComponent<{
  setFilterButtonEl: React.Dispatch<
    React.SetStateAction<HTMLButtonElement | null>
  >;
  setSearchInputValue: React.Dispatch<React.SetStateAction<string>>;
  searchInputValue: React.Dispatch<
    React.SetStateAction<HTMLButtonElement | null>
  >;
  mutations: any;
  data: any;
}> = ({
  setFilterButtonEl,
  setSearchInputValue,
  searchInputValue,
  data,
  mutations,
}) => {
  //-------- Grid Header --------
  const SearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(event.target.value);
  };
  return (
    <GridToolbarContainer
      sx={{
        padding: `0px`,
      }}
    >
      <Stack
        flex='1'
        sx={{
          backgroundColor: (theme: any) => theme.palette.customGrey.grey1,
          padding: `0px 10px`,
        }}
      >
        <Stack
          sx={{
            height: `60px`,
            "& .MuiFormLabel-root:not(.Mui-focused)": {
              color: "grey !important",
            },
          }}
          direction='row'
          alignItems='center'
          spacing={2}
        >
          <TextField
            size='small'
            value={searchInputValue}
            onChange={SearchInputChange}
            label='Recherche'
            variant='outlined'
          />
          <UserManagementAdd
            API_USER={ADMIN_USER}
            mutations={mutations}
            data={data}
          />
          <GridToolbarColumnsButton ref={setFilterButtonEl} />
        </Stack>
        <Divider light />
      </Stack>
    </GridToolbarContainer>
  );
};

const UserManagement = ({ userType }: any) => {
  const [allDevices, setAllDevices] = useState([]);
  const [searchInputValue, setSearchInputValue] = React.useState(``);
  const [hasChangedDevices, setHasChangedDevices] = useState(false);
  const [openDevicesPopup, setOpenDevicesPopup] = React.useState<{
    status: boolean;
    row?: any;
  }>({ status: false });

  const [filterColumnButton, setFilterColumnButton] =
    React.useState<HTMLButtonElement | null>(null);

  // let API = ADMIN_SETTINGS;
  // let API_USER = ADMIN_USER;

  useQuery("getDevicesSettings", async () => {
    await ADMIN_SETTINGS.GET_APPAREILS_IN_SETTINGS().then((res) => {
      if (res) {
        setAllDevices(res.data);
      }
    });
  });
  const fetchUserList = async () => {
    const response = await ADMIN_USER.GET_USERS();
    return response.data;
  };

  const queryClient = useQueryClient();
  const { isLoading, data } = useQuery("UserList", fetchUserList);

  const mutations = {
    delete: useMutation(ADMIN_USER.DELETE_USER, {
      onSuccess: (resp) => {
        queryClient.setQueryData("UserList", (oldData: any) =>
          oldData.filter((model: any) => model._id !== resp.data._id)
        );
      },
    }),
    create: useMutation((newUser) => ADMIN_USER.POST_REGISTER(newUser), {
      onSuccess: (resp) => {
        queryClient.setQueryData("UserList", (oldData: any) => [
          ...oldData,
          resp.data,
        ]);
      },
    }),
    update: useMutation(
      ({ id, updates }: any) => ADMIN_USER.PUT_USER(id, updates),
      {
        onSuccess: (data, variables) => {
          queryClient.setQueryData("UserList", (oldData: any) =>
            oldData.map((user: any) =>
              user.user_id === variables.id ? data.data : user
            )
          );
        },
      }
    ),
    fetchPutDevices: useMutation(
      ({ agentId, agent }: any) => ADMIN_USER.PUT_USER(agentId, agent),
      {
        onSuccess: (data, variables) => {
          if (data.message === "ok") {
            queryClient.setQueryData("UserList", (oldData: any) => {
              const index = oldData.findIndex(
                (agent: any) => agent.user_id === variables.agentId
              );
              oldData[parseInt(index)] = data.data;
              setHasChangedDevices(true);
              setOpenDevicesPopup({ ...openDevicesPopup, status: false });
              return oldData;
            });
          }
        },
      }
    ),
  };

  const handleDeleteUser = async (id: string) => {
    await mutations.delete.mutateAsync(id);
    setOpenDeletePopup({ status: false });
  };

  //-------- Delete Popup --------
  const [OpenDeletePopup, setOpenDeletePopup] = React.useState<{
    status: boolean;
    row?: any;
  }>({ status: false });

  //-------- Grid Filters --------

  const SearchBarFilter = (rows: any) => {
    return rows
      .map((row: any) => {
        row.id = row._id;
        row.full_name = row.name + " " + row.family_name;
        return row;
      })
      .filter(
        (row: any) =>
          !searchInputValue ||
          row.name?.toLowerCase().includes(searchInputValue.toLowerCase()) ||
          row.family_name
            ?.toLowerCase()
            .includes(searchInputValue.toLowerCase()) ||
          row.email?.toLowerCase().includes(searchInputValue.toLowerCase())
      );
  };

  //-------- Grid Columns --------
  const columns = [
    //show by default
    {
      field: `full_name`,
      headerName: `Nom/Prenom`,
      width: 250,
    },
    {
      field: `email`,
      headerName: `Email`,
      minWidth: 250,
      renderCell: (cellValues: GridRenderCellParams) => {
        return (
          <Link href={`mailto:${cellValues.value}`} color='inherit'>
            {cellValues.value}
          </Link>
        );
      },
    },
    {
      field: `CardNumber`,
      headerName: `Numéro de carte`,
      minWidth: 150,
    },
    // fixed right
    {
      field: `actions`,
      type: `actions`,
      headerName: `Actions`,
      width: 120,
      resizable: false,
      getActions: (e: { row?: any }) => [
        <GridActionsCellItem
          onClick={() => setOpenDevicesPopup({ row: e.row, status: true })}
          sx={{
            backgroundColor: "secondary.main",
          }}
          icon={
            <EngineeringIcon
              sx={{
                color: "white",
              }}
            />
          }
          label='More'
          key='grid-actions-cell-item-2'
        />,
        <UserManagementEdit
          API={ADMIN}
          API_USER={ADMIN_USER}
          key='user-grid-edit'
          rowInfos={e}
          mutations={mutations}
          data={data}
        />,
        <GridActionsCellItem
          onClick={() => setOpenDeletePopup({ row: e.row, status: true })}
          icon={<DeleteIcon />}
          label='Delete'
          key='grid-actions-cell-item-1'
        />,
      ],
    },
  ];

  const DeleteUserPopup = () => {
    return (
      <Dialog
        open={OpenDeletePopup?.status}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle
          sx={{
            overflow: "hidden",
            fontSize: { xs: "17px", sm: "24px" },
            textOverflow: "ellipsis",
          }}
        >
          Voulez vous vraiment supprimer {OpenDeletePopup?.row?.full_name} ?
        </DialogTitle>
        <DialogActions
          sx={{
            padding: `10px 20px`,
            backgroundColor: "#f9f9f9",
          }}
        >
          <Button
            sx={{
              color: `text.disabled`,
            }}
            onClick={() => {
              setOpenDeletePopup({ status: false });
            }}
          >
            Annuler
          </Button>
          <Button
            sx={{
              color: `#fff`,
            }}
            variant='contained'
            disableElevation
            onClick={() => {
              OpenDeletePopup?.row?.id &&
                handleDeleteUser(OpenDeletePopup.row.user_id);
            }}
            autoFocus
          >
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  return (
    <Box
      className='wrapper_noPadding card'
      sx={{ height: "588px", display: "flex", alignItems: "center", gap: 2 }}
    >
      {openDevicesPopup.status && (
        <DevicesSelectorPopup
          mutations={mutations}
          open={openDevicesPopup.status}
          agent={openDevicesPopup?.row}
          setOpenDevicesPopup={setOpenDevicesPopup}
          hasChangedDevices={hasChangedDevices}
          allDevices={allDevices}
        />
      )}
      <Box
        sx={{
          height: "100%",
          width: "100%",
        }}
      >
        {!isLoading && data ? (
          <DataGridPro
            autoPageSize
            pagination
            sx={{
              '.MuiDataGrid-booleanCell[data-value="true"] ': {
                color: `#8D919A`,
              },
              borderRadius: `20px`,
              overflow: "hidden",
            }}
            rows={(data[0] && SearchBarFilter(data)) || []}
            columns={columns}
            disableColumnMenu
            localeText={{
              toolbarColumns: "Colonnes",
              columnsPanelTextFieldLabel: "Chercher une colonne",
              columnsPanelTextFieldPlaceholder: "Nom de la colonne",
              columnsPanelShowAllButton: "Afficher tout",
              columnsPanelHideAllButton: "Masquer tout",
            }}
            components={{
              Toolbar: CustomToolbar,
              NoRowsOverlay: () => (
                <Stack
                  height='100%'
                  alignItems='center'
                  justifyContent='center'
                >
                  Aucune ligne à afficher
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack
                  height='100%'
                  alignItems='center'
                  justifyContent='center'
                >
                  Aucun résultat
                </Stack>
              ),
            }}
            componentsProps={{
              panel: {
                anchorEl: filterColumnButton,
              },
              toolbar: {
                setFilterButtonEl: setFilterColumnButton,
                setSearchInputValue,
                searchInputValue,
                data,
                mutations,
                API_USER: ADMIN_USER,
              },
            }}
            initialState={{ pinnedColumns: { right: [`actions`] } }}
            // disableSelectionOnClick
          />
        ) : (
          <FullSizeLoading />
        )}
        <DeleteUserPopup />
      </Box>
    </Box>
  );
};

export default UserManagement;
