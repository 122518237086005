import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { ADMIN } from "../../../api/ADMIN";
import { USERS } from "../../../api/USER";
import CancelIcon from "@mui/icons-material/Cancel";
export const ObservationPopup = ({
  openObservationPopUp,
  control,
  setOpenObservationPopUp,
  observations,
  setObservations,
  intervention,
  mutations,
  setImageIds,
  setHasDeletedImage,
  hasDeletedImage,
  userType,
}: any) => {
  const [imageId, setImageId] = useState<any>(null);
  const [imageName, setImageName] = useState("");
  const [imagePath, setImagePath] = useState<any>(null);
  const [imageFile, setImageFile] = useState<any>({});
  const [hasSetImage, setHasSetImage] = useState(false);
  const [observation, setObservation] = useState({
    image_id: "",
    description: "",
    action: "",
  });
  let API: any = ADMIN;
  if (userType === "USER") {
    API = USERS;
  }

  const downloadImage = useCallback(async () => {
    const response: any = await API.DOWNLOAD_OBSERVATION_IMG(
      intervention._id,
      imageId
    );
    if (!response) {
      setHasSetImage(false);
      return null;
    }
    const url = window.URL.createObjectURL(new Blob([response.data]));
    setHasSetImage(true);

    return url;
  }, [intervention._id, imageId, API]);

  useEffect(() => {
    if (imageId !== null && imageId !== undefined && imageId !== "") {
      const url = downloadImage();
      url.then((res: any) => {
        setImagePath(res);
      });
    }
  }, [intervention._id, imageId, downloadImage]);
  useEffect(() => {
    setObservation(
      openObservationPopUp.key >= 0
        ? observations[openObservationPopUp.key]
        : {
            image_id: "",
            description: "",
            action: "",
          }
    );
    if (openObservationPopUp.key !== null) {
      setImageId(observations[openObservationPopUp?.key]?.image_id);
    }
  }, [observations, openObservationPopUp, imageId, intervention._id]);

  const handleSubmit = async (e: any, key: number) => {
    let updatedObservation: any;
    if (imageName && imageFile.constructor !== Object) {
      const response = await mutations.uploadImage.mutateAsync({
        reportId: intervention._id,
        imageFile: imageFile,
      });
      if (response.data) {
        setImageIds((oldArray: any) => [...oldArray, response.data]);
        setObservation({ ...observation, image_id: response.data });
        updatedObservation = { ...observation, image_id: response.data };
      }
    }
    e.preventDefault();

    if (key !== null) {
      if (updatedObservation) {
        observations[key] = updatedObservation;
      } else {
        observations[key] = observation;
      }
      setOpenObservationPopUp({ ...openObservationPopUp, status: false });
    } else {
      if (updatedObservation) {
        setObservations([...observations, updatedObservation]);
      } else {
        setObservations([...observations, observation]);
      }
      setOpenObservationPopUp({ ...openObservationPopUp, status: false });
    }
    setImageName("");
    setImageFile({});
    setImagePath(null);
    setHasSetImage(false);
    setImageId(null);
  };

  return (
    <Dialog
      open={openObservationPopUp.status}
      onClose={() => {
        setOpenObservationPopUp({
          ...openObservationPopUp,
          status: false,
        });
        setObservation({
          image_id: "",
          description: "",
          action: "",
        });
        setImageFile({});
        setImageName("");
        setImagePath(null);
        setHasSetImage(false);
        setImageId(null);
      }}
      maxWidth={"sm"}
      fullWidth
    >
      <Box
        component='form'
        onSubmit={async (e) => {
          e.preventDefault();
          handleSubmit(e, openObservationPopUp.key);
        }}
      >
        <DialogTitle
          sx={{
            width: 1,
            backgroundColor: "customGrey.grey1",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography variant='h2'>Ajout de l'observation</Typography>
          </Box>
          <CloseIcon
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setOpenObservationPopUp({
                ...openObservationPopUp,
                status: false,
              });
              setObservation({
                image_id: "",
                description: "",
                action: "",
              });
            }}
          />
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              marginTop: "20px",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Box sx={{ position: "relative" }}>
                {imagePath && (
                  <img src={imagePath} alt='test' className='img-overview' />
                )}
                {imageFile.constructor !== Object && (
                  <img
                    className='img-overview'
                    alt="Reçu de l'utilisateur"
                    src={window.URL.createObjectURL(imageFile)}
                  />
                )}
                {hasSetImage && (
                  <IconButton
                    onClick={async () => {
                      setImageFile({});
                      setImageName("");
                      setImagePath(null);
                      setHasSetImage(false);
                      if (imageId) {
                        await mutations.deleteImage.mutateAsync({
                          reportId: intervention._id,
                          imageId: imageId,
                        });
                      }

                      setImageId(null);

                      setObservations(
                        observations.map((obs: any) =>
                          obs.image_id === observation.image_id
                            ? { ...obs, image_id: "" }
                            : obs
                        )
                      );
                      setObservation({ ...observation, image_id: "" });
                    }}
                    sx={{ position: "absolute", right: "0px", top: "0px" }}
                  >
                    <CancelIcon />
                  </IconButton>
                )}
              </Box>
              {!hasSetImage && (
                <Box>
                  <Typography>Ajouter une image</Typography>
                  <Button component='label'>
                    <input
                      type='file'
                      onChange={(e) => {
                        const obj: any = e.target.files;
                        setImageFile(obj[0]);
                        setImageName(obj[0].name);
                        setHasSetImage(true);
                      }}
                      hidden
                    />
                    <Box>
                      <AddPhotoAlternateIcon
                        sx={{ fontSize: "60px", cursor: "pointer" }}
                      />
                    </Box>
                  </Button>
                </Box>
              )}
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {imageName !== "" && (
                  <Typography sx={{ fontSize: "17px" }}>
                    Image :{" "}
                    {imageName.length > 15
                      ? imageName.slice(0, 15) + "..."
                      : imageName}
                  </Typography>
                )}
              </Box>
            </Box>
            <Box>
              <TextField
                multiline
                minRows='2'
                required
                onChange={(e) =>
                  setObservation({
                    ...observation,
                    description: e.target.value,
                  })
                }
                value={observation?.description || ""}
                label="Ajout d'un commentaire"
                sx={{ width: 1, margin: "20px 0" }}
              />
            </Box>
            <Box>
              <TextField
                label="Ajout d'une action"
                multiline
                onChange={(e) =>
                  setObservation({ ...observation, action: e.target.value })
                }
                value={observation?.action || ""}
                minRows='2'
                sx={{ width: 1, margin: "20px 0" }}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ width: 1, backgroundColor: "customGrey.grey1" }}>
          <Button type='submit' variant='contained'>
            {openObservationPopUp.key === null ? "Ajouter" : "Modifier"}
          </Button>
          <Button
            onClick={() => {
              setOpenObservationPopUp({
                ...openObservationPopUp,
                status: false,
              });
              setObservation({
                image_id: "",
                description: "",
                action: "",
              });
              setImageFile({});
              setImageName("");
              setImagePath(null);
              setHasSetImage(false);
              setImageId(null);
            }}
          >
            Annuler
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
