import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

const DevicesSelectorPopup = ({
  open,
  agent,
  setOpenDevicesPopup,
  allDevices,
  mutations,
  hasChangedDevices,
}: any) => {
  const [hasChanged, setHasChanged] = useState(false);
  const [selectedDevices, setSelectedDevices] = useState<any[]>(
    agent?.Appareils || []
  );
  const putDevices = (device: any) => {
    const devices = selectedDevices || [];
    const updatedDevices = [...devices, device];
    setSelectedDevices(updatedDevices);
    setHasChanged(true);
  };

  const deleteDevice = (deviceId: string) => {
    const updatedDevices = selectedDevices?.filter(
      (selectedDevice) => selectedDevice !== deviceId
    );

    setSelectedDevices(updatedDevices);
  };

  const handleOnChecked = async (deviceId: string, e: any) => {
    if (e.target.checked) {
      await putDevices(deviceId);
    } else {
      await deleteDevice(deviceId);
    }
    setHasChanged(true);
  };
  return (
    <Dialog
      open={open}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='sm'
      fullWidth
    >
      <DialogTitle
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          backgroundColor: "customGrey.grey1",
        }}
      >
        Liste des appareils de {agent?.family_name + " " + agent?.name}
      </DialogTitle>
      <DialogContent sx={{ p: "0 20px" }}>
        <FormControl sx={{ margin: "20px 0" }}>
          {allDevices?.map((device: any) => {
            return (
              <FormControlLabel
                key={device.id}
                control={
                  <Checkbox
                    checked={selectedDevices?.includes(device.id)}
                    onChange={(e) => handleOnChecked(device.id, e)}
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 35 } }}
                  />
                }
                label={device.name}
              />
            );
          })}
          {allDevices.length === 0 && (
            <Typography variant='h3'>
              Aucun appareil disponible pour cet agent
            </Typography>
          )}
        </FormControl>
      </DialogContent>
      <DialogActions
        sx={{
          padding: `10px 20px`,
          backgroundColor: "#f9f9f9",
        }}
      >
        {/* {hasChangedDevices && (
          <Typography variant='h3' sx={{ color: "green" }}>
            Les informations ont bien été enregistrées
          </Typography>
        )} */}
        <Button
          sx={{
            color: `text.disabled`,
          }}
          onClick={() => {
            setOpenDevicesPopup(false);
          }}
        >
          Annuler
        </Button>
        <Button
          variant={"contained"}
          type='submit'
          onClick={async () =>
            await mutations.fetchPutDevices.mutateAsync({
              agentId: agent.user_id,
              agent: { ...agent, Appareils: selectedDevices },
            })
          }
          disabled={!hasChanged}
          disableElevation
          autoFocus
        >
          Enregister
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DevicesSelectorPopup;
