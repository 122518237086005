import { Footer, Header, ItemComponent } from "./ExportRapportComposants";
import { useState, useEffect } from "react";

const findObservationInfo = (controlId: string, controlChunk: any) => {
  const control = controlChunk.find(
    (control: any) => control._id === controlId
  );
  const conforme = control.result?.conforme;
  const observations = control.result?.observations;
  if (conforme === "true") {
    return observations?.length > 0
      ? `CONFORME, ${observations?.length} observations`
      : `CONFORME`;
  } else if (conforme === "false" && observations?.length > 0) {
    return `NON CONFORME, ${observations?.length} observations`;
  }
};

export const PreRenderRecapControl = ({ model, controlChunk }: any) => {
  return (
    <table className='recap-table'>
      <thead>
        <tr>
          <th>Elément</th>
          <th>Détail du contrôle</th>
          <th>Observations</th>
        </tr>
      </thead>
      <tbody>
        {model.map((element: any) =>
          element.controles?.map((control: any, index: any) => (
            <tr key={control._id} id={"PreRenderRecapControl" + control._id}>
              {index === 0 && (
                <td rowSpan={element.controles.length}>{element.name}</td>
              )}
              <td>{control.description}</td>
              <td>{findObservationInfo(control._id, controlChunk)}</td>
            </tr>
          ))
        )}
      </tbody>
    </table>
  );
};

export const RecapControl = ({
  model,
  controlChunk,
  totalPages,
  setPageCount,
  controleReason,
  machine,
  affaire_num,
  reportNumber,
  lexicon,
}: any) => {
  type ControlData = { height: number; content: any };
  type PageContent = ControlData[];

  const [controlDataList, setControlDataList] = useState<ControlData[]>([]);
  const [pagesContent, setPagesContent] = useState<PageContent[]>([]);

  const toggleElementVisibility = (
    elementId: string,
    displayValue: "block" | "none"
  ) => {
    const element = document.getElementById(elementId);
    element?.style.setProperty("display", displayValue);
  };
  const computeControlHeight = (controlId: string): number => {
    const controlElement = document.getElementById(controlId);
    return controlElement ? controlElement.clientHeight : 0;
  };

  useEffect(() => {
    toggleElementVisibility("preRenderRecapControl", "block");
    const controlData = controlChunk.map((control: any) => ({
      content: control,
      height: computeControlHeight("PreRenderRecapControl" + control._id),
    }));

    setControlDataList(controlData);
  }, [controlChunk]);

  useEffect(() => {
    const maxPageHeight = 1200;
    const newPagesContent: PageContent[] = [];
    let currentPageContent: ControlData[] = [];
    let currentPageHeight = 0;

    controlDataList.forEach((controlData) => {
      if (currentPageHeight + controlData.height <= maxPageHeight) {
        currentPageContent.push(controlData);
        currentPageHeight += controlData.height;
      } else {
        newPagesContent.push(currentPageContent);
        currentPageContent = [controlData];
        currentPageHeight = controlData.height;
      }
    });

    if (currentPageContent.length > 0) {
      newPagesContent.push(currentPageContent);
    }

    setPagesContent(newPagesContent);
    toggleElementVisibility("preRenderRecapControl", "none");
  }, [controlDataList]);

  const formatElements = (data: any) => {
    const flatData = data.flat();
    const elementNames = flatData.map((item: any) => item.content.name);
    const uniqueElementNames = Array.from(new Set(elementNames));
    if (uniqueElementNames.length === 1) {
      return uniqueElementNames[0];
    }
    const formattedElements =
      uniqueElementNames.slice(0, -1).join(", ") +
      " et " +
      uniqueElementNames.slice(-1);

    return formattedElements;
  };
  return (
    <>
      {pagesContent.map((pageDivs, pageIndex) => (
        <div key={pageIndex} className='page'>
          <Header
            machine={machine}
            affaire_num={affaire_num}
            report_num={reportNumber}
          />
          <div className='page_content'>
            <div className='page_section'>
              {pageIndex === 0 && (
                <div>
                  <div className='page_section_title'>
                    Récapitulatif des points contrôlés
                  </div>
                  <ItemComponent
                    title='Objet du contrôle'
                    content={controleReason}
                  />
                  {lexicon && <p style={{ marginBottom: "7px" }}>{lexicon}</p>}
                  <ItemComponent
                    title={"Sous-ensembles examinés"}
                    content={`${formatElements(
                      pagesContent
                    )}, selon le tableau ci-dessous`}
                  />
                </div>
              )}
              <table className='recap-table'>
                {pageIndex === 0 && (
                  <thead>
                    <tr>
                      <th>Elément</th>
                      <th>Détail du contrôle</th>
                      <th>Observations</th>
                    </tr>
                  </thead>
                )}
                <tbody>
                  {pageDivs?.map((control: any, i2: number) => {
                    return (
                      <tr key={i2}>
                        {(i2 === 0 ||
                          control.content.name !==
                            pageDivs[i2 - 1].content.name) && (
                          <td
                            rowSpan={
                              pageDivs.filter(
                                (controled: any) =>
                                  control.content.name ===
                                  controled.content.name
                              ).length
                            }
                          >
                            {control.content.name}
                          </td>
                        )}
                        <td>{control.content.description}</td>
                        <td>
                          {findObservationInfo(
                            control.content._id,
                            controlChunk
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <Footer totalPages={totalPages} setPageCount={setPageCount} />
        </div>
      ))}
    </>
  );
};

export default RecapControl;
