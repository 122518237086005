import { Box, Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import React from "react";

export const PopUpDelete = ({
  open,
  toggleClose,
  mutations,
  elementId,
  headerText,
  mutationsFunction,
}: any) => {
  return (
    <Dialog open={open} onClose={toggleClose}>
      <DialogTitle>{headerText}</DialogTitle>
      <DialogActions sx={{backgroundColor:'customGrey.grey1'}}>
        <Box sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
          <Button onClick={toggleClose}>Annuler</Button>
          <Button variant='contained' onClick={() => mutationsFunction()}>
            Supprimer
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
