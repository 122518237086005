import React from "react";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import { Avatar, Button, TextField, Box, Typography, Container } from "@mui/material";
import { CREDENTIALS } from '../../api/CREDENTIALS';
import ErrorMessage from '../ui/errorMessage';
export default function SignIn() {
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    CREDENTIALS.GET_LOGIN({
      email: data.get('email'),
      password: data.get('password'),
    }).then((res) => {
      res && console.log(JSON.parse(res).accessToken)

      if(res){
        JSON.parse(res).accessToken && localStorage.setItem('token', JSON.parse(res).accessToken)
        window.location.replace('/');
      }else{
        setErrorMessage(`Email ou mot de passe incorrect`)
      }
    });
  };


  React.useEffect(() => {
    localStorage.getItem('token') && window.location.replace('/');
  }, []);



  return (
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Connexion
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            {errorMessage && <ErrorMessage message={errorMessage}/>}
            <TextField
              margin="dense"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="dense"
              required
              fullWidth
              name="password"
              label="Mot de passe"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, color: 'white' }}
            >
              Connexion
            </Button>
            {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Pas de compte ? Inscrivez-vous"}
                </Link>
              </Grid>
            </Grid> */}
          </Box>
        </Box>
      </Container>
  );
}