import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { USERS } from "../../../api/USER";
import { InterventionNotCreated } from "./InterventionNotCreated";
import { InterventionDisplay } from "./InterventionDisplay";
import jwtDecode from "jwt-decode";
import DoneIcon from "@mui/icons-material/Done";
export const Intervention = () => {
  const [selectedReport, setSelectedReport] = useState("");
  const [reports, setReports] = useState([]);
  const [report, setReport] = useState<any>([]);
  const [hasFinished, setHasFinished] = useState(false);

  const [isLoadingReports, setIsLoadingReports] = useState(true);
  const storedToken = localStorage.getItem("token");
  const userId = storedToken && jwtDecode<any>(storedToken)?.user?.id;

  const getReports = async () => {
    const responseGetReports = await USERS.GET_REPORTS();
    return responseGetReports?.data;
  };

  const fetchReports = async () => {
    const responseGetReport = await USERS.GET_MY_REPORT_IN_PROGRESS();
    console.log("resposne : ", responseGetReport);
    if (!responseGetReport?.data) {
      setReports(await getReports());
      setIsLoadingReports(false);
    }
    setIsLoadingReports(false);
    return responseGetReport.data;
  };
  const queryClient = useQueryClient();
  const { isLoading, data: intervention } = useQuery(
    "fetchReportsAgent",
    fetchReports
  );

  const lengthArrayInterventions = intervention?.interventions.length - 1;
  const getCurrentIntervention =
    intervention?.interventions[lengthArrayInterventions];

  const checkIntervention = (intervention: any) => {
    const interventionUser =
      intervention?.interventions[lengthArrayInterventions];
    if (
      interventionUser &&
      interventionUser?.user_id === userId &&
      interventionUser?.finished === "false"
    ) {
      return interventionUser;
    } else {
      return null;
    }
  };
  const mutations = {
    createIntervention: useMutation(
      ({ reportId, place }: any) =>
        USERS.POST_INTERVENTION_IN_REPORT(reportId, place),
      {
        onSuccess(data, variables) {
          console.log("data & variable : ", data, variables);
          if (data) {
            queryClient.setQueryData("fetchReportsAgent", (oldData: any) => {
              return data.data;
            });
          }
        },
      }
    ),
    putControl: useMutation(
      ({
        reportId,
        interventionId,
        elementId,
        controlId,
        controlResult,
      }: any) =>
        USERS.PUT_CONTROLE_RESULT_IN_REPORT(
          reportId,
          interventionId,
          elementId,
          controlId,
          controlResult
        ),
      {
        onSuccess: (data: any, variables: any) => {
          if (data) {
            queryClient.setQueryData("fetchReportsAgent", (oldData: any) => {
              return data.data;
            });
          }
        },
      }
    ),
    uploadImage: useMutation(
      ({ reportId, imageFile }: any) =>
        USERS.UPLOAD_OBSERVATION_IMG(reportId, imageFile),
      {
        onSuccess: (data: any, variables: any) => {
          if (data.data) {
            return data.data;
          }
        },
      }
    ),
    deleteImage: useMutation(
      ({ reportId, imageId }: any) =>
        USERS.DELETE_OBSERVATION_IMG(reportId, imageId),
      {
        onSuccess: (data: any, variables: any) => {},
      }
    ),
    putIntervention: useMutation(
      ({ reportId, report }: any) => USERS.PUT_REPORT(reportId, report),
      {
        onSuccess: (data: any, variables: any) => {
          console.log("data & variables : ", variables);

          queryClient.setQueryData("fetchReportsAgent", (oldData: any) => {
            return checkIntervention(data.data);
          });
        },
      }
    ),
  };
  if (isLoading || isLoadingReports)
    return <Typography>Chargement...</Typography>;
  return (
    <>
      {hasFinished && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: "14px",
            fontSize: "25px",
            margin: "10px 20px",
          }}
        >
          <Typography variant='h3' sx={{ color: "customGreen.green1" }}>
            Votre intervention a bien été enregistrée
          </Typography>
          <DoneIcon
            sx={{
              color: "customGreen.green1",
              marginBottom: "6px",
              fontSize: "25px",
            }}
          />
        </Box>
      )}
      <Box
        className='wrapper_noPadding card'
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Box
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {checkIntervention(intervention) === null ? (
            <InterventionNotCreated
              report={report}
              setReport={setReport}
              selectedReport={selectedReport}
              setSelectedReport={setSelectedReport}
              reports={reports}
              mutations={mutations}
              intervention={intervention}
            />
          ) : (
            <InterventionDisplay
              setHasFinished={setHasFinished}
              hasFinished={hasFinished}
              userId={userId}
              mutations={mutations}
              intervention={intervention}
              interventionUserId={getCurrentIntervention?._id}
            />
          )}
        </Box>
      </Box>
    </>
  );
};
