import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { InterventionPopup } from "./InterventionPopup";
import { USERS } from "../../../api/USER";
export const InterventionControl = ({
  control,
  selectedElementId,
  setSelectedControlId,
  selectedControlId,
  mutations,
  intervention,
  interventionUserId,
  report,
  selectedElementName,
  lastIntervention,
}: any) => {
  const [openControlPopUp, setOpenControlPopUp] = useState(false);

  const lastInterventionsObservations = lastIntervention?.intervention
    ?.find((myElement: any) => myElement._id === selectedElementId)
    .controles.find((myControl: any) => myControl._id === selectedControlId)
    ?.result?.observations;
  console.log(
    "last interventions observations : ",
    intervention,
    intervention.interventions[intervention.interventions.length - 2],
    lastIntervention,
    lastInterventionsObservations
  );
  return (
    <>
      <InterventionPopup
        intervention={intervention}
        selectedElementId={selectedElementId}
        open={openControlPopUp}
        control={control}
        mutations={mutations}
        setOpenControlPopUp={setOpenControlPopUp}
        interventionUserId={interventionUserId}
        lastInterventionsObservations={lastInterventionsObservations}
        onClose={() => setOpenControlPopUp(false)}
        mode={"INTERVENTION"}
        userType={"USER"}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box
          key={control._id}
          sx={{
            borderRadius: "20px",
            padding: "20px",
            cursor: "pointer",
            maxWidth: { xs: "350px", xm: "450px" },
            width: "100%",
            display: "flex",
            alignItems: "center",
            height: "110px",
          }}
          onClick={() => {
            setOpenControlPopUp(true);
            setSelectedControlId(control._id);
          }}
        >
          <Box
            sx={{
              backgroundColor:
                control.result?.conforme === undefined &&
                control?.result?.observations &&
                control?.result?.observations.length !== 0
                  ? "customOrange.orange1"
                  : control?.result?.conforme === "true"
                  ? "customGreen.green1"
                  : control?.result?.conforme
                  ? "customRed.red1"
                  : "primary.main",
              minWidth: "25px",
              borderTopLeftRadius: "10px",
              borderBottomLeftRadius: "10px",
              height: 1,
            }}
          ></Box>
          <Box
            sx={{
              height: 1,
              backgroundColor: "customGrey.grey1",
              width: 1,
              borderBottomRightRadius: "10px",
              borderTopRightRadius: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                maxWidth: "300px",
                overflow: "hidden",
                color: "black",
                fontWeight: 500,
              }}
            >
              {control?.description.length > 30
                ? control?.description.slice(0, 30) + "..."
                : control?.description}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};
