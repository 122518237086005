import React, { useState, useEffect, useContext } from "react";
import Credential from "../components/login/credential";
import SignIn from "../components/login/SignIn";
import { Box, Typography } from "@mui/material";

function Login() {
  // create useState
  const [option, setOption] = useState(true);

  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      sx={{ height: "100%" }}
    >
      <Box
        sx={{
          backgroundColor: "white",
          borderRadius: "20px",
          overflow: "hidden",
          margin: "40px 20px",
          boxShadow: "rgba(0, 0, 0, 0.2) 0px 8px 24px",
        }}
      >
        <Box display={"flex"}>
          <Box
            sx={{
              width: "100%",
              textAlign: "center",
              borderRadius: "0 0 20px 0",
              padding: "20px 0",
              backgroundColor: !option ? "primary.main" : "transparant",
              cursor: !option ? "pointer" : "auto",
            }}
            onClick={() => {
              setOption(true);
            }}
          >
            <Typography
              component='h1'
              variant='h3'
              color={!option ? "white" : "auto"}
            >
              Connexion
            </Typography>
          </Box>
        </Box>
        <SignIn />
      </Box>
    </Box>
  );
}

export default Login;
