import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
} from "@mui/material";
export const LexiconPopUp = ({
  open,
  lexiconText,
  model,
  toggle,
  mutations,
}: any) => {
  const [lexicon, setLexicon] = useState<String>(lexiconText || "");
  return (
    <Dialog open={open} onClose={toggle} fullWidth maxWidth={"md"}>
      <DialogTitle sx={{ backgroundColor: "#F9F9F9" }}>
        Modification du lexique:{" "}
      </DialogTitle>
      <Box
        component='form'
        onSubmit={(e) => {
          e.preventDefault();
          mutations.putModel.mutateAsync({
            modelId: model._id,
            model: { ...model, lexicon: lexicon },
          });
          toggle();
        }}
      >
        <DialogContent>
          <TextField
            label='Lexique'
            multiline
            onChange={(e) => setLexicon(e.target.value)}
            value={lexicon}
            minRows={6}
            fullWidth
          />
        </DialogContent>
        <DialogActions
          sx={{
            padding: `10px 20px`,
            backgroundColor: "#f9f9f9",
          }}
        >
          <Button variant='contained' type='submit'>
            Sauvegarder
          </Button>
          <Button onClick={toggle}>Annuler</Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
