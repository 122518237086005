import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  Grid,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { InterventionPopup } from "../../user/Intervention/InterventionPopup";
import { EditElementPopUp } from "../ListModel/EditElementPopUp";
import { USERS } from "../../../api/USER";
import { InterventionPopupAdmin } from "./InterventionPopupAdmin";
const ObservationsGetter = ({
  numberObservations,
  report,
  elementId,
  control,
  mutations,
  intervention,
  userType,
}: any) => {
  const [openObservations, setOpenObservations] = useState(false);
  return (
    <>
      {openObservations &&
        (userType === "ADMIN" ? (
          <InterventionPopupAdmin
            intervention={report}
            userType={userType}
            selectedElementId={elementId}
            open={openObservations}
            control={control}
            mutations={mutations}
            setOpenControlPopUp={setOpenObservations}
            interventionUserId={intervention?._id}
            onClose={() => setOpenObservations(false)}
          />
        ) : (
          <InterventionPopup
            intervention={report}
            userType={userType}
            selectedElementId={elementId}
            open={openObservations}
            control={control}
            mutations={mutations}
            setOpenControlPopUp={setOpenObservations}
            interventionUserId={intervention?._id}
            onClose={() => setOpenObservations(false)}
          />
        ))}

      <Box sx={{ cursor: "pointer" }} onClick={() => setOpenObservations(true)}>
        <Button variant='contained' sx={{ width: "150px", fontSize: "15px" }}>
          {numberObservations > 0
            ? numberObservations + " Observations"
            : "0 Observation"}
        </Button>
      </Box>
    </>
  );
};

export const ElementIntervention = ({
  element,
  userType,
  mutations,
  report,
  intervention,
}: any) => {
  const [controles, setControles] = useState(element.controles || []);
  const [openEditElement, setOpenEditElement] = useState(false);
  return (
    <Box
      key={element._id}
      sx={{ width: 1, overflow: "hidden", mt: "20px" }}
      className='card'
    >
      <EditElementPopUp
        open={openEditElement}
        toggleClose={() => setOpenEditElement(false)}
        report={report}
        currentIntervention={intervention}
        element={element}
        mutations={mutations}
        editMode={openEditElement}
        setEditMode={setOpenEditElement}
        setControles={setControles}
        controles={controles}
        viewType='INTERVENTION'
        userType={userType}
      />
      <Box
        sx={{
          width: "100%",
          backgroundColor: "#F9F9F9",
          height: "60px",
          padding: "0 20px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant='h2'>{element.name}</Typography>
        <Box
          sx={{
            gap: "10px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {userType === "ADMIN" && (
            <Button
              sx={{
                color: "white",
                padding: "8px 15px",
              }}
              variant='contained'
              onClick={() =>
                setOpenEditElement((isEditMode: Boolean) => !isEditMode)
              }
            >
              <EditIcon
                sx={{
                  fontSize: "17px",
                  mr: "6px",
                }}
              />
              <Typography sx={{ fontSize: "17px", color: "white" }}>
                Editer
              </Typography>
            </Button>
          )}
          {/* <IconButton
          //   onClick={() => setPopUpDelete(true)}
          >
            <DeleteIcon sx={{ fontSize: "28px" }} />
          </IconButton> */}
          {/* <PopUpDelete
            open={popUpDelete}
            mutationsFunction={deleteMutation}
            elementId={element._id}
            headerText={"Voulez-vous supprimer l'élément sélectionné ?"}
            toggleClose={() => setPopUpDelete((isOpen) => !isOpen)}
          /> */}
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: "white",
          padding: "20px",
        }}
      >
        {!element.controles && (
          <Typography
            variant='body1'
            sx={{
              padding: "5px 0",
              textAlign: "center",
            }}
          >
            L'élément n'a pas de contrôle défini
          </Typography>
        )}
        {element?.controles && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {element?.controles?.map((control: any) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    margin: "15px 0",
                  }}
                  key={control._id}
                >
                  <TextField
                    minRows={3}
                    multiline
                    maxRows={3}
                    disabled
                    InputProps={{
                      sx: {
                        color: "#fff", // Changer la couleur du texte en noir
                      },
                    }}
                    sx={{
                      width: "770px",
                    }}
                    value={control.description}
                    autoFocus
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      gap: "20px",
                      margin: "10px",
                    }}
                  >
                    <Box sx={{ width: "100px" }}>
                      <Typography>
                        {control?.result?.conforme ? "Contrôlé" : "En cours"}
                      </Typography>
                      <Typography>
                        {control?.result?.conforme === "true"
                          ? "Conforme"
                          : "Non conforme"}
                      </Typography>
                    </Box>
                    <ObservationsGetter
                      numberObservations={control.result?.observations?.length}
                      mutations={mutations}
                      control={control}
                      report={report}
                      intervention={intervention}
                      elementId={element?._id}
                      userType={userType}
                    />
                  </Box>
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
    </Box>
  );
};
