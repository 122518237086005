import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";

function ListReport_Add({ mutations, models, clients, ...props }: any) {
  const [selectedModel, setSelectedModel] = useState("");
  const [selectedClient, setSelectedClient] = useState("");
  const [site, setSite] = useState("");
  //-------- Add User Popup ---------
  const [openAddReport, setOpenAddReport] = React.useState<boolean>(false);
  const handleAddReportPopup = () => {
    setOpenAddReport(false);
  };

  const sites = ["Usine", "Station"];

  const formikSchema = yup.object({
    title: yup
      .string()
      .min(2, `Titre trop court !`)
      .max(50, `Titre trop long !`)
      .required(`Veuillez renseigner un titre pour le rapport`),
    controleReason: yup
      .string()
      .min(2, `Objet de contrôle trop court !`)
      .required("Veuillez renseigner un objet de contrôle"),
    sectionSite: yup
      .string()
      .required("Veuillez renseigner l'emplacement du site"),
  });

  const formik = useFormik({
    initialValues: {
      title: ``,
      controleReason: ``,
      sectionSite: ``,
    },
    validationSchema: formikSchema,
    onSubmit: async (formValues, { resetForm }) => {
      await mutations.createReport.mutateAsync({
        client_id: selectedClient,
        title: formValues.title,
        controleReason: formValues.controleReason,
        model_id: selectedModel,
        site: site,
        sectionSite: formValues.sectionSite,
      });
      handleAddReportPopup();
      resetForm();
      setSelectedClient("");
      setSelectedModel("");
      setSite("");
    },
  });

  return (
    //header de user management
    <Stack direction='row' alignItems='center' spacing={5}>
      <Button
        onClick={() => {
          setOpenAddReport(true);
        }}
        sx={{
          color: "#fff",
          height: `38px`,
        }}
        variant='contained'
      >
        <AddIcon />
        <Typography
          sx={{
            color: "#fff",
            fontSize: { xs: `0`, sm: `14px` },
          }}
          variant='body2'
        >
          Ajouter un rapport
        </Typography>
      </Button>
      <Dialog open={openAddReport} onClose={() => setOpenAddReport(false)}>
        <Box component='form' onSubmit={formik.handleSubmit}>
          <DialogTitle sx={{ backgroundColor: "#f9f9f9" }}>
            Ajouter un rapport
          </DialogTitle>
          <DialogContent
            sx={{
              width: `560px`,
              maxWidth: `100%`,
              padding: `20px 20px 20px 20px`,
              mt: 2,
              "& .MuiFormLabel-root:not(.Mui-focused, .Mui-error)": {
                color: "grey !important",
              },
            }}
          >
            <Stack>
              <TextField
                margin='dense'
                fullWidth
                id='title'
                label='Titre du rapport'
                name='title'
                autoComplete='title'
                value={formik.values.title}
                onChange={formik.handleChange}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
              />
              <TextField
                margin='dense'
                fullWidth
                id='controleReason'
                label='Objet du contrôle'
                name='controleReason'
                autoComplete='controleReason'
                value={formik.values.controleReason}
                onChange={formik.handleChange}
                error={
                  formik.touched.controleReason &&
                  Boolean(formik.errors.controleReason)
                }
                helperText={
                  formik.touched.controleReason && formik.errors.controleReason
                }
              />
              <FormControl sx={{ margin: "9px 0" }}>
                <InputLabel id='site-selector-label'>
                  Choisissez le site du rapport
                </InputLabel>
                <Select
                  labelId='site-selector-label'
                  id='site-selector'
                  label='Choisissez le site du rapport'
                  value={site}
                  required
                  onChange={(e) => setSite(e.target.value)}
                >
                  {sites?.map((site: any, index: number) => {
                    return (
                      <MenuItem key={index} value={site}>
                        {site}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {site !== "" && (
                <TextField
                  margin='dense'
                  fullWidth
                  id='sectionSite'
                  label='Section du site'
                  name='sectionSite'
                  autoComplete='sectionSite'
                  value={formik.values.sectionSite}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.sectionSite &&
                    Boolean(formik.errors.sectionSite)
                  }
                  helperText={
                    formik.touched.sectionSite && formik.errors.sectionSite
                  }
                />
              )}

              <FormControl sx={{ margin: "9px 0" }}>
                <InputLabel id='model-selector-label'>
                  Choisissez votre modèle
                </InputLabel>

                <Select
                  labelId='model-selector-label'
                  id='model-selector'
                  label='Choisissez votre modèle'
                  value={selectedModel}
                  required
                  onChange={(e) => setSelectedModel(e.target.value)}
                >
                  {models?.map((model: any) => (
                    <MenuItem key={model._id} value={model._id}>
                      {model.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ margin: "4px 0" }}>
                <InputLabel id='model-selector-label'>
                  Sélectionnez un client
                </InputLabel>
                <Select
                  labelId='model-selector-label'
                  id='model-selector'
                  label='Choisissez votre modèle'
                  required
                  value={selectedClient}
                  onChange={(e) => setSelectedClient(e.target.value)}
                >
                  {clients?.map((client: any) => (
                    <MenuItem key={client._id} value={client._id}>
                      {client.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </DialogContent>
          <DialogActions
            sx={{
              padding: `10px 20px`,
              backgroundColor: "#f9f9f9",
            }}
          >
            <Button
              type='submit'
              sx={{
                color: `#fff`,
              }}
              variant='contained'
              disableElevation
              autoFocus
            >
              Créer
            </Button>
            <Button
              sx={{
                color: `text.disabled`,
              }}
              onClick={handleAddReportPopup}
            >
              Annuler
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Stack>
  );
}

export default ListReport_Add;
