import React, { useState } from "react";
import mongoose from "mongoose";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import AddIcon from "@mui/icons-material/Add";
import ControlElement from "../UserGrid/ControlElement";
export const EditElementPopUp = ({
  open,
  toggleClose,
  element,
  mutations,
  editMode,
  modelId,
  setEditMode,
  controles,
  setControles,
  viewType,
  currentIntervention,
  report,
  userType,
}: any) => {
  const [isAddMode, setIsAddMode] = useState(false);
  const [changed, setChanged] = useState(false);
  const [controlDesc, setControlDesc] = useState("");
  const [elementName, setElementName] = useState(element.name || "");
  const handleSubmit = () => {
    if (viewType === "INTERVENTION") {
      mutations.putReport.mutateAsync({
        reportId: report._id,
        report: {
          ...report,
          interventions: report.interventions?.map((inter: any) => {
            if (inter._id === currentIntervention._id) {
              return {
                ...inter,
                intervention: inter.intervention?.map((el: any) =>
                  el._id === element._id
                    ? {
                        ...el,
                        controles: controles,
                      }
                    : el
                ),
              };
            }
            return inter;
          }),
        },
      });
    } else {
      mutations.putElement.mutateAsync({
        elementId: element._id,
        element: { ...element, name: elementName, controles: controles },
      });
    }
    toggleClose();
  };
  const toggleAddButton = () => {
    setIsAddMode((isEditMode) => !isEditMode);
  };

  const resetStates = () => {
    setControles(element.controles);
    setElementName(element.name || "");
    setChanged(false);
    setIsAddMode(false);
    setControlDesc("");
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        resetStates();
        toggleClose();
      }}
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle sx={{ backgroundColor: "#F9F9F9" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          Edition de l'élément {element.name}
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            backgroundColor: "white",
          }}
        >
          <Box
            sx={{
              padding: "20px 0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              flexWrap: "wrap",
              gap: "20px",
            }}
          >
            <Box sx={{ width: 1 }}>
              <TextField
                sx={{ width: 1 }}
                label="Edition du nom de l'élément"
                value={elementName}
                onChange={(e) => {
                  setElementName(e.target.value);
                  setChanged(true);
                }}
              />
            </Box>
            {controles?.length !== 0 && (
              <Box sx={{ width: 1, borderTop: "1px solid grey" }}></Box>
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                maxHeight: "500px",
                overflowY: "scroll",
                width: 1,
                gap: "20px",
                paddingTop: "10px",
              }}
            >
              {controles?.map((control: any) => {
                return (
                  <ControlElement
                    mutations={mutations}
                    control={control}
                    editMode={editMode}
                    popUpMode={true}
                    setControles={setControles}
                    controles={controles}
                    setChanged={setChanged}
                    key={control._id}
                  />
                );
              })}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {isAddMode ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  width: "100%",
                }}
                component='form'
                onSubmit={(e) => {
                  e.preventDefault();

                  setControles([
                    ...controles,
                    {
                      _id: new mongoose.Types.ObjectId(),
                      description: controlDesc,
                    },
                  ]);
                  toggleAddButton();
                  setControlDesc("");
                }}
              >
                <TextField
                  minRows={1}
                  multiline
                  fullWidth
                  maxRows={5}
                  label='Ajouter un contrôle'
                  value={controlDesc}
                  required
                  autoFocus
                  onChange={(e: any) => {
                    setChanged(true);
                    setControlDesc(e.target.value);
                  }}
                />
                <Button
                  type='submit'
                  variant='contained'
                  sx={{ height: "100%" }}
                >
                  <DoneIcon
                    sx={{
                      color: "white",
                    }}
                  />
                </Button>
                <Button sx={{ height: "100%" }}>
                  <CloseIcon
                    onClick={() => setIsAddMode(false)}
                    sx={{
                      cursor: "pointer",
                      "&:hover": { color: "darkslategrey" },
                    }}
                  />
                </Button>
              </Box>
            ) : (
              <Button
                variant='contained'
                fullWidth
                sx={{ height: "55px" }}
                onClick={toggleAddButton}
              >
                <AddIcon sx={{ marginRight: "20px" }} />
                Ajouter un contrôle
              </Button>
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          padding: `10px 20px`,
          backgroundColor: "#f9f9f9",
        }}
      >
        <Button
          onClick={() => {
            resetStates();
            toggleClose();
          }}
        >
          Annuler
        </Button>
        <Button
          sx={{
            backgroundColor: changed ? "primary.main" : "grey",
            color: "white",
            display: "flex",
            alignItems: "center",
          }}
          variant='contained'
          disabled={!changed}
          onClick={() => {
            handleSubmit();
            setEditMode(false);
          }}
        >
          Enregistrer
        </Button>
      </DialogActions>
    </Dialog>
  );
};
