import {
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  TextField,
  Box,
  Button,
} from "@mui/material";
import React, { useState } from "react";

const ElementPopup = ({ open, toggle, mutations, modelId }: any) => {
  const [elementName, setElementName] = useState("");
  const [changed, setChanged] = useState(false);
  return (
    <Dialog open={open} onClose={toggle} maxWidth={'sm'}>
      <Box
        component='form'
        onSubmit={async (e) => {
          e.preventDefault();
          await mutations?.addElement.mutateAsync({
            modelId: modelId,
            name: elementName,
          });
          toggle();
        }}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "600px",
        }}
      >
        <DialogTitle sx={{backgroundColor: 'customGrey.grey1' }}>Créer un élément</DialogTitle>
        <FormControl sx={{ padding: `20px 20px 20px 20px`, }}>
          <TextField
            required
            fullWidth
            label="Nom de l'élément"
            onChange={(e) => {
              setChanged(true);
              setElementName(e.target.value);
            }}
          />
        </FormControl>
        <DialogActions sx={{
              padding: `10px 20px`,
              backgroundColor: "#f9f9f9",
            }}>
            <Button onClick={toggle}>Annuler</Button>
            <Button variant='contained' type='submit' disabled={!changed}>
              Créer
            </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ElementPopup;
