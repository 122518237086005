import { Box, Button } from "@mui/material";

import {
  FormGenerator,
  FormDataType,
  FormFieldType,
  yuped,
} from "../../ui/FormGenerator";

const ExportForm = ({ formData, setFormData }: any) => {
  const printExport = (): Promise<void> => {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        window.print();
        resolve();
      }, 1000);
    });
  };

  const SubmitFunction = async (values: FormDataType) => {
    setFormData(values);
    await printExport();
  };

  const formFields: FormFieldType[] = [
    {
      label: "Numéro d'affaire",
      name: "affaire_num",
      type: "string",
      initialValue: formData?.affaire_num || ``,
      formikSchema: yuped
        .string()
        .required(`Veuillez renseigner un numéro d'affaire`),
    },
    {
      label: "Responsable",
      name: "responsable",
      type: "string",
      initialValue: formData?.responsable || ``,
      formikSchema: yuped
        .string()
        .required(`Veuillez renseigner un responsable`),
    },
    {
      label: "Nature de la modification",
      name: "nature",
      type: "string",
      initialValue: formData?.nature || ``,
      formikSchema: yuped
        .string()
        .required(`Veuillez renseigner la nature de la modification`),
    },
    {
      label: "Indice",
      name: "indice",
      type: "number",
      initialValue: formData?.indice || `è`,
      formikSchema: yuped.string().required(`Veuillez renseigner un indice`),
    },
    {
      label: "Technique opératoire",
      name: "techniqueOperatoire",
      type: "string",
      initialValue: formData?.nature || ``,
      formikSchema: yuped
        .string()
        .required(`Veuillez renseigner la nature de la modification`),
    },
    // {
    //   label: "string multiligne",
    //   name: "indice",
    //   type: "string",
    //   rows: 4,
    //   initialValue: formData?.indice || "test",
    //   formikSchema: yuped.string().required(`Veuillez renseigner un indice`),
    // },
    // {
    //   label: "number",
    //   name: "test",
    //   type: "number",
    //   initialValue: formData?.test || 10,
    //   formikSchema: yuped.number().required(`Veuillez renseigner un test`),
    // },
    // {
    //   label: "Password",
    //   name: "test",
    //   type: "password",
    //   initialValue: formData?.test || 10,
    //   formikSchema: yuped.number().required(`Veuillez renseigner un test`),
    // },
    // {
    //   label: "Select",
    //   name: "appareil",
    //   type: "select",
    //   options: ["test", "test2", "test3"],
    //   initialValue: formData?.appareil || "",
    //   formikSchema: yuped.string().required(`Veuillez renseigner un appareil`),
    // },
    // {
    //   label: "Checkbox facultatif",
    //   name: "test2",
    //   type: "checkbox",
    //   formikSchema: yuped.boolean(),
    // },
    // {
    //   label: "Checkbox obligatoire",
    //   name: "test3",
    //   type: "checkbox",
    //   initialValue: formData?.test3 || true,
    //   formikSchema: yuped.boolean().test('isChecked', 'Message', value => value === true)
    // }
  ];

  return (
    <Box className='card' sx={{ p: "20px" }}>
      <FormGenerator
        SubmitFunction={SubmitFunction}
        formFields={formFields}
        textButton='Générer le rapport'
      />
      {/* <FormGenerator 
        SubmitFunction={SubmitFunction} 
        formFields={formFields} 
        textButton='Générer le rapport'
        dialog={{
          button: <Button>open form</Button>,
          title: 'test'
        }}
      /> */}
    </Box>
  );
};

export default ExportForm;
