import { Box, CircularProgress } from "@mui/material";
const FullSizeLoading = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress sx={{ color: "primary.main" }} />
    </Box>
  );
};

export default FullSizeLoading;
