import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Login from "./pages/login";
import DashBoard from "./pages/dashboard";
import Header from "./components/ui/navigation";
import Footer from "./components/ui/footer";
import { Box } from "@mui/material";
import { Model } from "./pages/Model";
import { Report } from "./pages/Report";
import { Export } from "./pages/Export";
import { Intervention } from "./pages/Intervention";

function AppRouter() {
  React.useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      if (window.location.pathname === "/login") {
        window.location.replace("/");
      }
    } else {
      if (window.location.pathname === "/") {
        window.location.replace("/login");
      }
    }
  }, []);

  return (
    <>
      <Router>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
          }}
        >
          <Header />
          <Routes>
            <Route
              path='/'
              element={
                <Box sx={{ flex: "1" }}>
                  <DashBoard />
                </Box>
              }
            />
            <Route
              path='/:location'
              element={
                <Box sx={{ flex: "1" }}>
                  <DashBoard />
                </Box>
              }
            />

            <Route
              path='/login'
              element={
                <Box
                  className='login'
                  sx={{
                    flex: "1",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Login />
                </Box>
              }
            />
            <Route
              path='/model/:model_id'
              element={
                <Box
                  sx={{
                    flex: "1",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Model />
                </Box>
              }
            />
            <Route
              path='/report/:report_id'
              element={
                <Box sx={{ flex: "1" }}>
                  <Report />
                </Box>
              }
            />
            <Route
              path='/intervention/:reportId/:interventionId'
              element={
                <Box sx={{ flex: "1" }}>
                  <Intervention />
                </Box>
              }
            />
            <Route
              path='/export/:report_id'
              element={
                <Box sx={{ flex: "1" }}>
                  <Export />
                </Box>
              }
            />

            <Route path='*' element={<div>Error 404</div>} />
          </Routes>
          {/* <Footer/> */}
        </Box>
      </Router>
    </>
  );
}

export default AppRouter;
