import {
  Box,
  Typography,
  Divider,
  Button,
} from "@mui/material";

import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import GroupsIcon from '@mui/icons-material/Groups';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import EditNoteIcon from '@mui/icons-material/EditNote';
import SettingsIcon from '@mui/icons-material/Settings';
import DomainAddIcon from '@mui/icons-material/DomainAdd';

const AdminNavigation = ({adminNavigation, setAdminNavigation}: any) => {
  const menu_items = [
    {
      name: 'Liste des rapports',
      icon: <FactCheckIcon sx={{margin: '0 5px'}}/>,
      link: 'list_report'
    },
    {
      name: 'Gestion des agents',
      icon: <GroupsIcon sx={{margin: '0 5px'}}/>,
      link: 'user'
    },
    {
        name: 'Liste des modèles',
        icon: <EditNoteIcon sx={{margin: '0 5px'}}/>,
        link: 'list_model'
    },
    {
        name: 'Liste des clients',
        icon: <DomainAddIcon sx={{margin: '0 5px'}}/>,
        link: 'list_client'
    },
    {
        name: '',
        icon: <SettingsIcon sx={{margin: '0 5px'}}/>,
        link: 'settings'
    },
  ]

  return (
    <Box className='wrapper card' sx={{mb: '20px'}}>
        <Box sx={{height: '60px', display: 'flex', alignItems:'center', gap: 2, padding: '10px 20px'}}>
          <AdminPanelSettingsIcon sx={{ display: {xs: 'none', sm: 'block'}}}  color="primary"/>
          <Divider sx={{ display: {xs: 'none', sm: 'block'}}} orientation="vertical"/>
          <Box sx={{display: 'flex', alignItems:'center', gap: 2, width:'100%'}}>
            {
              menu_items.map((item, index) => (
                  <Button key={index} sx={{width: item.name ? '100%' : 'auto'}} variant={adminNavigation === item.link ? "outlined" : "contained"} onClick={() => {setAdminNavigation(item.link)}}>
                      {item.icon}
                      <Typography sx={{
                        color: adminNavigation === item.link ? "primary" : "#fff",
                        fontSize: {xs: '0px', md:'0', lg:'14px'}
                        }} variant='body2'>{item.name}</Typography>
                  </Button>
                ))
            }
          </Box> 
        </Box>
    </Box>
  );
};

export default AdminNavigation;