import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true; // removes the `xs` breakpoint
    xm: true;
    xsm: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
  }
  interface PaletteColor {
    hover?: string;
    selection?: string;
  }

  interface Palette {
    primaryhard: Palette["primary"];
    customGrey: {
      grey1: string;
      grey2: string;
      grey3: string;
      grey4: string;
      grey5: string;
      grey6: string;
    };
  }

  interface PaletteOptions {
    primaryhard: Palette["primary"];
    customGreen: {
      green1: string;
    };
    customOrange: {
      orange1: string;
    };
    customGrey: {
      grey1: string;
      grey2: string;
      grey3: string;
      grey4: string;
      grey5: string;
      grey6: string;
    };
    customRed: {
      red1: string;
    };
  }

  interface SimplePaletteColorOptions {
    hover?: string;
    selection?: string;
  }
  interface TypeText {
    tertiary?: string;
  }
}

declare module "@mui/material/styles/createTypography" {
  interface TypographyOptions {
    orderMoreInfos: {
      fontSize: number;
      lineHeight: string;
      fontFamily: string;
    };
  }
}

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      xsm: 320,
      xm: 370,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    mode: `light`,
    primary: {
      main: "#14213d",
      light: "#536DFE",
      dark: "#244584",
    },
    secondary: {
      main: "#fca311",
      light: "#ffd740",
      dark: "#ff8f00",
    },
    primaryhard: {
      main: `#35b818`,
      hover: `#35b818`,
      light: `#83e56d`,
      dark: `#a6fd94`,
      contrastText: `#020000`,
    },
    customGreen: {
      green1: `#35b818`,
    },
    customGrey: {
      grey1: `#f9f9f9`,
      grey2: `#f0f0f0`,
      grey3: `#eeeeee`,
      grey4: `#dddddd`,
      grey5: `#bbbbbb`,
      grey6: `#999999`,
    },
    customOrange: {
      orange1: "#F6A21A",
    },
    customRed: {
      red1: "red",
    },
    background: {
      default: "#FFF",
    },
    text: {
      primary: "#333333",
      secondary: "#999999",
      disabled: "#777777",
      // hint: "#9FA8DA"
    },
    error: {
      main: `#FF0000`,
    },
    warning: {
      main: `#F9BF00`,
    },
    success: {
      main: `#78BE22`,
    },
    info: {
      main: `#0077C8`,
    },
    divider: `#ddd`,
  },

  typography: {
    fontSize: 14,
    fontFamily: `Rubik`,
    allVariants: {
      color: `#02080D`,
    },
    body1: {
      fontSize: "15px",
    },
    body2: {
      fontSize: "14px",
      color: "#999999",
    },
    h1: {
      fontWeight: 800,
      fontSize: `3.6rem`,
      "@media (max-width:1200px)": {
        fontSize: `3.0rem`,
      },
      "@media (max-width:600px)": {
        fontSize: `2.6rem`,
      },
    },
    h2: {
      fontSize: `1.45rem`,
      fontWeight: 650,
    },
    h3: {
      fontSize: `1.2rem`,
      fontWeight: 500,
      marginBottom: `6px`,
    },
    h4: {
      fontSize: `0.8rem`,
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: `0.75rem`,
      fontWeight: 300,
      color: `#8D919A`,
    },
    button: {
      textTransform: `none`,
      color: `#fff`,
      fontSize: `1.1rem`,
    },
    orderMoreInfos: {
      fontSize: 15,
      lineHeight: `22px`,
      fontFamily: `Montserrat`,
    },
  },
  components: {
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
    },

    MuiList: {
      defaultProps: {
        dense: true,
      },
    },
    MuiMenuItem: {
      defaultProps: {
        dense: true,
      },
    },
    MuiTable: {
      defaultProps: {
        size: `small`,
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        size: `small`,
      },
    },
    MuiCheckbox: {
      defaultProps: {
        size: `small`,
      },
    },
    MuiFab: {
      defaultProps: {
        size: `small`,
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        margin: `dense`,
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: `small`,
      },
    },
    MuiRadio: {
      defaultProps: {
        size: `small`,
      },
    },
  },
  shape: {
    borderRadius: 5,
  },
});

export default theme;
