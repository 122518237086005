import React from "react";
import {
  Box,
  Divider,
  Stack,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  Link,
} from "@mui/material";
import { ADMIN_CLIENT } from "../../../api/ADMIN_CLIENT";
import { ADMIN } from "../../../api/ADMIN";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  DataGridPro,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarContainer,
} from "@mui/x-data-grid-pro";
import DeleteIcon from "@mui/icons-material/Delete";
import ClientAdd from "./ListClient_Add";
import ClientEdit from "./ListClient_Edit";
import FullSizeLoading from "../../ui/fullSizeLoading";

const CustomToolbar: React.FunctionComponent<{
  setFilterButtonEl: React.Dispatch<
    React.SetStateAction<HTMLButtonElement | null>
  >;
  setSearchInputValue: React.Dispatch<React.SetStateAction<string>>;
  searchInputValue: React.Dispatch<
    React.SetStateAction<HTMLButtonElement | null>
  >;
  mutations: any;
  data: any;
}> = ({
  setFilterButtonEl,
  setSearchInputValue,
  searchInputValue,
  data,
  mutations,
}) => {
  //-------- Grid Header --------
  const SearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(event.target.value);
  };
  return (
    <GridToolbarContainer
      sx={{
        padding: `0px`,
      }}
    >
      <Stack
        flex='1'
        sx={{
          backgroundColor: (theme: any) => theme.palette.customGrey.grey1,
          padding: `0px 10px`,
        }}
      >
        <Stack
          sx={{
            height: `60px`,
            "& .MuiFormLabel-root:not(.Mui-focused)": {
              color: "grey !important",
            },
          }}
          direction='row'
          alignItems='center'
          spacing={2}
        >
          <TextField
            size='small'
            value={searchInputValue}
            onChange={SearchInputChange}
            label='Recherche'
            variant='outlined'
          />
          <ClientAdd
            API_USER={ADMIN_CLIENT}
            mutations={mutations}
            data={data}
          />
          <GridToolbarColumnsButton
            sx={{ display: { xs: "none", sm: "flex" } }}
            ref={setFilterButtonEl}
          />
        </Stack>
        <Divider light />
      </Stack>
    </GridToolbarContainer>
  );
};

const ListClient = () => {
  const [searchInputValue, setSearchInputValue] = React.useState(``);
  const [filterColumnButton, setFilterColumnButton] =
    React.useState<HTMLButtonElement | null>(null);

  const fetchClientList = async () => {
    const response = await ADMIN_CLIENT.GET_CLIENTS();
    return response.data;
  };

  const queryClient = useQueryClient();
  const { isLoading, data } = useQuery("ClientList", fetchClientList);

  const mutations = {
    delete: useMutation(ADMIN_CLIENT.DELETE_CLIENT, {
      onSuccess: (resp) => {
        queryClient.setQueryData("ClientList", (oldData: any) =>
          oldData.filter((client: any) => client._id !== resp.data._id)
        );
      },
    }),
    create: useMutation(ADMIN_CLIENT.POST_CLIENT, {
      onSuccess: (resp) => {
        queryClient.setQueryData("ClientList", (oldData: any) => [
          ...oldData,
          resp.data,
        ]);
      },
    }),
    update: useMutation(
      ({ id, updates }: any) => ADMIN_CLIENT.PUT_CLIENT(id, updates),
      {
        onSuccess: (data, variables) => {
          queryClient.setQueryData("ClientList", (oldData: any) =>
            oldData.map((client: any) =>
              client._id === variables.id ? data.data : client
            )
          );
        },
      }
    ),
  };

  const handleDeleteUser = async (id: string) => {
    await mutations.delete.mutateAsync(id);
    setOpenDeletePopup({ status: false });
  };

  //-------- Delete Popup --------
  const [OpenDeletePopup, setOpenDeletePopup] = React.useState<{
    status: boolean;
    row?: any;
  }>({ status: false });

  const SearchBarFilter = (rows: any) => {
    return rows
      .map((row: any) => {
        row.id = row._id;
        return row;
      })
      .filter(
        (row: any) =>
          !searchInputValue ||
          row.name?.toLowerCase().includes(searchInputValue.toLowerCase()) ||
          row.email?.toLowerCase().includes(searchInputValue.toLowerCase()) ||
          row.phone?.toLowerCase().includes(searchInputValue.toLowerCase()) ||
          row.address1
            ?.toLowerCase()
            .includes(searchInputValue.toLowerCase()) ||
          row.address2
            ?.toLowerCase()
            .includes(searchInputValue.toLowerCase()) ||
          row.zipCode?.toLowerCase().includes(searchInputValue.toLowerCase()) ||
          row.city?.toLowerCase().includes(searchInputValue.toLowerCase())
      );
  };

  //-------- Grid Columns --------
  const columns = [
    //show by default
    {
      field: `socialReason`,
      headerName: `Raison sociale`,
      width: 150,
    },
    {
      field: `email`,
      headerName: `Email`,
      width: 150,
      renderCell: (cellValues: any) => {
        return (
          <Link href={`mailto:${cellValues.value}`} color='inherit'>
            {cellValues.value}
          </Link>
        );
      },
    },
    {
      field: `phone`,
      headerName: `Téléphone`,
      width: 150,
      renderCell: (cellValues: any) => {
        return (
          <Link href={`tel:${cellValues.value}`} color='inherit'>
            {cellValues.value}
          </Link>
        );
      },
    },
    {
      field: `address1`,
      headerName: `Adresse 1`,
      width: 150,
    },
    {
      field: `address2`,
      headerName: `Adresse 2`,
      width: 150,
    },
    {
      field: `zipCode`,
      headerName: `Code postal`,
      width: 150,
    },
    {
      field: `city`,
      headerName: `Ville`,
      width: 150,
    },
    // fixed right
    {
      field: `actions`,
      type: `actions`,
      headerName: `Actions`,
      width: 90,
      resizable: false,
      getActions: (e: { row?: any }) => [
        <ClientEdit
          API={ADMIN}
          API_USER={ADMIN_CLIENT}
          key='user-grid-edit'
          rowInfos={e}
          mutations={mutations}
          data={data}
        />,
        <GridActionsCellItem
          onClick={() => setOpenDeletePopup({ row: e.row, status: true })}
          icon={<DeleteIcon />}
          label='Delete'
          key='grid-actions-cell-item-1'
        />,
      ],
    },
  ];

  const DeleteClientPopup = () => {
    return (
      <Dialog
        open={OpenDeletePopup?.status}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle
          sx={{
            overflow: "hidden",
            fontSize: { xs: "17px", sm: "24px" },
            textOverflow: "ellipsis",
          }}
        >
          Voulez vous vraiment supprimer {OpenDeletePopup?.row?.title} ?
        </DialogTitle>
        <DialogActions
          sx={{
            padding: `10px 20px`,
            backgroundColor: "#f9f9f9",
          }}
        >
          <Button
            sx={{
              color: `text.disabled`,
            }}
            onClick={() => {
              setOpenDeletePopup({ status: false });
            }}
          >
            Annuler
          </Button>
          <Button
            sx={{
              color: `#fff`,
            }}
            variant='contained'
            disableElevation
            onClick={() => {
              OpenDeletePopup?.row?.id &&
                handleDeleteUser(OpenDeletePopup.row.id);
            }}
            autoFocus
          >
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Box
      className='wrapper_noPadding card'
      sx={{ height: "588px", display: "flex", alignItems: "center", gap: 2 }}
    >
      <Box
        sx={{
          height: "100%",
          width: "100%",
        }}
      >
        {!isLoading && data ? (
          <DataGridPro
            autoPageSize
            pagination
            sx={{
              '.MuiDataGrid-booleanCell[data-value="true"] ': {
                color: `#8D919A`,
              },
              borderRadius: `20px`,
              overflow: "hidden",
            }}
            rows={(data[0] && SearchBarFilter(data)) || []}
            columns={columns}
            disableColumnMenu
            localeText={{
              toolbarColumns: "Colonnes",
              columnsPanelTextFieldLabel: "Chercher une colonne",
              columnsPanelTextFieldPlaceholder: "Nom de la colonne",
              columnsPanelShowAllButton: "Afficher tout",
              columnsPanelHideAllButton: "Masquer tout",
            }}
            components={{
              Toolbar: CustomToolbar,
              NoRowsOverlay: () => (
                <Stack
                  height='100%'
                  alignItems='center'
                  justifyContent='center'
                >
                  Aucune ligne à afficher
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack
                  height='100%'
                  alignItems='center'
                  justifyContent='center'
                >
                  Aucun résultat
                </Stack>
              ),
            }}
            componentsProps={{
              panel: {
                anchorEl: filterColumnButton,
              },
              toolbar: {
                setFilterButtonEl: setFilterColumnButton,
                setSearchInputValue,
                searchInputValue,
                data,
                mutations,
                API_USER: ADMIN_CLIENT,
              },
            }}
            initialState={{ pinnedColumns: { right: [`actions`] } }}
          />
        ) : (
          <FullSizeLoading />
        )}
        <DeleteClientPopup />
      </Box>
    </Box>
  );
};

export default ListClient;
