import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  TextField,
  MenuItem,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import EditIcon from "@mui/icons-material/Edit";
function ListReport_Edit({ mutations, clients, rowInfos }: any) {
  const [openEditReport, setOpenEditReport] = useState<boolean>(false);
  const [selectedClient, setSelectedClient] = useState(rowInfos.row.client_id);
  const [status, setStatus] = useState(rowInfos.row.status);
  const formikSchema = yup.object({
    reportTitle: yup
      .string()
      .min(2, `Titre trop court !`)
      .max(50, `Titre trop long !`)
      .required(`Veuillez renseigner un titre au rapport`),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      reportTitle: rowInfos.row.title || "",
    },
    validationSchema: formikSchema,
    onSubmit: async (formValues, { resetForm }) => {
      await mutations.putReport.mutateAsync({
        reportId: rowInfos.row._id,
        report: {
          ...rowInfos.row,
          title: formValues.reportTitle,
          client_id: selectedClient,
          status: status,
        },
      });
      handleEditPopUpClose();
      resetForm();
    },
  });

  const handleEditPopUpClose = () => {
    formik.resetForm();
    setOpenEditReport(false);
  };

  return (
    //header de user management
    <Box>
      <GridActionsCellItem
        onClick={() => setOpenEditReport(true)}
        icon={
          <EditIcon
            sx={{ fontSize: { xs: "18px", xsm: "20px", sm: "22px" } }}
          />
        }
        label='Edit'
      />
      <Dialog open={openEditReport} onClose={handleEditPopUpClose}>
        <Box component='form' onSubmit={formik.handleSubmit}>
          <DialogTitle sx={{ backgroundColor: "#f9f9f9" }}>
            Modification du rapport
          </DialogTitle>
          <DialogContent
            sx={{
              width: `560px`,
              maxWidth: `100%`,
              padding: `20px 20px 20px 20px`,
              mt: 2,
              "& .MuiFormLabel-root:not(.Mui-focused, .Mui-error)": {
                color: "grey !important",
              },
            }}
          >
            <TextField
              margin='dense'
              fullWidth
              id='reportTitle'
              label='Titre du rapport'
              name='reportTitle'
              autoComplete='reportTitle'
              value={formik.values.reportTitle}
              onChange={formik.handleChange}
              error={
                formik.touched.reportTitle && Boolean(formik.errors.reportTitle)
              }
              helperText={
                formik.touched.reportTitle &&
                formik.errors.reportTitle?.toString()
              }
            />

            <FormControl sx={{ margin: "20px 0", width: 1 }}>
              <InputLabel id='model-selector-label'>
                Assigner un nouveau client
              </InputLabel>
              <Select
                labelId='model-selector-label'
                id='model-selector'
                label='Assigner un nouveau client'
                value={selectedClient}
                onChange={(e) => setSelectedClient(e.target.value)}
              >
                {clients?.map((client: any) => (
                  <MenuItem key={client._id} value={client._id}>
                    {client.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ margin: "20px 0", width: 1 }}>
              <InputLabel id='model-selector-label'>
                Status du rapport
              </InputLabel>
              <Select
                labelId='model-selector-label'
                id='model-selector'
                label='Status du rapport'
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <MenuItem value='uncompleted'>Incomplété</MenuItem>
                <MenuItem value='completed'>Complété</MenuItem>
              </Select>
            </FormControl>
            {/* <TextField
              margin='dense'
              fullWidth
              id='family_name'
              label='Nom'
              name='family_name'
              autoComplete='family_name'
              value={formik.values.family_name}
              onChange={formik.handleChange}
              error={
                formik.touched.family_name && Boolean(formik.errors.family_name)
              }
              helperText={
                formik.touched.family_name &&
                formik.errors.family_name?.toString()
              }
            /> */}
            {/* <TextField
              margin='dense'
              fullWidth
              id='phone'
              label='Téléphone'
              name='phone'
              autoComplete='phone'
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={
                formik.touched.phone && formik.errors.phone?.toString()
              }
            /> */}
          </DialogContent>
          <DialogActions
            sx={{
              padding: `10px 20px`,
              backgroundColor: "#f9f9f9",
            }}
          >
            <Button
              sx={{
                color: `text.disabled`,
              }}
              onClick={handleEditPopUpClose}
            >
              Annuler
            </Button>
            <Button
              type='submit'
              sx={{
                color: `#fff`,
              }}
              variant='contained'
              disableElevation
              onClick={() => null}
              autoFocus
            >
              Enregistrer
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
}

export default ListReport_Edit;
